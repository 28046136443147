export const medicalAidResults = [
  {
    "scheme": "1 DAY HEALTH",
    "plan": "1 DOCTOR HEALTH",
    "administrator": null,
    "contactInfo": {
      "email": [
        "admin@day1.co.za",
        "authorisations@1doctor.co.za",
        "claimsqueries@day1.co.za"
      ],
      "fax": ["086 690 4818"],
      "phone": [
        "0876 100 600"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No advanced dentistry, no Ortho, No dentures, No Implants, No Hospital, theatre benefits. No Panorex. No Rootcanal fillings.",
    "preAuth": "8107 & 8112 Intraoral radiograph periapical and bitewing (2/member per 8101 not more than 4 per year). 8162 Fluoride Treatment \u2013 adult . 8201 Extraction first tooth (1 per quadrant per visit 2nd or more require authorization) 8202 Extraction each additional tooth (If 8101 is claimed authorization required for 1 or more. Authorization is required if fillings are claimed in same visit). Max 3 fillings per visit 4 or more require authorization with X-ray). ",
    "crucialRules": "8104 Examination for a specific condition (not within 28 days of 8101, 8102 or 8105). 8132 Pulp removal (pulpectomy) (relief of pain) (1 per member per annum). ",
    "notes": "Herewith the  procedure codes covered within the 1Doctor Formulary. Basic treatment includes preventative cleaning, fillings, extractions and emergency pain and sepsis control via a Day1 Health Network dentist. 2 Visits per annum per member.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Aeci",
    "plan": "Comprehensive",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "aecisociety@medscheme.co.za"
      ],
      "fax": [],
      "phone": [
        "0859 002 103"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "yes",
    "dentures": "yes",
    "crownsOrBridges": "yes",
    "implants": "yes",
    "surgicalExtractions": "yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated.  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised unless adequately motivated.  No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated.  High impact acrylic is not covered unless adequately motivated.",
    "preAuth": "Maxillo-facial procedures: These can be performed under conscious sedation the doctor\u2019s rooms, or in hospital.  Procedures will be covered from the advanced dentistry benefit.  The hospital (if applicable) and anaesthetist costs will be covered at cost or at 2 x Scheme Tariff, whichever is lower, from the Risk benefit benefit if the procedure was pre-authorised, otherwise it will be for the member\u2019s own cost. Orthodontics A separate benefit is available for beneficiaries < age 21, as contained in the Scheme member guide. Subject to preauthorisation.",
    "crucialRules": " Multiple fillings: A treatment plan and X-rays  required for multiple fillings. Pan x- every 3 years. Orthodontic treatment R30 000 per beneficiary, limited to one treatment plan spread over 24 months. Maximum age of 21. ",
    "notes": "R13 200 per beneficiary, limited to R26 400 per family. Depending on the Option, certain benefits have no limit (such as for basic dentistry)",
    "source": "https://aecimedicalaidsociety.co.za/benefits/dentistry/",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Aeci",
    "plan": "Comprehensive Select",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "aecisociety@medscheme.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 002 103"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "yes",
    "dentures": "yes",
    "crownsOrBridges": "yes",
    "implants": "yes",
    "surgicalExtractions": "yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated.  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised unless adequately motivated.  No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated.  High impact acrylic is not covered unless adequately motivated.",
    "preAuth": "Maxillo-facial procedures.  These can be performed under conscious sedation the doctor\u2019s rooms, or in hospital.  Procedures will be covered from the advanced dentistry benefit. \u2022 The hospital (if applicable) and anaesthetist costs will be covered at cost or at 2 x Scheme Tariff, whichever is lower, from the Risk benefit benefit if the procedure was pre-authorised, otherwise it will be for the member\u2019s own cost. Orthodontics A separate benefit is available for beneficiaries < age 21, as contained in the Scheme member guide. Subject to preauthorisation.",
    "crucialRules": " Multiple fillings: A treatment plan and X-rays  required for multiple fillings. Pan x- every 3 years. Orthodontic treatment R30 000 per beneficiary, limited to one treatment plan spread over 24 months. Maximum age of 21.",
    "notes": "R13 200 per beneficiary, limited to R26 400 per family",
    "source": "https://aecimedicalaidsociety.co.za/benefits/dentistry/",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AECI Medical",
    "plan": "Value Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for surgical removal of impacted teeth. No panorex. No cover for advanced dentistry. No benefits for Ortho.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2  per beneficiary  per annum.  Denture Benefit  Pre-authorisation required. ",
    "crucialRules": "Plastic and acrylic dentures are limited to one set per beneficiary every 24 months.",
    "notes": "An authorisation granted is not a guarantee of payment. ",
    "source": "https://aecimedicalaidsociety.co.za/benefits/dentistry/",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Dental",
    "plan": "Silver",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "086 155 5522"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for surgical removal of impacted teeth. No panorex. No cover for advanced dentistry. Benefits for amalgam restorations to be replaced with composite are only available where such treatment is necessary to restore secondary caries. Resin restorations are not covered on non\u0002carious teeth restored with amalgams. ",
    "preAuth": "Extraction: Limited to 3 extractions per member per annum  Maximum of 1 X 8201 per quadrant per visit. Limited to 3 restorations per member per annum.  1 Restoration code per tooth number in a 12-month period.  Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "Full mouth examination, charting and treatment planning Once per 6 months per member from date of service. x-ray  2 x-rays per member per annum. ",
    "notes": " Very basic option. Patient written consent needed for procedures not covered.  Restorations cannot be claimed on the same tooth number and service date as tariff 8132 , only adequatly motivated cases will be considered. Advanced Dentistry \u2013 R800 As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit.",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Dental",
    "plan": "Gold",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "086 155 5522"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. No Benefits for Panorex. No Theatre benefits. ",
    "preAuth": "Pre-authorisation required for more than 3 restorations per visit. Pre-authorisation and x-rays \nrequired for any 3 or 4 surface restorations on wisdom teeth. Root Canal:  Limited to 1 RCT per member per annum.  All specialised dentistry must be pre-authorised. Impaction : Limited to 2 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48).  All specialised dentistry  must be pre-authorised. Crown: 1 Crown per member per annum with a maximum of R3 500.00,  1 Per tooth number per 5 years from date of service. ",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray 3 x-rays per member per annum. Extraction: Limited to 5 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Restorations: Limited to 4 restorations per member per annum. 1 Restoration code per tooth number in a 12-month period. ",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. ",
    "source": "2024 DRC  Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Dental",
    "plan": "Platinum",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "086 155 5522"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. No Benefits for Panorex. No Theatre benefits. ",
    "preAuth": "  Pre-authorisation required for more than 3 restorations per visit. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. Limited to 2 RCT per member per annum must be pre-authorised.  Impaction: Limited to 3 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48) must be pre-authorised. 2 Crowns per member per annum with a maximum of R3 500 per crown.  1 Per tooth number per 5 years from date of service.  All specialised dentistry must be pre-authorised.",
    "crucialRules": "X-ray  4 x-rays per member per annum. Extraction: 4 x-rays per member per annum. Maximum of 1 X 8201 per quadrant per visit.  Limited to 5 restorations per member per annum. 1 Restoration code per tooth number in a 12-month period.",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Dental",
    "plan": "Platinum Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "086 155 5522"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes ",
    "dentures": "Yes ",
    "crownsOrBridges": "Yes ",
    "implants": "No",
    "surgicalExtractions": "Yes ",
    "exclusions": "On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation.  No panorex",
    "preAuth": "Restorations: Limited to 5 restorations per member per annum.  Pre-authorisation required for more than 3 restorations per visit. Pre-authorisation and x\u0002rays required for any 3 or 4 surface restorations on wisdom teeth. Root Canal: Limited to 2 RCT per member per annum,  must be pre-authorised. Impaction: Limited to 3 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48) must be pre-authorised. 2 Crowns per member per annum with a maximum of R4000 per crown.  1 Per tooth number per 5 years from date of service, must be pre-authorised. Dentures: One set of Plastic Dentures every 48 months per covered member.  Maximum or R4000  All specialised dentistry must be pre-authorised. Repair, Remodel, Reline, Rebase Once per annum per  member.  All specialised dentistry must be pre-authorised. ",
    "crucialRules": "Full Mouth Examination Once per 6 months per member from date of service. X-ray: 4 x-rays per member per  annum. Extraction: Limited to 7 extraction per \nmember per annum.  Maximum of 1 X 8201 per quadrant per visit.  1 Restoration code per tooth number in a 12- month period. ",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Combined",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "Extraction Codes: More than 4 extractions per annum require preauthorisation.  Maximum of 1 X 8201 per quadrant per visit.  More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": " On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. 1 Restoration code per tooth number in a 9-month period",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Day to day",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "Extraction Codes: More than 4 extractions per annum require preauthorisation.  Maximum of 1 X 8201 per quadrant per visit.  More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": " On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. 1 Restoration code per tooth number in a 9-month period",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Gold Max",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "Extraction Codes: More than 4 extractions per annum require preauthorisation.  Maximum of 1 X 8201 per quadrant per visit.  More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": " On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. 1 Restoration code per tooth number in a 9-month period",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Platinum Max",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "Extraction Codes: More than 4 extractions per annum require preauthorisation.  Maximum of 1 X 8201 per quadrant per visit.  More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": " On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. 1 Restoration code per tooth number in a 9-month period",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Titanium Max",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "Extraction Codes: More than 4 extractions per annum require preauthorisation.  Maximum of 1 X 8201 per quadrant per visit.  More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": " On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation. 1 Restoration code per tooth number in a 9-month period",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Chrome",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "More than 3 restorations requires pre-authorisation. Surgical Removals: Surgical removals are only covered when authorised. More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning.  1 Full mouth examination per member per annum from the  date of service. Xrays : Only 2 per member per annum. Extraction Codes: Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. 1 Restoration code per tooth number in a 9-month period.",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. Should a code not be in the manual then it is not covered.",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Silver Max",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "More than 3 restorations requires pre-authorisation. Surgical Removals: Surgical removals are only covered when authorised. More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning.  1 Full mouth examination per member per annum from the  date of service. Xrays : Only 2 per member per annum. Extraction Codes: Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. 1 Restoration code per tooth number in a 9-month period.",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. Should a code not be in the manual then it is not covered.",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Bronze",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "More than 3 restorations requires pre-authorisation. Surgical Removals: Surgical removals are only covered when authorised. More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning.  1 Full mouth examination per member per annum from the  date of service. Xrays : Only 2 per member per annum. Extraction Codes: Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. 1 Restoration code per tooth number in a 9-month period.",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. Should a code not be in the manual then it is not covered.",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Delta Max ",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "More than 3 restorations requires pre-authorisation. Surgical Removals: Surgical removals are only covered when authorised. More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning.  1 Full mouth examination per member per annum from the  date of service. Xrays : Only 2 per member per annum. Extraction Codes: Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. 1 Restoration code per tooth number in a 9-month period.",
    "notes": " Very basic option.    Patient written consent needed for procedures not covered. As this is a generic Rand limited benefit any dental code claimed that is not in the above listings will be processed and paid at the billed rate to the maximum of the Rand limit. Should a code not be in the manual then it is not covered.",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Affinity Reef",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "More than 3 restorations requires pre-authorisation. Surgical Removals: Surgical removals are only covered when authorised. More than 3 restorations require pre-authorisation. Pre-authorisation and x-rays required for any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning.  1 Full mouth examination per member per annum from the  date of service. Xrays : Only 2 per member per annum. Extraction Codes: Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. 1 Restoration code per tooth number in a 9-month period.",
    "notes": "Very basic option.  Patient written consent needed for procedures not covered.  Restorations cannot be claimed on the same tooth number and service date as tariff 8132 , only adequatly motivated cases will be considered. Should a code not be in the manual then it is not covered.",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Affinity Health",
    "plan": "Elevate",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "086 111 0033",
        "087 943 9611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefits for Panorex. 8132 Emergency root canal treatment Not covered on primary teeth. No benfits for specialised dentistry or In Hospital benefits.",
    "preAuth": "More than 3 restorations requires  pre-authorisation.  Pre-authorisation and x-rays required \nfor any 3 or 4 surface restorations on wisdom teeth.  8937 Surgical removal of tooth  Surgical removal is only covered when authorised. 8935 Treatment of septic socket authorisation. ",
    "crucialRules": "One full mouth examination per member per covered period. 180 days apart from date of service. Limited to 3 extractions per member per covered period.  Maximum of 1 X 8201 per quadrant per visit. Limited to 3 restorations per member per covered period. ",
    "notes": "Very basic option.  Patient written consent needed for procedures not covered.  Restorations cannot be claimed on the same tooth number and service date as tariff 8132 , only adequatly motivated cases will be considered. Should a code not be in the manual then it is not covered.",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Prime",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Emergency root canal treatment",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Scale and Polish NO BENEFITS. Bite Plate NO BENEFITS, Mouth Guard NO BENEFITS\nRoot Canal NO BENEFITS, Temporary Crown NO BENEFITS, Specialised Dentistry Benefits NO BENEFITS",
    "preAuth": "Limited of 2 restorations per member per annum.",
    "crucialRules": "3 Consultations per member per annum,  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101.     2 x-rays per member per annum. Emergency Root Canal Maximum of 2 per annum. Limited to 2 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited of 2 restorations per member per annum.  1 Restoration code per  tooth number in a 9-month period.",
    "notes": " Very basic option. Patient written consent needed for procedures not covered. Trauma (1 Months) Stated Benefit All relevant trauma codes are covered to the maximum of the stated benefits (a policy report/emergency room report is required).",
    "source": "2025 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Green",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "",
    "basicTreatment": "yes",
    "rct": "yes",
    "dentures": "yes",
    "crownsOrBridges": "yes",
    "implants": "yes",
    "surgicalExtractions": "yes",
    "exclusions": "Scale and Polish NO BENEFITS. Bite Plate NO BENEFITS, Mouth Guard NO BENEFITS\nRoot Canal NO BENEFITS, Temporary Crown NO BENEFITS, Specialised Dentistry Benefits NO BENEFITS",
    "preAuth": "Limited of 2 restorations per member per annum.",
    "crucialRules": "3 Consultations per member per annum,  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101.     2 x-rays per member per annum. Emergency Root Canal Maximum of 2 per annum. Limited to 2 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited of 2 restorations per member per annum.  1 Restoration code per  tooth number in a 9-month period.",
    "notes": " Very basic option. Patient written consent needed for procedures not covered. Trauma (1 Months) Stated Benefit All relevant trauma codes are covered to the maximum of the stated benefits (a policy report/emergency room report is required).",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Green Combo",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "",
    "basicTreatment": "yes",
    "rct": "yes",
    "dentures": "yes",
    "crownsOrBridges": "yes",
    "implants": "yes",
    "surgicalExtractions": "yes",
    "exclusions": "Scale and Polish NO BENEFITS. Bite Plate NO BENEFITS, Mouth Guard NO BENEFITS\nRoot Canal NO BENEFITS, Temporary Crown NO BENEFITS, Specialised Dentistry Benefits NO BENEFITS. No Panorex.",
    "preAuth": "Limited of 2 restorations per member per annum.",
    "crucialRules": "3 Consultations per member per annum,  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101.     2 x-rays per member per annum. Emergency Root Canal Maximum of 2 per annum. Limited to 2 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited of 2 restorations per member per annum.  1 Restoration code per  tooth number in a 9-month period.",
    "notes": " Very basic option. Patient written consent needed for procedures not covered. Trauma (1 Months) Stated Benefit All relevant trauma codes are covered to the maximum of the stated benefits (a policy report/emergency room report is required).",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Bronze",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Panoramic x-ray once in a life time. All specialised dentistry must be pre-authorised and are paid according to the stated benefit. Impaction in the Chair NO BENEFITS. Impaction in the Chair NO BENEFITS. Crown NO BENEFITS. Root Canal (NO BENEFITS). Implants NO BENEFITS. ",
    "preAuth": "Occlusal guard : 1 Bite plate per member per annum. 1  Mouth guard per member per annum. One full set of plastic dentures per member every 5 years.  Maximum or R3 480. ",
    "crucialRules": "3 Consultations per member per annum.  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101. 3 x-rays per member per  annum.  Panoramic x-ray  Once per lifetime.  Limited to 2 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited to 3 restorations per member per annum. 1 Restoration code per tooth number in a 9- month period.",
    "notes": " Very basic option. Patient written consent needed for procedures not covered. Trauma (1 Months) Stated Benefit All relevant trauma codes are covered to the maximum of the stated benefits (a policy report/emergency room report is required).",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Silver",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated.  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised unless adequately motivated.  No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated.  High impact acrylic is not covered unless adequately motivated. ",
    "preAuth": "Limited to 2 root canals per member per annum.  Pre-authorisation and x\u0002rays required. Pre-authorisation and x\u0002rays required. Maximum of 2 temporary  crowns per annum. Impaction in the Chair: Limited to 4 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). Impaction in Hospital: Limited to 2 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). 1 Crown per member per annum up to R4 060 per crown. One full set of plastic dentures per member every 5 years.  Limited to R3 480 per event. 1 Implant per member per annum up to R5 800 per  implant. ",
    "crucialRules": "4 Consultations per member per annum.  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101. 5 x-rays per member per annum. 8115 Panoramic x-ray Once per lifetime. Emergency Root Canal Maximum of 3 per annum. Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited to 4 restorations \nper member per annum. ",
    "notes": " Very basic option. Patient written consent needed for procedures not covered. Trauma (1 Months) Stated Benefit All relevant trauma codes are covered to the maximum of the stated benefits (a policy report/emergency room report is required).",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "BLUE",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated.  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised unless adequately motivated.  No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated.  High impact acrylic is not covered unless adequately motivated. ",
    "preAuth": "Limited to 2 root canals per member per annum.  Pre-authorisation and x\u0002rays required. Pre-authorisation and x\u0002rays required. Maximum of 2 temporary  crowns per annum. Impaction in the Chair: Limited to 4 extractions per member per annum.  Limited to Wisdom teeth only. Impaction in Hospital: Limited to 2 extractions per member per annum.  Limited to Wisdom teeth only. 1 Crown per member per annum up to R4 060 per crown. One full set of plastic dentures per member every 5 years.  Limited to R3 480 per event. 1 Implant per member per annum up to R5 800 per  implant. ",
    "crucialRules": "4 Consultations per member per annum.  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101. 5 x-rays per member per annum. 8115 Panoramic x-ray Once per lifetime. Emergency Root Canal Maximum of 3 per annum. Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited to 4 restorations \nper member per annum. ",
    "notes": " Very basic option. Patient written consent needed for procedures not covered. Trauma (1 Months) Stated Benefit All relevant trauma codes are covered to the maximum of the stated benefits (a policy report/emergency room report is required).",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Blue Combo",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated.  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised unless adequately motivated.  No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated.  High impact acrylic is not covered unless adequately motivated. ",
    "preAuth": "Limited to 2 root canals per member per annum.  Pre-authorisation and x\u0002rays required. Pre-authorisation and x\u0002rays required. Maximum of 2 temporary  crowns per annum. Impaction in the Chair: Limited to 4 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). Impaction in Hospital: Limited to 2 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). 1 Crown per member per annum up to R4 060 per crown. One full set of plastic dentures per member every 5 years.  Limited to R3 480 per event. 1 Implant per member per annum up to R5 800 per  implant. ",
    "crucialRules": "4 Consultations per member per annum.  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101. 5 x-rays per member per annum. 8115 Panoramic x-ray Once per lifetime. Emergency Root Canal Maximum of 3 per annum. Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited to 4 restorations \nper member per annum. ",
    "notes": " Very basic option. Patient written consent needed for procedures not covered. Trauma (1 Months) Stated Benefit All relevant trauma codes are covered to the maximum of the stated benefits (a policy report/emergency room report is required).",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Red",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated.  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised unless adequately motivated.  No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated.  High impact acrylic is not covered unless adequately motivated. ",
    "preAuth": "Limited to 2 root canals per member per annum.  Pre-authorisation and x\u0002rays required. Pre-authorisation and x\u0002rays required. Maximum of 2 temporary  crowns per annum. Impaction in the Chair: Limited to 4 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). Impaction in Hospital: Limited to 2 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). 1 Crown per member per annum up to R4 060 per crown. One full set of plastic dentures per member every 5 years.  Limited to R3 480 per event. 1 Implant per member per annum up to R5 800 per  implant. ",
    "crucialRules": "4 Consultations per member per annum.  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101. 5 x-rays per member per annum. 8115 Panoramic x-ray Once per lifetime. Emergency Root Canal Maximum of 3 per annum. Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited to 4 restorations \nper member per annum. ",
    "notes": "Patient written consent needed for procedures not covered.  1Restoration code per tooth number in a 9 month period . Occlusal guard:   1 Bite plate per member per  annum. Mouth guard (members younger than 23) 1 Mouth guard per member per annum. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Red Combo",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated.  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised unless adequately motivated.  No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated.  High impact acrylic is not covered unless adequately motivated. ",
    "preAuth": "Limited to 2 root canals per member per annum.  Pre-authorisation and x\u0002rays required. Pre-authorisation and x\u0002rays required. Maximum of 2 temporary  crowns per annum. Impaction in the Chair: Limited to 4 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). Impaction in Hospital: Limited to 2 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). 1 Crown per member per annum up to R4 060 per crown. One full set of plastic dentures per member every 5 years.  Limited to R3 480 per event. 1 Implant per member per annum up to R5 800 per  implant. ",
    "crucialRules": "4 Consultations per member per annum.  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101. 5 x-rays per member per annum. 8115 Panoramic x-ray Once per lifetime. Emergency Root Canal Maximum of 3 per annum. Limited to 3 extractions per member per annum.  Maximum of 1 X 8201 per quadrant per visit. Limited to 4 restorations \nper member per annum. ",
    "notes": "Patient written consent needed for procedures not covered.  1Restoration code per tooth number in a 9 month period . Occlusal guard:   1 Bite plate per member per  annum. Mouth guard (members younger than 23) 1 Mouth guard per member per annum. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "AGS",
    "plan": "Gold",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "010 141 3080"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48) unless adequately motivated.  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised unless adequately motivated.  No Benefit for Laboratory fabricated crowns on primary teeth unless adequately motivated.  High impact acrylic is not covered unless adequately motivated. ",
    "preAuth": "Limited to 3 root canals per member per annum.  Pre-authorisation and x\u0002rays required. Provisional crown Pre-authorisation and x\u0002rays required. Maximum of 3 teeth per annum. All specialised dentistry must be pre-authorised. Impaction in the Chair: Limited to 4 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). Impaction in Hospital: Surgical removal of impacted tooth Limited to 4 extractions per member per annum.  Limited to Wisdom teeth only (teeth 18, 28, 38, 48). 2 Crowns per member per annum up to R4 640 per crown. One full set of plastic dentures per member every 5 years. 2 Implants per member per annum up to R6 960 per implant. ",
    "crucialRules": "5 Consultations per member per annum.  One 8101 per 6 months per member from date of service.  8104 - Not within 4 weeks of code 8101. 6 x-rays per member per   annum. 8115 Panoramic x-ray  Once per lifetime.  Emergency Root Canal Maximum of 3 per annum. Limited to 4 restorations per member per annum.  1 Restoration code per  tooth number in a 9-month period. Occlusal guard  1 per member per annum. Mouth guard  1 per member per annum.",
    "notes": "Patient written consent needed for procedures not covered.  1Restoration code per tooth number in a 9 month period . Occlusal guard:   1 Bite plate per member per  annum. Mouth guard (members younger than 23) 1 Mouth guard per member per annum. ",
    "source": "2024 DRC Ops manual- insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Alliance midmed",
    "plan": "Alliance midmed",
    "administrator": "Alliance-midmed",
    "contactInfo": {
      "email": [
        "auths@alliancemidmed.co.za",
        "service@alliancemidmed.co.za"
      ],
      "fax": [],
      "phone": [
        " 0860 00 2101"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants and  laboratory costs. No benefits.  Any hospitalisation related to dentistry, (For all beneficiaries above 6years) dental implant,  Mouth guard for Sport,  Oral hygiene evaluation and instruction, Fluoride treatment for beneficiaries under 5 years and 13 years and above ,  Provisional and diagnostic dentures,  Snoring appliances,  Laboratory delivery fees,  Metal Base dentures Provisional crowns,  Crown for third molar teeth,  Dentist charges for Denture repairs (Only laboratory fees are covered), The laboratory costassociated with mouth guards (Only the dentist charges will be covered).Crowns or crown retainers on third molars (wisdom teeth). No benefits. ",
    "preAuth": "Dentistry - Crown and Bridge Per Family benefit. Pre-auth required. Co-payment of 10% applies.  Limited to 3 crowns per beneficiary and limited to once per tooth in 5-year period. - Orthodontics Limited to two family members per year, aged between 9 and 18 years. Pre-auth required. Co-payment of 10% applies. - Maxillo-Facial Surgery Including Wisdom teeth extractions. 100% of the Alliance Midmed rate. Limited to treatment in the dental chair. Pre-auth required. Provide quote and x-rays. Co-payment of 10% applies.  Anaesthetic - Laughing Gas & Conscious Sedation Pre-auth required.  Anaesthetic -  Limited to children under 7 years. ",
    "crucialRules": "A specific tooth may only be filled once in two (2) years (720 days . \nBit plate (also known as occlusal splint)  In Hospital dentistry for children Limited to children under 7 years. ",
    "notes": "Partial metal frame dentures and associated laboratory costs. Covered at 90% of Alliance-MidMed rate. ",
    "source": "https://www.alliancemidmed.co.za/assets/documents/benefitguide/dentalbenefitguidelinesummary.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Anglo Medical aid",
    "plan": "Managed Care",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "member@angloms.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 222 633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "",
    "preAuth": "Dental hospitalisation (including medicine and related products): In the case of trauma or patients under the age of 7 years requiringanaesthetic, the removal of impacted molars, maxillo-facial and oral surgery (PMB conditions.  Specialised treatments including crowns, bridges, inlays, study models, dentures, orthodontics, osseo-integrated implants or similar tooth implants and periodontics. ",
    "crucialRules": "Panorex every 3 years. Medical Savings Account: Dental services including orthodontic treatment (after your basic dentistry benefit has been exhausted). Dentistry: Conservative treatments including fillings, x-rays, extractions and oral hygiene. Specialised treatments including crowns, bridges, inlays, study models, dentures, orthodontics, osseo-integrated implants or similar tooth implants and periodontics . Family Limit Adult R4 885, Child R1 845",
    "notes": "Once the dental benefit is depleted, payment will be allocated to available MSA. Up to 125% of SRR for non-PMB specialised dental services, performed by dental specialist. Cost above SRR may be paid from your available MSA upon instruction",
    "source": "https://www.angloms.co.za/wcm/medical-schemes/angloms/assets/benefit-guides/2024/ams-benefit-guide.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Anglo Medical aid",
    "plan": "Standard",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48).  No Benefit for Pontics on second molars (17/27/37/47) unless 8\u2019s are fully functional and pre\u0002authorised. No Benefit for Laboratory fabricated crowns on primary teeth.  High impact acrylic is not covered unless adequately motivated",
    "preAuth": " Pre-authorisation and x-rays required for:  More than 5 restorations per year (especially anterior teeth).  2 or more 3 or 4 surface restorations per visit.  More than 2 anterior restorations  per visit.  Multiple restorations on an anterior tooth on the same  service date. Consultation for a specific problem, Not within 4 weeks of 8101 (8102 not covered). (Motivation required for code 8104 if no covered clinical procedure is charged). Extraction Codes: Maximum 1 x 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Root Canal Treatment (Must be pre-authorised):  RCT on posterior teeth limited to members 12 years and older. Emergency root canal treatment.  Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an  x-ray to show the complete root canal.",
    "crucialRules": " Panoramic x-ray: One per member in a 3-year period from date of service. Benefit limited to members older than 5 years unless motivated. Sterilised  instrumentation  Will only be paid if code 9011, 9013, 8731, 8201 or 8202 is claimed.   Posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised. Any 3 or 4 surface restorations on wisdom teeth.  1 Restoration code per tooth . Root Canal Treatment: Retreatment subject to preauthorisation and managed care protocols.  No cover for third molars (8\u2019s).  No cover for primary teeth. Denture Codes: Once every 4 years per member  One per jaw (set of dentures) every 4 years.  Rebase, Reline, repair, Remodel: More than one per 365 days require motivation preauthorisation.",
    "notes": "Patient written consent needed for procedures not covered. f you choose to use a dentist outside the network, you will have to pay a co-payment (the difference between 80% of the Scheme Reimbursement Rate and the claimed amount). Services are limited to the basic dental services listed below and subject to DRC protocols.Additional dentistry codes not stated above are covered by the stated benefit calculated per dependant. \nAnybody in the family can use the Family limit:  Adult \u2013 R1 800 per dependant. Child R450. ",
    "source": "2024 DRC ops  manual - Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Anglo Medical aid",
    "plan": "Value",
    "administrator": "Primecure",
    "contactInfo": {
      "email": [
        "support@primecure.co.za"
      ],
      "fax": [],
      "phone": [
        "0861665665"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Panoramic and other extra oral x-rays (e.g., 8115) does not form part of the benefit schedule. No benefits for Crowns, Implants, Ortho.",
    "preAuth": "Intra oral radiographs/Bite Wing X-rays, per film (maximum of 3 X-rays per beneficiary per year) authorisation required after the 4th. Treatment of septic socket First treatment no authorisation is required. For second treatment and onward authorisation is necessary (historic treatment of 8201/8202). Extractions - Pre-Authorisation is needed for five or more extractions per beneficiary per year. One (1) surgical extraction per beneficiary per year. Must be authorised. maximum of 3 amalgam/resin restorations. Additional need to be pre-authorised. 8162 needs to be authorised. Dentures  Authorisation required. 1 Set of dentures allowed per family per 24-month cycle. Four or more anterior resin restoration per beneficiary per year  Five or more non-surgical extractions (8201, 8202) per beneficiary per year  Dentures, reline, rebase and all specialised dentistry - Partial dentures - Tooth numbers required  Sutures.  Code 8144 (Intravenous/conscious sedation): refer to Prime Cure case manager for pre-authorisation (Full risk schemes only - pre-authorisation required) ",
    "crucialRules": "Consultation oral examination Once per year. 8131 Palliative (emergency) dental pain treatment. 8132 Gross pulpal debridement, primary & permanent teeth (emergency root canal). 8307 Pulp amputation (pulpotomy) - Only on primary teeth - maximum two per year per beneficiary. ",
    "notes": "Cleaning, scaling, polishing and fluoride treatment are limited to one treatment per beneficiary per year. Dental emergency out of network visits are limited to one event per beneficiary per year to cover emergency pain management, sepsis and extractions paid at the Prime Cure tariff.",
    "source": "https://www.primecure.co.za/wp-content/uploads/2023/12/Prime-Cure-2024-Dental-Manual.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bankmed",
    "plan": "Basic",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex or specialised dentistry.  No benefit for in-hospital dental treatment, except PMBs. ",
    "preAuth": "Pulp removal (pulpectomy) 8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or  scanned x-ray to show the complete root canal.  Pre-authorisation and x-rays required for:  More than 4 restorations per year (especially anterior teeth).  Two or more 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.  Multiple restorations on an anterior tooth on the same service date.  Posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  Any 3 or 4 surface restorations on \nwisdom teeth. All dentures require Pre-authorisatio.  Repair,  Rebase, Remodel,  Reline,  Adjustment of denture Pre-authorisation required. .Extraction Codes  More than 4 per year require pre- authorisation.",
    "crucialRules": " Maximum of 2 x-rays per visit per member. Maximum quantity of 7 per year. Sterilised and instrumentation  Maximum 1 per visit. Will only be paid if \ncode 8731, 9013, 9011, 8201 or 8202 is claimed. Restoration Codes: Posterior Amalgam and Resin restorations remunerated at the same tariff below. 1 Restoration code per tooth number in a 9 month period.",
    "notes": "No Panorex. Very basic option  Patient written consent needed for procedures not covered.",
    "source": "2024 DRC Op manual - capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bankmed",
    "plan": "Traditional",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "treatment@bankmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0800 226 5633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48). \uf0b7 No Benefit for Pontics on second molars (17/27/37/47). No Benefit for Laboratory fabricated crowns on primary teeth.",
    "preAuth": "Authorisation all Advanced Dentistry. Orthodontic Treatment is included in the combined benefit. Limited to: \u2022 M: R8 370 pbpa               \u2022 M + 1 +: R12 985 pfpa",
    "crucialRules": " One oral examination pbpa.  Amalgam and resin fillings only.  Plastic dentures only.  Two topical fluoride treatments pbpa (age 15 years and younger).  One topical fluoride treatment pfpa.  Limited to eight molar teeth pb per lifetime.  Scale and polish limited to two pbpa. ",
    "notes": "Plastic dentures pays from basic dentistry. Pre-authorisation for a hospital admission does not guarantee that all claims related to the hospital event will be covered in full. ",
    "source": "https://www.bankmed.co.za/wcm/medical-schemes/bankmed/assets/find-a-document/benefit-and-contribution-schedule-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bankmed",
    "plan": "Core saver",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "treatment@bankmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0800 226 5633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48).  No Benefit for Pontics on second molars (17/27/37/47). No Benefit for Laboratory fabricated crowns on primary teeth.",
    "preAuth": "Authorisation all Advanced Dentistry",
    "crucialRules": "Limited to available MSA",
    "notes": "Subject to available Medical Savings Account. Pre-authorisation for a hospital admission does not guarantee that all claims related to the hospital event will be covered in full",
    "source": "https://www.bankmed.co.za/wcm/medical-schemes/bankmed/assets/find-a-document/benefit-and-contribution-schedule-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bankmed",
    "plan": "Comprehensive",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "treatment@bankmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0800 226 5633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48).  No Benefit for Pontics on second molars (17/27/37/47). No Benefit for Laboratory fabricated crowns on primary teeth.",
    "preAuth": "Advanced dentistry Caps, crowns, bridges and cost of endosteal and osseaintegrated implants. Limited to:  M: R6 520 pbpa    M + 1 +: R10 920 pfpa       Thereafter subject to\navailable MSA. Orthodontics: Orthodontics:  Limited to R10 920 pfpa.      Thereafter subject to available MSA",
    "crucialRules": " One oral examination pbpa.  Amalgam and resin fillings only.  Plastic dentures only.  Two topical fluoride treatments pbpa (age 15 years and younger).  One topical fluoride treatment pfpa.  Limited to eight molar teeth pb per lifetime.  Scale and polish limited to two pbpa. ",
    "notes": "Unlimited; paid from Insured Benefit. Pre-authorisation for a hospital admission does not guarantee that all claims related to the hospital event will be covered in full.",
    "source": "https://www.bankmed.co.za/wcm/medical-schemes/bankmed/assets/find-a-document/benefit-and-contribution-schedule-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bankmed",
    "plan": "Plus Plan",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "treatment@bankmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0800 226 5633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48).  No Benefit for Pontics on second molars (17/27/37/47). No Benefit for Laboratory fabricated crowns on primary teeth.",
    "preAuth": "Advanced Dentistry Caps, crowns, bridges and cost of endosteal and ossea\u0002integrated implants",
    "crucialRules": "Combined limit for advanced dentistry, orthodontics and all other dental services.  Limited to eight molar \nteeth pb per lifetime. ",
    "notes": " subject to available Medical Savings Account",
    "source": "https://www.bankmed.co.za/wcm/medical-schemes/bankmed/assets/find-a-document/benefit-and-contribution-schedule-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Barloworld",
    "plan": "Barloworld",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "bcima@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860002106"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48).  No Benefit for Pontics on second molars (17/27/37/47). No Benefit for Laboratory fabricated crowns on primary teeth.",
    "preAuth": "Authorisation all Advanced Dentistry. Orthodontic Treatment is included in the combined benefit.",
    "crucialRules": " Benefit for retreatment of a tooth is subject to clinical protocols. ",
    "notes": "The combined benefit incorporates more advanced dental procedures, including treatment for inlays, crowns, root canal therapy, bridges, mounted study models, dentures and the treatment by periodontists, prosthodontists and dental technician's fees for all such dentistry. The benefit includes Oral medical procedures and metal base to complete denture code 8663.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "BCIMAS",
    "plan": "Plus",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "bcima@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0112081005"
      ]
    },
    "networked": "no",
    "nonNetworkedPayment": "no",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48).  No Benefit for Pontics on second molars (17/27/37/47). No Benefit for Laboratory fabricated crowns on primary teeth.",
    "preAuth": "Authorisation all Advanced Dentistry. Orthodontic Treatment is included in the combined benefit.",
    "crucialRules": "Conservative:  Subject and limited to available PMSA. Specialised Dentistry:  Subject and limited to available PMSA. Orthodontic treatment:  Subject and limited to available PMSA.",
    "notes": "Conservative: 100% of Scheme tariff - Subject and limited to available PMSA. Specialised Dentistry: 100% of Scheme tariff - Subject and limited to available PMSA. Orthodontic treatment: 100% of Scheme tariff - Subject and limited to available PMSA.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "BCIMAS",
    "plan": "Basic",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "bcima@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0112081005"
      ]
    },
    "networked": "no",
    "nonNetworkedPayment": "no",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth.  No Benefit for Crowns on third molars (wisdom teeth \u2013 18/28/38/48).  No Benefit for Pontics on second molars (17/27/37/47). No Benefit for Laboratory fabricated crowns on primary teeth.",
    "preAuth": "Authorisation all Advanced Dentistry Specialised: crowns, bridgework, orthodontics, periodontics, prosthodontics, plastic dentures, maxillo-facial, oral surgery, etc. . Orthodontic Treatment is included in the combined benefit. \u2022 Dentistry (in-hospital procedures, subject to pre-authorisation) ",
    "crucialRules": "Preventative Care Benefit - Unlimited and will not affect your Hospital and Day-to-Day Limits. , Conservative Dentistry, For beneficiaries under 6 years of age",
    "notes": " Very basic option   ",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Beat  1",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Major medical maxilla-facial surgery: No benefits, except for the treatment of PMB conditions as per standard of care in the State sector which shall be paid at cost at DSP day hospitals.  Basic and specialised dentistry No benefit.",
    "preAuth": " Pre-authorisation must be obtained for any surgical procedure that needs to be performed in a theatre and shall be payable at 100% Scheme tariff",
    "crucialRules": "n pre-authorisation for all planned treatments and/or procedures.",
    "notes": "Hospital Plan",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2024",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Beat 2. HOSPITAL PLAN (WITH SAVINGS)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Major medical maxilla-facial surgery; No benefits, except for the treatment of PMB conditions as per standard of care in the State sector which shall be paid at cost at DSP day hospitals.  The cost of gold, metal or other inlays in a denture and/or crown.\n Bleaching of vital teeth.  Lingual orthodontics.",
    "preAuth": "Major maxillofacial surgery, strictly related to certain conditions PMBs only.  PMBs only at DSP day hospitals. Beneficiaries 7 years and younger Limited to R6 071 per family.\nBeneficiaries over 7 years Dental surgical procedures paid from  savings for procedures performed in the doctor\u2019s rooms only. Surgical extractions of teeth / roots / impactions / failed implants; - Surgical drainage of dental abscess; - Alveolectomy / alveolotomy (preparatory surgery for dental prosthesis); - Root canal related surgery. Orthodontic: Subject to pre-authorisation",
    "crucialRules": "Basic: Preventative benefit or savings account. Specialised: Savings account. Orthodontic: Subject to pre-authorisation. Basic and specialised dentistry: shall be paid from the PMSA:",
    "notes": "Limited day-to-day benefits are available.   General full-mouth examination by a general dentist  (including gloves and use of sterile equipment for the visit) 12 years and above. Once a year. Under 12 years.Twice a year. Full-mouth intra-oral radiographs All ages. Once every 36 months. Intra-oral radiograph All ages. 2 photos per year.Scaling and/or polishing All ages. Twice a year (i.e. every 6 months from the date of service). Fluoride treatment All ages. Twice a year (i.e. every 6 months from the date of service). Fissure sealing Up to and including 21 years. In accordance with accepted protocol. Space maintainers During primary and mixed denture stage. Once per space.",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2025",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Beat 3 HOSPITAL PLAN (WITH SAVINGS AND \nSELECTED RISK BENEFITS)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "yes",
    "exclusions": "Surgical extractions of teeth / roots / impactions / failed implants; - Surgical drainage of dental abscess;  Bleaching of vital teeth.  Lingual orthodontics.  The cost of gold, metal or other inlays in a denture and/or crown.",
    "preAuth": "Dental and oral surgery (in or out of hospital) Limited to R9 338 per family per annum. Orthodontic: Subject to  pre-authorisation. Major maxillofacial surgery, strictly related to certain conditions 100% Scheme tariff. Limited to R14 969 per family per annum.",
    "crucialRules": "Basic: Preventative benefit or savings account. Specialised: Savings account. Orthodontic: Subject to pre-authorisation",
    "notes": "Limited day-to-day benefits are available.   General full-mouth examination by a general dentist  (including gloves and use of sterile equipment for the visit) 12 years and above. Once a year. Under 12 years.Twice a year. Full-mouth intra-oral radiographs All ages. Once every 36 months. Intra-oral radiograph All ages. 2 photos per year.Scaling and/or polishing All ages. Twice a year (i.e. every 6 months from the date of service). Fluoride treatment All ages. Twice a year (i.e. every 6 months from the date of service). Fissure sealing Up to and including 21 years. In accordance with accepted protocol. Space maintainers During primary and mixed denture stage. Once per space.",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2026",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Beat 4 HOSPITAL PLAN (WITH SAVINGS AND \nDAY-TO-DAY BENEFITS)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Major maxillofacial surgery, strictly related to certain conditions 100% Scheme tariff. Limited to R15 244 per family per annum. Dental and oral surgery (in or out of hospital) Limited to R11 673 per family per annum. Orthodontics are subject to pre-authorisation.  Dental / Oral / Jaw surgery  Pre-authorisation must be obtained for any surgical procedure that needs to be performed in a theatre and shall be payable at 100% Scheme tariff.",
    "crucialRules": "Basic and specialised dentistry Savings and then from day-to-day limit. Orthodontics are subject to pre-authorisation.\nLimited to M = R6 534, M1+ = R13 124. (Subject to overall day-to-day limit). ",
    "notes": "Basic: Preventative benefit or savings account. Specialised: Savings account.  General full-mouth examination by a general dentist  (including gloves and use of sterile equipment for the visit) 12 years and above. Once a year. Under 12 years.Twice a year. Full-mouth intra-oral radiographs All ages. Once every 36 months. Intra-oral radiograph All ages. 2 photos per year.Scaling and/or polishing All ages. Twice a year (i.e. every 6 months from the date of service). Fluoride treatment All ages. Twice a year (i.e. every 6 months from the date of service). Fissure sealing Up to and including 21 years. In accordance with accepted protocol. Space maintainers During primary and mixed denture stage. Once per space.",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2027",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Pace1",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Dental and oral surgery (in or out of hospital) Limited to R9 338 per family per annum. Major maxillofacial surgery, strictly related to certain conditions 100% Scheme tariff. Limited to R15 105 per family per annum. Orthodontic: Subject to pre-authorisation",
    "crucialRules": "Basic and specialised dentistry Savings and then from day-to-day limits. . Limited to M = R4 778, M1+ = R9 696. (Subject to overall day-to-day limit)",
    "notes": "Basic: Preventative benefit or savings account. Specialised: Savings account.  General full-mouth examination by a general dentist  (including gloves and use of sterile equipment for the visit) 12 years and above. Once a year. Under 12 years.Twice a year. Full-mouth intra-oral radiographs All ages. Once every 36 months. Intra-oral radiograph All ages. 2 photos per year.Scaling and/or polishing All ages. Twice a year (i.e. every 6 months from the date of service). Fluoride treatment All ages. Twice a year (i.e. every 6 months from the date of service). Fissure sealing Up to and including 21 years. In accordance with accepted protocol. Space maintainers During primary and mixed denture stage. Once per space.",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2028",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Pace2",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Major maxillofacial surgery, strictly related to certain conditions 100% Scheme tariff. Dental and oral surgery (in or out of hospital) Limited to R15 518 per family per annum. Orthodontic dentistry Savings first. 100% Scheme tariff. Subject to pre-authorisation. Limited to R7 769 per event for beneficiaries up to 18 years of age. Subject to overall day-to-day limit.",
    "crucialRules": "Basic and specialised dentistry Savings and then from day-to-day limit. Limited to M = R8 009, M1+ = R16 019. (Subject to overall day-to-day limit)",
    "notes": "Basic: Preventative benefit or savings account. Specialised: Savings account.  General full-mouth examination by a general dentist  (including gloves and use of sterile equipment for the visit) 12 years and above. Once a year. Under 12 years.Twice a year. Full-mouth intra-oral radiographs All ages. Once every 36 months. Intra-oral radiograph All ages. 2 photos per year.Scaling and/or polishing All ages. Twice a year (i.e. every 6 months from the date of service). Fluoride treatment All ages. Twice a year (i.e. every 6 months from the date of service). Fissure sealing Up to and including 21 years. In accordance with accepted protocol. Space maintainers During primary and mixed denture stage. Once per space.",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2029",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Pace3",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors",
    "preAuth": "Major maxillofacial surgery, strictly related to certain conditions 100% Scheme tariff. Dental and oral surgery (in or out-of-hospital) Limited to R19 500 per family per annum. Orthodontic dentistry Savings first. 100% Scheme tariff. Subject to pre-authorisation. Limited to \nR9 989 per event for beneficiaries up to 18 years of age. Subject to overall day-to-day limit",
    "crucialRules": "Basic and specialised dentistry Savings first and then from day-to-day limit. Limited to M = R8 630, M1+ = R16 089. (Subject to overall day-to-day limit",
    "notes": "Basic: Preventative benefit or savings account. Specialised: Savings account.  General full-mouth examination by a general dentist  (including gloves and use of sterile equipment for the visit) 12 years and above. Once a year. Under 12 years.Twice a year. Full-mouth intra-oral radiographs All ages. Once every 36 months. Intra-oral radiograph All ages. 2 photos per year.Scaling and/or polishing All ages. Twice a year (i.e. every 6 months from the date of service). Fluoride treatment All ages. Twice a year (i.e. every 6 months from the date of service). Fissure sealing Up to and including 21 years. In accordance with accepted protocol. Space maintainers During primary and mixed denture stage. Once per space.",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2030",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Pace4",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "\u2022 Laboratory delivery fees \u2022 Othognatic surgery \u2022 Sinus lift \u2022 Gum guards or mouth protectors. ",
    "preAuth": "Major maxillofacial surgery, strictly related to certain conditions 100% Scheme tariff. Dental and oral surgery (in or out-of-hospital) Limited to R23 345 per family per annum. Orthodontic dentistry 100% Scheme tariff. Subject to pre-authorisation. Limited to R12 208 per event for beneficiaries up to 18 years of age. (Subject to overall day-to-day limit) ",
    "crucialRules": "Basic and specialised dentistry Limited to M = R14 403, M1+ = R24 310. (Subject to overall day-to-day limit)",
    "notes": "Basic: Preventative benefit or savings account. Specialised: Savings account.  General full-mouth examination by a general dentist  (including gloves and use of sterile equipment for the visit) 12 years and above. Once a year. Under 12 years.Twice a year. Full-mouth intra-oral radiographs All ages. Once every 36 months. Intra-oral radiograph All ages. 2 photos per year.Scaling and/or polishing All ages. Twice a year (i.e. every 6 months from the date of service). Fluoride treatment All ages. Twice a year (i.e. every 6 months from the date of service). Fissure sealing Up to and including 21 years. In accordance with accepted protocol. Space maintainers During primary and mixed denture stage. Once per space.",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2031",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Rhythm 1 (Pulse)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " Crowns, dentures and other specialised dentistry are not covered. Root canal treatment and other advanced dentistry. Dental and oral surgery (in and/or out of hospital) No benefit, except in respect of PMB conditions.  Major medical maxilla-facial surgery No benefit, except in respect of PMB conditions.",
    "preAuth": "Major maxillofacial surgery, strictly related to certain conditions Approved PMBs at DSPs. Dental and oral surgery (in or out of hospital) Approved PMBs at DSPs.",
    "crucialRules": "Basic dentistry Where clinically appropriate and subject to Bestmed Rhythm1 protocols, Bestmed Rhythm Dental Network Providers and Rhythm approved dental codes.",
    "notes": "Primary extractions if clinically necessary;",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2032",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bestmed",
    "plan": "Rhythm 2 (Pulse)",
    "administrator": "Bestmed",
    "contactInfo": {
      "email": [
        "service@bestmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860002378"
      ]
    },
    "networked": "yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " Crowns,  and other specialised dentistry are not covered. Root canal treatment and other advanced dentistry. Dental and oral surgery (in and/or out of hospital) No benefit, except in respect of PMB conditions.  Major medical maxilla-facial surgery No benefit, except in respect of PMB conditions. No benefits shall apply for dentures; \nand  No benefits shall apply for specialised dentistry.",
    "preAuth": "Major maxillofacial surgery, strictly related to certain conditions Approved PMBs at DSPs. Dental and oral surgery (in or out of hospital) Approved PMBs at DSPs. 1 (one) set of dentures per family per 24 (twenty-four) months. Benefits shall be subject to the use of accredited \ndental laboratories; ",
    "crucialRules": "Basic dentistry Where clinically appropriate and subject to Bestmed Rhythm2 protocols, Bestmed Rhythm Dental Network Providers and Rhythm approved dental codes. Dentures Limited to a maximum of 2 removable acrylic dentures (i.e. 2 single denture plates) per family every 24 months. 2 (two) consultations for full mouth examination per beneficiary",
    "notes": "Primary extractions if clinically necessary;",
    "source": "https://www.bestmed.co.za/plans-and-options/brochures-guides-and-forms#!guidesandbrochures-2033",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "BEWELL",
    "plan": "Bewell 100",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No advanced dentistry, no Ortho, No dentures, No Implants, No Hospital, theatre benefits. No Panorex. No Rootcanal fillings.",
    "preAuth": "Motivation and records required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3rd Molars) Benefit for fillings is granted once per tooth in 9 months",
    "crucialRules": "Two consultations per dependant per year (once every 6 months). One specific consultation for pain and sepsis per dependant per year; not within 4 weeks of 8101. Maximum of 2 X-rays films per visit per dependant . Two polishing and scaling treatments per dependant per year (once every 6 months 8155 or 8159)",
    "notes": "Pulpectomy (pain relief) Not covered on primary teet",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/NHG-BeWell-100-Series-Dental-Benefit-Table-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "BMW Employees ",
    "plan": "",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "service@discovery.co.za",
        "claims@discovery.co.za"
      ],
      "fax": ["086 044 5566"],
      "phone": [
        "086 000 2107"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Bleaching of teeth that have not had root canal treatment,  metal inlays in dentures and front teeth. Mouth protectors and gold dentures. This benefit excludes hospitalisation for: Osseo-integrated implants and orthognathic surgery ",
    "preAuth": "Maxillofacial and oral surgery 150% of Society Rate Subject to overall annual limit and preauthorisation. Authorisation for all advanced dentistry.",
    "crucialRules": "Advanced dentistry including services for inlays, crowns, bridges, mounted study models, metal base partial dentures, the treatment by periodontists, prosthodontists ",
    "notes": "Specialised dentistry .  Main member only R12,522    Family R26,987.   Basic dentistry  R1,850 for a member each year. Members younger than 13 years have a hospital deductible (upfront payment) of R2 600 and a deductible of R1 300 at a day clinic Members 13 years or older will have a hospital deductible (upfront payment) of R7 000 and a deductible of R4 400 at a day clinic",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Boncomprehensive",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 336"],
      "phone": [
        "0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury. Implants on third molars. Orthodontics: Pre-authorisation required. ",
    "preAuth": "Fillings: Multiple fillings: A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required. Two partial frames (an upper and a lower) per beneficiary in a 5-year period . Crown & Bridge Pre-authorisation required Three crowns per family per year. Implants Pre-authorisation required. Two implants per beneficiary in a 5-year period. Cost of implant components limited to R3 387 per implant. Benefit for orthodontic treatment granted once per beneficiary per lifetime. Periodontics Pre-authorisation required. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair. Hospitalisation (General Anaesthetic) Pre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment.Moderate/Deep Sedation \nin the Dental Rooms Pre-authorisation required. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. Fillings Benefit for fillings: Granted once per tooth in 720 days. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "notes": "Please note that Medscheme will be responsible for the payment of all dental related claims on the BonComprehensive option. Consultations Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: \nLimited to beneficiaries from age 5 and younger than 16 years of age. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Bonclassic",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury. Implants on third molars.  Apicectomies,  Dentectomies,  Frenectomies. ",
    "preAuth": " Multiple fillings: A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period.  Limit: Benefit for Plastic Dentures is payable from the available Conservative Dentistry limit. Specialised Dentistry limit of R6 997 per family per year. Partial Chrome Cobalt Frame Dentures Pre-authorisation required. Two partial frames (an upper and a lower) per beneficiary in a 5-year period . Crown & Bridge Pre-authorisation required. One  crown per family per year. Benefit for orthodontic treatment granted once per beneficiary per lifetime. Benefit for fixed comprehensive treatment: Limited to individuals from age 9 and younger than 18 years of age.  Periodontics Pre-authorisation required. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair. Hospitalisation (General Anaesthetic) Pre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment.Moderate/Deep Sedation in the Dental Rooms Pre-authorisation required. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period.  Benefit for fillings: Granted once per tooth in 720 days.Multiple fillings: A treatment \nplan and X-rays may be required for multiple fillings.  Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "notes": "BONCLASSIC: Conservative Dentistry limit of R5 812 per family per year. Consultations Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: \nLimited to beneficiaries from age 5 and younger than 16 years of age. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Standard ",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": " Multiple fillings: A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period.  Partial Chrome Cobalt Frame Dentures Pre-authorisation required. Two partial frames (an upper and a lower) per beneficiary in a 5-year period . Crown & Bridge Pre-authorisation required. One  crown per family per year. Benefit for orthodontic treatment granted once per beneficiary per lifetime. Benefit for fixed comprehensive treatment: Limited to individuals from age 9 and younger than 18 years of age.  Periodontics Pre-authorisation required. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair. Hospitalisation (General Anaesthetic) Pre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment.Moderate/Deep Sedation in the Dental Rooms Pre-authorisation required. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period.  Benefit for fillings: Granted once per tooth in 720 days.Multiple fillings: A treatment plan and X-rays may be required for multiple fillings.  Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "notes": " Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 16 years of age. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Standard Select",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": " Multiple fillings: A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures Pre-authorisation required. Two partial frames (an upper and a lower) per beneficiary in a 5-year period . Crown & Bridge Pre-authorisation required. One  crown per family per year. Benefit for orthodontic treatment granted once per beneficiary per lifetime. Benefit for fixed comprehensive treatment: Limited to individuals from age 9 and younger than 18 years of age.  Periodontics Pre-authorisation required. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair. Hospitalisation (General Anaesthetic) Pre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment.Moderate/Deep Sedation in the Dental Rooms Pre-authorisation required. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period.  Benefit for fillings: Granted once per tooth in 720 days.Multiple fillings: A treatment plan and X-rays may be required for multiple fillings.  Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "notes": " Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 16 years of age. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Boncomplete",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": " Multiple fillings: A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period.  Partial Chrome Cobalt Frame Dentures Pre-authorisation required. Two partial frames (an upper and a lower) per beneficiary in a 5-year period . Crown & Bridge Pre-authorisation required. One  crown per family per year. Benefit for orthodontic treatment granted once per beneficiary per lifetime. Benefit for fixed comprehensive treatment: Limited to individuals from age 9 and younger than 18 years of age.  Periodontics Pre-authorisation required. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair. Hospitalisation (General Anaesthetic) Pre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment.Moderate/Deep Sedation in the Dental Rooms Pre-authorisation required. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period.  Benefit for fillings: Granted once per tooth in 720 days.Multiple fillings: A treatment plan and X-rays may be required for multiple fillings.  Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "notes": " Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from age 5 and younger than 16 years of age. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Primary",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": " Multiple fillings: A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair. Hospitalisation (General Anaesthetic) Pre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment.Deep Sedation in the Dental Rooms Pre-authorisation required. ",
    "crucialRules": "There is a benefit for one out-of-network emergency consultation (tariff code 8104) per beneficiary per year. The following treatment is covered when charged with code 8104: extractions (code 8201 \u2013 max of 2); a pulpotomy (8307 \u2013 max of 2) or a pulpectomy (8132 \u2013 max of 2).Two check-ups per\nbeneficiary per year (once every 6 months) Fillings : Granted once per tooth in 720 days. ",
    "notes": " Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Primary Select",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No crowns, bridges, implants, ortho. No Specialised dentistry. No Metal Plate dentures. Partial Chrome Cobalt Frame Dentures No benefits. Crown & Bridge No Benefits, Implants No benefits, Orthodontics No benefits, ",
    "preAuth": " Multiple fillings: A treatment plan and X-rays required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair. Hospitalisation (General Anaesthetic) Pre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment.Deep Sedation in the Dental Rooms Pre-authorisation required. ",
    "crucialRules": "Emergency consultation (tariff code 8104) per beneficiary per year. The following treatment is covered when charged with code 8104: extractions (code 8201 \u2013 max of 2); a pulpotomy (8307 \u2013 max of 2) or a pulpectomy (8132 \u2013 max of 2). Fillings : Granted once per tooth in 720 days",
    "notes": " Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Bonsave",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": " Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures, One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Partial Chrome Cobalt Frame DenturesPre- authorisation required . Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge Pre-authorisation required Three crowns per family per year. ",
    "crucialRules": " PAYABLE BY DENIS FROM RISK:  Fillings Benefit for fillings: Granted once per tooth in 720 days  Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. X-rays: Extraoral One per beneficiary in a 3-year period. Deep Sedation in the Dental Rooms Pre-authorisation required",
    "notes": " Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Bonfit",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": " Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required for Dentures, One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period . Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Partial Chrome Cobalt Frame DenturesPre- authorisation required . Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Crown & Bridge Pre-authorisation required Three crowns per family per year. ",
    "crucialRules": " Fillings Benefit for fillings: Granted once per tooth in 720 days  Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. X-rays: Extraoral One per beneficiary in a 3-year period. Deep Sedation in the Dental Rooms Pre-authorisation required.Hospitalisation (General Anaesthetic) No benefit for In Hospital (General Anaesthetic) dentistry, except for PMBs.",
    "notes": " Two check-ups per beneficiary per year (once every 6 months). Preventative Care Two scale and polish treatments per beneficiary per year (once every 6 months) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Boncap",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.  X-rays Extraoral \u2013 NO Benefit.",
    "preAuth": "One set of plastic dentures (an upper and a lower) per family in a 2- year period for beneficiaries 21 years and older. 20% co-payment applies. The appropriate laboratory codes will be covered. 20% co-payment  applies. ",
    "crucialRules": " One check-up per beneficiary per year (Once every 365 days). One specific consultation (emergency) for pain. Benefit for emergency treatment only - 8131. Emergency Pulp removal for the relief of acute pain prior to root \ncanal therapy. Extractions (Removal of Teeth) 8201 Extraction of tooth or exposed roots. Benefit for 4 fillings per beneficiary per year. Benefit for fillings are granted once per tooth in 720 days. Rebase of denture once per family per year for beneficiaries 21 years and older. 20% co-payment applies. Repairs to existing dentures twice per family per year for beneficiaries 21 years and older. 20% co-payment applies. ",
    "notes": "Please note that Medscheme will be responsible for the payment of all dental claims (Out-of-hospital dentistry, In-hospital PMB dentistry and related anaesthetics) on the BonCap option.    Rebase of denture once per family per year. 20% co-payment applies. The appropriate laboratory codes will be covered. 20% co-payment applies. Repairs to existing dentures twice per family per year. 20% co-payment applies. The appropriate laboratory codes will be covered.  20% co-payment applies. Patient written Accountability form needed for procedures not covered.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "BonEssential",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Basic Dentistry. No Specialised dentistry. Only Preventative Care Fissure sealants (tariff code 8163) are available for beneficiaries younger than 16 years of age and limited to one per tooth in 3 years",
    "preAuth": "Maxillo-facial Surgery in the dental chair: Benefit subject to managed care protocols. /Deep Sedation in the Dental Rooms Pre-authorisation required.",
    "crucialRules": "Only Preventative Care. Consultations No benefit. X-rays: Intraoral No benefit. X-rays: Extraoral No benefit . General anaesthetic benefit available for the removal of impacted teeth ONLY.",
    "notes": "Patient written Accountability form needed for procedures not covered.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "BonEssential Select",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Basic Dentistry. No Specialised dentistry. Only Preventative Care Fissure sealants (tariff code 8163) are available for beneficiaries younger than 16 years of age and limited to one per tooth in 3 years",
    "preAuth": "Maxillo-facial Surgery in the dental chair: Benefit subject to managed care protocols. /Deep Sedation in the Dental Rooms Pre-authorisation required.",
    "crucialRules": "Only Preventative Care. Consultations No benefit. X-rays: Intraoral No benefit. X-rays: Extraoral No benefit . General anaesthetic benefit available for the removal of impacted teeth ONLY.",
    "notes": "Patient written Accountability form needed for procedures not covered.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "BonStart",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 338"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Basic Dentistry. No Specialised dentistry.",
    "preAuth": "No benefit for In-hospital (General Anaesthetic) dentistry, except for PMBs. Subject to BonStart Hospital Network Subject to pre-authorisation",
    "crucialRules": "Only Preventative dental benefits. One scale and polish treatment per \nbeneficiary per year (not within 6  months from the previous year\u2019s \nscale and polish) Code 8155 or 8159 (max of 1 in  total per year). ",
    "notes": " One consultation per beneficiary per year (not within 6 months from \nthe previous year\u2019s consultation) R120 co-payment applies to the \ncharged code 8101 Patient written Accountability form needed for procedures not covered.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "BonStart Select",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 337"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Basic Dentistry. No Specialised dentistry.",
    "preAuth": "No benefit for In-hospital (General Anaesthetic) dentistry, except for PMBs. Subject to BonStart Hospital Network Subject to pre-authorisation",
    "crucialRules": "Only Preventative dental benefits. One scale and polish treatment per \nbeneficiary per year (not within 6  months from the previous year\u2019s \nscale and polish) Code 8155 or 8159 (max of 1 in  total per year). ",
    "notes": " One consultation per beneficiary per year (not within 6 months from \nthe previous year\u2019s consultation) R120 co-payment applies to the \ncharged code 8101 Patient written Accountability form needed for procedures not covered.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Bonitas",
    "plan": "Hospital Standard",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["0866 770 339"],
      "phone": [
        "'0860 336 346"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " Scheme Exclusions:  Osseo-integrated implantation, Orthognathic (jaw correction) surgery,  Sinus lifts,  Bone augmentations,  Bone and tissue regeneration procedures,  The cost of bone regeneration material, The auto-transplantation of teeth.",
    "preAuth": " Maxillo-facial surgery in hospital: - Pre-authorisation required . - Benefit subject to managed care protocols and payable to the specialist up to 100% of the BDT Oral pathology procedures (cysts and biopsies, the surgical treatment of tumours of the jaw and soft tissue tumours): - Claims will only be covered if supported by a laboratory report that confirms diagnosis. Benefit for the closure of an oral-antral opening (code 8909): - Subject to motivation and managed care protocols Other surgery in hospital (General Anaesthetic) Pre-authorisation required General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment.  Sedation  in the Dental Rooms Pre-authorisation required. ",
    "crucialRules": "No basic or Specialised dentistry. ",
    "notes": "",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Bonitas-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "BPMAS",
    "plan": "BPMAS",
    "administrator": "BPMAS",
    "contactInfo": {
      "email": [
        "enquiries@bpmas.co.za"
      ],
      "fax": [],
      "phone": [
        "021 480 4610",
        "080 000 1607"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments:  Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.  The cost of the use of gold in dentures. General anesthetic and hospitalisation for conservative dental work excluded, except in the case of trauma and impacted third molars.",
    "preAuth": "Special dentistry (Including metal base dentures) All orthodontic services are subject to prior approval. In-hospital dentistry subject to pre-authorisation",
    "crucialRules": " Benefits included: Conservative and restorative dentistry (includes plastic dentures and extractions under conscious sedation)\nSpecial dentistry (Including metal base dentures) Implants. ",
    "notes": "Conservative and restorative dentistry (includes plastic dentures and extractions under conscious sedation)",
    "source": "https://www.bpmas.co.za/Files/(126202374857%20AM)%20bpmas_memberguide_1dec23.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CAMAF",
    "plan": "Alliance && Alliance network",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545",
        "011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for dental veneers are available for Members on any Benefit Option except where members have accumulated (positive) funds in MSA",
    "preAuth": "ADVANCED DENTISTRY Crowns, Bridges, Orthodontics, Dentures: 80% CBT limited to:\nM0 R21 348       M1 R31 906       M2+ R38 478",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry 80% CBT. ONE DENTISTRY CONSULTATION General checkup only - excludes consumables",
    "notes": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry 80% CBT",
    "source": "https://camaf.co.za/wp-content/uploads/2023/11/CAMAF_2024-Electronic-Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CAMAF",
    "plan": "Double Plus",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545",
        "011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for dental veneers are available for Members on any Benefit Option except where members have accumulated (positive) funds in MSA",
    "preAuth": "ADVANCED DENTISTRY Crowns, Bridges, Orthodontics, Dentures: 80% CBT limited to:\nM0 R15 900      M1 R22 896       M2+ R30 835",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry 80% CBT. ONE DENTISTRY CONSULTATION General checkup only - excludes consumables",
    "notes": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry 80% CBT",
    "source": "https://camaf.co.za/wp-content/uploads/2023/11/CAMAF_2024-Electronic-Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CAMAF",
    "plan": "Essential Plus",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545",
        "011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for dental veneers are available for Members on any Benefit Option except where members have accumulated (positive) funds in MSA",
    "preAuth": "ADVANCED DENTISTRY Crowns, bridges, orthodontics, dentures: Subject to Medical Savings Account",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry 80% CBT. ONE DENTISTRY CONSULTATION General checkup only - excludes consumables",
    "notes": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners: Basic dentistry Subject to Medical Savings Account. ",
    "source": "https://camaf.co.za/wp-content/uploads/2023/11/CAMAF_2024-Electronic-Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CAMAF",
    "plan": "Essential Network",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545",
        "011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for dental veneers are available for Members on any Benefit Option except where members have accumulated (positive) funds in MSA",
    "preAuth": "ADVANCED DENTISTRY Crowns, bridges, orthodontics, dentures: Subject to Medical Savings Account. ADVANCED DENTISTRY Crowns, bridges, orthodontics, dentures 50% CBT. R 8 220\n",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners; basic dentistry 80% CBT. ONE DENTISTRY CONSULTATION General checkup only - excludes consumables",
    "notes": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners: Basic dentistry R 3 922",
    "source": "https://camaf.co.za/wp-content/uploads/2023/11/CAMAF_2024-Electronic-Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CAMAF",
    "plan": "First Choice",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545",
        "011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for osseo-integrated implants are available. No benefits for dental veneers are available for Members on any Benefit . Option except where members have accumulated (positive) funds in MSA. dental implants excluded. ",
    "preAuth": "Crowns, bridges, orthodontics, dentures: 50% CBT. Advanced Dentistry R 8 220. ",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures \nperformed by these practitioners: Basic dentistry 80% CBT. R 3 922. ",
    "notes": "ONE DENTISTRY CONSULTATION General checkup only - excludes consumables 100% CBT per beneficiary",
    "source": "https://camaf.co.za/wp-content/uploads/2023/11/CAMAF_2024-Electronic-Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CAMAF",
    "plan": "Network Choice",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545",
        "011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for osseo-integrated implants are available. No benefits for dental veneers are available for Members on any Benefit Option except where members have accumulated (positive) funds in MSA. ) dental implants excluded",
    "preAuth": "ADVANCED DENTISTRY Crowns, bridges, orthodontics, dentures: Subject to limit Advanced Dentistry R 8 220. ",
    "crucialRules": "Dental x-rays performed by dentists, consultations and procedures performed by these practitioners: 100% negotiated rate. R 3 922. ",
    "notes": "ONE DENTISTRY CONSULTATION General checkup only - excludes consumables 100% CBT per beneficiary",
    "source": "https://camaf.co.za/wp-content/uploads/2023/11/CAMAF_2024-Electronic-Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CAMAF",
    "plan": "Vital ",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545",
        "011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for osseo-integrated implants are available. No basic or advanced dentistry. No benefits for orthodontic treatment",
    "preAuth": "",
    "crucialRules": "ONE DENTISTRY CONSULTATION General checkup only - excludes consumables: 100% CBT per beneficiary",
    "notes": "",
    "source": "https://camaf.co.za/wp-content/uploads/2023/11/CAMAF_2024-Electronic-Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CAMAF",
    "plan": "Vital Network",
    "administrator": "Camaf",
    "contactInfo": {
      "email": [
        "custserv@camaf.co.za",
        "preauth@camaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 100 545",
        "011 707 8400"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for osseo-integrated implants are available. No basic or advanced dentistry",
    "preAuth": "No day to day funds.",
    "crucialRules": "ONE DENTISTRY CONSULTATION General checkup only - excludes consumables: 100% CBT per beneficiary",
    "notes": "",
    "source": "https://camaf.co.za/wp-content/uploads/2023/11/CAMAF_2024-Electronic-Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Compcare",
    "plan": "Selfnet",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments: Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.  The cost of the use of gold in dentures.  Discretionary procedures \u2013 elective treatments and surgery for personal reasons and not directly caused and related to illness, accident or disease",
    "preAuth": "  Prior to having specialised dentistry, the member is required to obtain pre-authorisation. Specialised dentistry Dentures, crowns, bridgework, metal fillings and inlays. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment. No benefit for orthodontic treatment for patients older than 18 years.",
    "crucialRules": "Conservative dentistry including consultations, preventative care, fillings, extractions and infection control: 100% of the scheme rate. Subject to PMSA. \nUnlimited conservative  dentistry per child younger than 6 years paid \nfrom risk.",
    "notes": "Surgical Procedures Out-of-Hospital 100% of AT. Subject and limited to available PMSA for non-PMBs.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Compcare",
    "plan": "Unisave",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777\u00a0"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments:  Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.\n The cost of the use of gold in dentures.  Discretionary procedures \u2013 elective treatments and surgery for personal reasons and not directly caused and related to illness,\naccident or disease. No benefit for orthodontic treatment for patients older than 18 years.",
    "preAuth": "Specialised dentistry:  Dentures, crowns, bridgework, metal fillings and inlays. (A quotation must be submitted for approval prior to the commencement of the treatment.",
    "crucialRules": " Multiple fillings: A treatment plan and X\u0002rays may be required for multiple fillings. Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Unlimited conservative dentistry  per child younger than 6  years paid from risk. Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital  combined limit. ",
    "notes": "Specialised dentistry Dentures, crowns, bridgework, metal fillings and inlays. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment.  Unlimited  consultations and basic dentistry for  children younger than 6 years. ",
    "source": "https://launch.universal.co.za/wp-content/uploads/2023/10/CompCare-UniSave-Brochure-2024-Digital-V2.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Compcare",
    "plan": "Pinnacle",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777\u00a0"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments:  Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.\n The cost of the use of gold in dentures.  Discretionary procedures \u2013 elective treatments and surgery for personal reasons and not directly caused and related to illness,\naccident or disease. No benefit for orthodontic treatment for patients older than 18 years.",
    "preAuth": "Specialised dentistry:  Dentures, crowns, bridgework, metal fillings and inlays. (A quotation must be submitted for approval prior to the commencement of the treatment.",
    "crucialRules": "Conservative dentistry including consultations, preventative care, fillings, extractions, and infection control.  Subject to PMSA. No benefit for orthodontic treatment for patients older than 18 years. ",
    "notes": "Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital combined limit . for the Efficiency Discounted option where your premium is reduced for only using Dis-Chem pharmacies and Netcare hospitals. ",
    "source": "https://launch.universal.co.za/wp-content/uploads/2023/10/CompCare-Pinnacle-Brochure-2024-Digital-V2.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Compcare",
    "plan": "Dymanic",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777\u00a0"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments:  Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.\n The cost of the use of gold in dentures.  Discretionary procedures \u2013 elective treatments and surgery for personal reasons and not directly caused and related to illness,\naccident or disease. . No benefit for orthodontic treatment for patients older than 18 years.",
    "preAuth": "Specialised dentistry Dentures, crowns, bridgework, metal fillings and inlays. Subject to a sub-limit of R14 850 PB and R20 000 PMF. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment. ",
    "crucialRules": "  Subject to PMSA. No benefit for orthodontic treatment for patients older than 18 years. Basic dentistry Subject to PMSA, AFB and SPG. Unlimited after threshold. Unlimited basic dentistry per child under the age of 6 years funded from risk.",
    "notes": "Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital combined limit . Unlimited  consultations and basic dentistry for  children younger than 6 years",
    "source": "https://launch.universal.co.za/wp-content/uploads/2023/10/CompCare-Dynamix-Brochure-2024-Digital-V2.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Compcare",
    "plan": "Mumed",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777\u00a0"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments:  Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.\n The cost of the use of gold in dentures.  Discretionary procedures \u2013 elective treatments and surgery for personal reasons and not directly caused and related to illness,\naccident or disease. No benefit for orthodontic treatment for patients older than 18 years.",
    "preAuth": "Specialised dentistry Dentures, crowns, bridgework, metal fillings and inlays. Sub-limit of      R2 400 PB. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment",
    "crucialRules": "Conservative dentistry including consultations, preventative care, fillings, extractions, and infection control.  Subject to PMSA. No benefit for orthodontic treatment for patients older than 18 years. Paid from AFB. Basic dentistry Conservative and restorative. Unlimited basic dentistry for children younger than 6 years funded from risk.",
    "notes": "Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital combined limit . Efficiency Discounted option where your premium is reduced for only using Dis-Chem pharmacies and Netcare hospitals.  Unlimited  consultations and basic dentistry for\nchildren younger than 6 years",
    "source": "https://launch.universal.co.za/wp-content/uploads/2023/10/CompCare-Mumed-Brochure-2024-Digital-V2.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Compcare",
    "plan": "Symmertry",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777\u00a0"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments:  Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.\n The cost of the use of gold in dentures.  Discretionary procedures \u2013 elective treatments and surgery for personal reasons and not directly caused and related to illness,\naccident or disease. No benefit for orthodontic treatment for patients older than 18 years.",
    "preAuth": "Specialised dentistry Dentures, crowns, bridgework, metal fillings and inlays. Sub-limit of R9 500 PB. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment.",
    "crucialRules": "Conservative dentistry including consultations, preventative care, fillings, extractions, and infection control.  Subject to PMSA. No benefit for orthodontic treatment for patients older than 18 years. Paid from AFB. Basic dentistry Conservative and restorative. Unlimited basic dentistry for children younger than 6 years funded from risk.",
    "notes": "Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital combined limit . Efficiency Discounted option where your premium is reduced for only using Dis-Chem pharmacies and Netcare hospitals.  Unlimited  consultations and basic dentistry for\nchildren younger than 6 years",
    "source": "https://launch.universal.co.za/wp-content/uploads/2023/10/CompCare-Symmetry-Brochure-2024-Digital-V2.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Compcare",
    "plan": "Selfsure",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "dental@universal.co.za",
        "compcare@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 222 777\u00a0"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments:  Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.\n The cost of the use of gold in dentures.  Discretionary procedures \u2013 elective treatments and surgery for personal reasons and not directly caused and related to illness,\naccident or disease. No benefit for orthodontic treatment for patients older than 18 years.",
    "preAuth": "Specialised dentistry:  Subject to the Day-to-Day Benefit Dentures, crowns, bridgework, metal fillings and inlays. Subject to protocols. A quotation must be submitted for approval prior to the commencement of treatment",
    "crucialRules": "Paid from day-to-day extender benefit. Basic dentistry Conservative and restorative",
    "notes": "Specialised dentistry, including maxillofacial and oral surgery- in-and-out of hospital combined limit . Efficiency Discounted option where your premium is reduced for only using Dis-Chem pharmacies and Netcare hospitals.  Unlimited  consultations and basic dentistry for\nchildren younger than 6 years",
    "source": "https://launch.universal.co.za/wp-content/uploads/2023/10/CompCare-SelfSure-Brochure-2024-Digital-V2.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CompCare Medical Scheme",
    "plan": "Umbono (student plan)",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "One set plastic denture per beneficiary every 36 months. Subject to AFB to a limit of R3 780 per beneficiary or R5 570 per family. A 20% co-payment applies. Pre-authorisation required for 4 or  more extractions per annum per beneficiary. Teeth  extractions to be done in dentist\u2019s rooms under local anaesthesia. Pre\u0002authorisation required if >2 per beneficiary  per annum.",
    "crucialRules": "Pre\u0002authorisation required if >4 per annum per beneficiary. Only anterior composite fillings are covered. Root Canal  Treatment Emergency only. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": "https://studentplan.co.za/wp-content/uploads/2023/12/CC-Student-1-Pager-2024-V5.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "CompCare Medical Scheme",
    "plan": "Umbono (student plan) ED",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "One set plastic denture per beneficiary every 36 months. Subject to AFB to a limit of R3 780 per beneficiary or R5 570 per family. A 20% co-payment applies. Pre-authorisation required for 4 or  more extractions per annum per beneficiary. Teeth  extractions to be done in dentist\u2019s rooms under local anaesthesia. Pre\u0002authorisation required if >2 per beneficiary  per annum.",
    "crucialRules": "Pre\u0002authorisation required if >4 per annum per beneficiary. Only anterior composite fillings are covered. Root Canal  Treatment Emergency only. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of paymen",
    "source": "https://studentplan.co.za/wp-content/uploads/2023/12/CC-Student-1-Pager-2024-V5.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "De Beers",
    "plan": "De Beers",
    "administrator": "De Beers",
    "contactInfo": {
      "email": [
        "debeerspreauth@ppsha.co.za"
      ],
      "fax": [],
      "phone": [
        "0538073111"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Dental procedures and treatments: Dental extractions for non-medical purposes.  Bleaching of teeth that have not been root canal treated.  High impact acrylic dentures.  The cost of the use of gold in dentures",
    "preAuth": "Specialised dentistry:  .  Limited to R9 270.  Includes crowns, dentures, bridges and periodontal treatment. Orthodontic treatment: Preauth.  Paid at 75% of SRPL rate.  Limited to R27 910 per lifetime.  Benefits are not provided for treatment starting after a beneficiary\u2019s 18th birthday  . Managed-care protocols apply, and pre-authorisation is required in respect of: Elective procedures where general anaesthesia is required;  Children under the age of nine (limited to one admission per year); and Removal of impacted wisdom teeth, apicectomies, removal of teeth and roots or exposure of teeth for orthodontic reasons",
    "crucialRules": " 1 check-up and scale and polish every 6 months, from date of last service.  Includes preventative and diagnostic consultations, fillings, extractions, cleanings, and X-rays. ",
    "notes": "Conservative dentistry:includes preventative and diagnostic consultations, fillings, extractions, cleanings and x-rays.  pre-authorisation is required in respect of Elective procedures where general anaesthesia is required.  Children under the age of nine (limited to one admission per year) .Removal of impacted wisdom teeth, apicectomies, removal of teeth and roots or exposure of teeth for orthodontic reasons.\nNo limit applies in respect of dentistry required as a result of trauma.",
    "source": "https://dbbs.co.za/download/422/member-guide/3761314/dbbs-member-guide-2024.pdf?preview=1",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Executive series",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860 445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": "Dental treatment in hospital Except where approved for severe dental and oral surgery, you need to pay a portion of your hospital or day clinic account upfront for dental admissions. This amount varies, depending on your age and the place of treatment. We pay the balance of the hospital account from your Hospital Benefit, up to 100% of the Discovery Health Rate (DHR). We pay the related accounts, which include the dental surgeon\u2019s account, from your Hospital Benefit, up to 100% of the Discovery Health Rate (DHR). We pay anaesthetists up to 200% of the Discovery Health Rate (DHR). For members 13 years and older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment, from your available day-today benefits.",
    "crucialRules": "Basic dental treatment done in the dentist\u2019s rooms is paid from your day-to-day benefits",
    "notes": "For members 13 and older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment from your available day-to-day benefits. We pay your day-to-day medical expenses. ",
    "source": "https://www.discovery.co.za/wcm/discoverycoza/assets/medical-aid/benefit-information/2024/cover-for-dental-treatment.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": " Comprehensive series: Classic | Classic smart",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860 445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": "The Severe Dental and Oral Surgery Benefit covers a defined list of procedures, with no upfront payment and no overall limit. This benefit is subject to authorisation and the Scheme\u2019s rules. For members 13 and older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment from your available day-to-day benefits.",
    "crucialRules": "There is no overall limit for basic dental treatment. However, all dental appliances and prostheses, their placement, and orthodontic treatment (including related accounts for orthognathic surgery) are paid at 100% of the Discovery Health Rate (DHR) and up to 200% of the DHR for anaethetists. We pay these claims from your day-to-day benefits, up to an annual limit of R34,500 per person or up to the ATB limit, whichever you reach first. ",
    "notes": "The Day-to-day Extender Benefit (DEB) extends your day-to-day cover while you are in your self-payment gap for essential healthcare services in our network. Dental appliances and orthodontic treatment* R34,500 per person. For members 13 years and older, we cover routine \nconservative dentistry, such as preventive treatment, simple fillings and root canal treatment, from your available day-to\u0002day benefits. Dental appliances and orthodontic treatment* R34,500 per person",
    "source": "https://www.discovery.co.za/wcm/discoverycoza/assets/medical-aid/benefit-information/2024/cover-for-dental-treatment.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Priority series: Classic | Essential",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860 445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": " The Severe Dental and Oral Surgery Benefit covers a defined list of procedures that are paid from the Hospital Benefit according to your chosen health plan. These procedures include:\n Internal temporomandibular joint (TMJ) surgery  Cleft lip and palate repairs  Surgery for severe life-threatening infections  Cancer-related surgery There is no overall limit for the procedures approved and covered by the Severe Dental and Oral Surgery Benefit. However,\naccounts for dental appliances and their placement are paid from the available day-to-day benefits, regardless of the place of treatment, and subject to the annual limit where applicable. ",
    "crucialRules": "If you are 13 and older, we cover routine dental treatment such as preventive treatments, simple fillings and root canal treatments performed in-hospital from your available day-to-day benefits.",
    "notes": "The Day-to-day Extender Benefit (DEB) extends your day-to-day cover while you are in your self-payment gap for essential healthcare services in our network. Dental appliances and orthodontic treatment* R34,500 per person. For members 13 years and older, we cover routine \nconservative dentistry, such as preventive treatment, simple \nfillings and root canal treatment, from your available day-to\u0002day benefits.",
    "source": "https://www.discovery.co.za/wcm/discoverycoza/assets/medical-aid/benefit-information/2024/cover-for-dental-treatment.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Saver series: Classic | Classic Delta | Essential | Essential Delta | Coastal",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "",
    "preAuth": "Cover for basic dental treatment  : What we pay for We pay for basic dental treatment done in the dentist's or dental specialist's rooms from your available day-to-day benefits (Medical Savings Account) at 100% of the Discovery Health Rate.\nThere is no overall limit for basic dental treatment. ",
    "crucialRules": "We pay for basic dental treatment done in the dentist or dental specialist\u2019s rooms at 100% of the Discovery Health Rate (DHR) from the available funds allocated to your Medical Savings Account (MSA) and from the Above Threshold Benefit (ATB) once your claims add up to the Annual Threshold. If you have run out of funds in your Medical Savings Account (MSA) and have not yet reached your Annual Threshold, you will need to pay these accounts.",
    "notes": "For members 13 and older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment from your available funds in your Medical Savings Account,",
    "source": "https://www.discovery.co.za/wcm/discoverycoza/assets/medical-aid/benefit-information/2024/cover-for-dental-treatment.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Smart series: Classic | Essential | Essential Dynamic",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "0860445566"
      ],
      "fax": [],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "In-hospital dental treatment is not covered on the Essential Smart and Essential Dynamic Smart plans. Orthognathic Surgery; This is not covered on the Essential Smart and Essential Dynamic Smart plans. ",
    "preAuth": "confirm benefits.",
    "crucialRules": "A co-payment of R115 on the Classic Smart Plan and R170 on the Essential Smart Plan applies with the balance of this fee covered up to the Discovery Health Rate (DHR)",
    "notes": "For members 13 and older, we cover routine conservative dentistry, such as preventive treatment, simple fillings and root canal treatment from your available funds in your Medical Savings Account,",
    "source": "https://www.discovery.co.za/wcm/discoverycoza/assets/medical-aid/benefit-information/2024/cover-for-dental-treatment.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Keycare Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Vapour sterilised and wrapped instrumentation  will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed.  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin.  Restorations on non-functional  wisdom teeth.",
    "preAuth": "Periapical x-ray  Pre-authorisation and motivation required prior to claims  submission when quantity is more than the following   : With 8101, 8112x2 and 8107x1 ,  With 8104, 8112x1 or 8107x1,  No consult 8112x1 or 8107x1,  Maximum quantity of 7 per year. Pulp removal  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Extraction Codes: Maximum 1 x 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Pre-authorisation and x-rays required for:  More than 4 restorations per year.  Two or more 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.  Multiple restorations on an anterior tooth on the same service date.  Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  Any 3- or 4 surface restorations on wisdom teeth with supporting x-rays.",
    "crucialRules": "1 Restoration code per tooth number in a 9 month period. Motivation required for code 8104 if no covered clinical procedure is charged.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option. Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Keycare Start",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Vapour sterilised and wrapped instrumentation  will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed..  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin.  Restorations on non-functional  wisdom teeth. No Panorex.",
    "preAuth": " Pre-authorisation and motivation required prior to claims  submission when quantity is more than the following   : With 8101, 8112x2 and 8107x1 ,  With 8104, 8112x1 or 8107x1,  No consult 8112x1 or 8107x1,  Maximum quantity of 7 per year. Pulp removal  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Extraction Codes: Maximum 1 x 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Pre-authorisation and x-rays required for:  More than 4 restorations per year.  Two or more 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.  Multiple restorations on an anterior tooth on the same service date.  Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  Any 3- or 4 surface restorations on wisdom teeth with supporting x-rays.",
    "crucialRules": "2 Restoration code per tooth number in a 9 month period. Motivation required for code 8104 if no covered clinical procedure is charged.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option. Patient written consent needed for procedures not covered",
    "source": "2025 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Flexicare",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " sterilised  instrumentation  will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed..  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin. Restorations on non-functional  wisdom teeth. No Panorex.  Restorations on non-functional wisdom teeth.  3 or 4 surface restorations on wisdom teeth not covered.",
    "preAuth": " Pulp removal  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Restoration Codes: Max of 3 restorations per 365 \ndays.  2 Posterior restorations (resin or amalgam) per member per 365 days.  Posterior resin restorations covered at amalgam rates.  Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  1 anterior restoration per member per 365 days.",
    "crucialRules": "Consultation for a specific problem, not requiring charting and treatment planning.   1 per member per 365 days. Not within 4 weeks of 8101, 8102, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged).Motivation required for code 8104 if no covered clinical procedure is charged. sterilised  instrumentation  Will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. Extraction Codes: Maximum 1 x 8201 and 8202  per quadrant per member per 365 days. 1 Restoration code per tooth \nnumber in a 9 month period. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option. Patient written consent needed for procedures not covered",
    "source": "2026 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Flexicare Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " sterilised  instrumentation  will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed..  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin. Restorations on non-functional  wisdom teeth. No Panorex.  Restorations on non-functional wisdom teeth.  3 or 4 surface restorations on wisdom teeth not covered.",
    "preAuth": " Pulp removal  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Restoration Codes: Max of 3 restorations per 365 \ndays.  2 Posterior restorations (resin or amalgam) per member per 365 days.  Posterior resin restorations covered at amalgam rates.  Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  1 anterior restoration per member per 365 days.",
    "crucialRules": "Consultation for a specific problem, not requiring charting and treatment planning.   1 per member per 365 days. Not within 4 weeks of 8101, 8102, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged).Motivation required for code 8104 if no covered clinical procedure is charged. sterilised  instrumentation  Will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. Extraction Codes: Maximum 1 x 8201 and 8202  per quadrant per member per 365 days. 1 Restoration code per tooth \nnumber in a 9 month period. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option. Patient written consent needed for procedures not covered",
    "source": "2027 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Discovery",
    "plan": "Flexicare HouseHold",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " sterilised  instrumentation  will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed..  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin. Restorations on non-functional  wisdom teeth. No Panorex.  Restorations on non-functional wisdom teeth.  3 or 4 surface restorations on wisdom teeth not covered.",
    "preAuth": " Pulp removal  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Restoration Codes: Max of 3 restorations per 365 \ndays.  2 Posterior restorations (resin or amalgam) per member per 365 days.  Posterior resin restorations covered at amalgam rates.  Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  1 anterior restoration per member per 365 days.",
    "crucialRules": "Consultation for a specific problem, not requiring charting and treatment planning.   1 per member per 365 days. Not within 4 weeks of 8101, 8102, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged).Motivation required for code 8104 if no covered clinical procedure is charged. sterilised  instrumentation  Will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. Extraction Codes: Maximum 1 x 8201 and 8202  per quadrant per member per 365 days. 1 Restoration code per tooth \nnumber in a 9 month period. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option. Patient written consent needed for procedures not covered",
    "source": "2028 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Enablemed",
    "plan": "Makoti Primary",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Plastic Dentures: No Benefits. Partial Chrome Cobalt Frame Dentures No Benefits. Crown & Bridge No Benefits. Implants No Benefits. Hospitalisation",
    "preAuth": "Benefit for more than three fillings per beneficiary: Subject to pre-notification. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair: Benefit subject to managed care\nprotocols Covered at the Scheme Tariff Benefit only available for the following procedure codes: 8931, 8935, 9011 and 9013. Hospitalisation (General Anaesthetic) Pre-authorisation required. Deep Sedation in Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. One check-up per beneficiary per year. Benefit for scale and polish: One scale and polish treatment per beneficiary per year. Benefit for fillings: Granted once per\ntooth in 720 days.  Benefit for extractions only available for procedure code 8201. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option. Patient written consent needed for procedures not covered",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Enablemed-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Enablemed",
    "plan": "Makoti Comprehensive",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required. Plastic Dentures Pre-authorisation required. Benefit for more than three fillings per beneficiary: Subject to pre-notification. Hospitalisation Pre-authorisation required. Partial Chrome Cobalt Pre-authorisation required One partial frame (an upper or a lower) per beneficiary in a 5-year period. Crown & Bridge Pre-authorisation required Benefit for crowns will be granted once per tooth in a 5-year period, Orthodontics Pre-authorisation required Only one family member may commence orthodontic treatment in a calendar year. Maxillo-facial Surgery\nand Oral Pathology Surgery in the dental chair: Benefit subject to managed care protocols. Hospitalisation (General Anaesthetic) Pre-authorisation required. ",
    "crucialRules": "X-rays: Extraoral: One per beneficiary in a 3-year period. Benefit for fillings: Granted once per tooth in 720 days. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option. Patient written consent needed for procedures not covered",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Enablemed-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Engen",
    "plan": "Engen",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "service@engenmed.co.za"
      ],
      "fax": ["0800 001 615"],
      "phone": [
        "'0800 001 615"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth), Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Emergency crowns that are not placed for the immediate protection in tooth injury.",
    "preAuth": "We will fund claims for specialised dentistry from the Specialised Dentistry Limit. This includes cover for inlays, Osseo-integrated Implantology, crowns, bridges, study models, metal based dentures and the repair of metal based dentures, oral medication and periodontics, orthodontics and prosthodontics. Please call us for confirmation of your benefits for orthodontic treatment. In-hospital specialised dentistry will require an authorisation. The hospital account will be funded from Insured Benefits and related accounts will all be funded from the Specialised Dental Limit. If you have a procedure with both specialised and basic dentistry, the claim will be paid as per the Specialised Dentistry Limit. How we cover Maxillo-facial surgery Pre-authorisation is required for maxillo-facial surgery. ",
    "crucialRules": "In-hospital dentistry \u2013 theatre and Anaesthetist accounts for children\nunder the age of 8 years. Specialised dentistry. Includes: inlays,\ncrowns, bridges, study models, metal-base dentures and the repair thereof, oral medicine, periodontics, orthodontics, prosthodontics and osseo-integrated implantology. 100% Fund Rate from Insured Benefits. ",
    "notes": "Basic Dentistry: 100% of Fund Rate from MSA. Once MSA is depleted, up to\nthe Primary Care (day-to-day) limit . ",
    "source": "https://www.engenmed.co.za/wcm/medical-schemes/engen/assets/find-a-document/benefit-guides/2024/embf-member-guide.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essential Employee Benefits (EEB)",
    "plan": "PEP EXECUTIVE PLAN ",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861033647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Multiple fillings need a  treatment plan and motivation, including X-rays.",
    "crucialRules": " Four X-rays per dependant per year. Benefit for four fillings per dependant per year \u2212 Benefit for fillings are granted once per tooth in 365 days \u2212 Benefit for re-treatment of a tooth subject to managed care protocols .",
    "notes": ". Benefits payable on the EEB Essential Plan is subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/EEB-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essential Employee Benefits (EEB)",
    "plan": "EEB ESSENTIAL",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861033647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Multiple fillings need a  treatment plan and motivation, including X-rays.",
    "crucialRules": " Benefit for four fillings per dependant per year \u2212 Benefit for fillings are granted once per tooth in 365 days \u2212 Benefit for re-treatment of a tooth subject to managed care protocols. Benefit for emergency treatment only",
    "notes": ". Benefits payable on the EEB Essential Plan is subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/EEB-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essential Employee Benefits (EEB)",
    "plan": "COMBINED PLAN",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861033647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Multiple fillings need a  treatment plan and motivation, including X-rays.",
    "crucialRules": "Four X-rays per dependant per year. Benefit for four fillings per dependant per year \u2212 Benefit for fillings are granted once\nper tooth in 365 days \u2212 Benefit for re-treatment of a tooth\nsubject to managed care protocols. Benefit for emergency treatment only.",
    "notes": ". Benefits payable on the EEB Essential Plan is subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/EEB-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essential Employee Benefits (EEB)",
    "plan": "ACKERMANS ESSENTIAL",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861033647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Multiple fillings need a  treatment plan and motivation, including X-rays.",
    "crucialRules": "Two consultations in total per dependant per year (once every 6 months). One specific consultation in total for pain and sepsis per dependant per year. Four X-rays per dependant per year. Benefit for four fillings per dependant per year Benefit for fillings are granted once per tooth in 365 days. ",
    "notes": "Benefits payable on the EEB Combined Plan is subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/EEB-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "Basic",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin.  No Benefit for Panorex, specialised dentistry, or in hospital procedures.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "Balance",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin.  No Benefit for Panorex, specialised dentistry, or in hospital procedures.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2025 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "Balance Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin.  No Benefit for Panorex, specialised dentistry, or in hospital procedures.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2026 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "Boost Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin.  No Benefit for Panorex, specialised dentistry, or in hospital procedures.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2027 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "Comprehensive",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. No Theatre, Implants, ortho. Dentures, Crowns.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2028 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "Day to day",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. No Theatre, Implants, ortho. Dentures, Crowns.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2029 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "55Plus4U",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. No Theatre, Implants, ortho. Dentures, Crowns.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2030 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "Essential lite",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. No Theatre, Implants, ortho. Dentures, Crowns.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2031 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "DD 17, 21,25,29",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. No Theatre, Implants, ortho. Dentures, Crowns.",
    "preAuth": " Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray  2 per member per consultation not more \n than 4 per annum. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2032 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "DD 33,37,41,45",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. No Theatre, Implants, ortho.",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Pre-authorisation required for more than 4 restorations per annum.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. 1 Crown per member up to R4 950 per event within a 24-month period.",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-ray 2 per member per consultation not more  than 4 per annum. 8132 cannot be claimed with 8131 or any restoration, root canal and extraction  codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will only be authorised once an emailed or scanned x-ray of the completed root canal is received.  1 Restoration code per tooth number in a 9-month period. 1 Root canal treatment up to R2 200 per member per annum. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2033 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Essentialmed",
    "plan": "DD49, DD53, DD57 & DD61",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex. No Theatre, Implants, ortho.",
    "preAuth": " Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.  More than 4 per visit require pre\u0002authorisation. Restoration: Pre-authorisation required for more than 4 restorations per year.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. 2 Root canal treatments up to R2 200 per event per member per annum. 2 Crowns per member up to R4 950 per event within a 24-month period. 1 Denture to the value of R2 000 per policy per annum.",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service. X-rays  2 per member per consultation not more 8112 Bitewing x-ray  than 4 per annum. 8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes.  Maximum of 1 per treatment date. Not covered on primary teeth.  The subsequent restoration an emailed or scanned x-ray of the completed root canal is received. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations, 1 Restoration code per tooth number in a 9-month period. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Myfed",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za"
      ],
      "fax": ["086 1100 220"],
      "phone": [
        "0861100220"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No Advanced dentistry, implants, ortho. No benefits for rootcanal fillings.",
    "preAuth": "Any 4 amalgam restorations per member per year .  Any four resin restorations per member per year (anterior).  All restorations are restricted to a total of 3 per year per patient without pre-authorisation and a total of 3 can be made up of any combination of the restorative codes. One set of dentures allowed  Pre \u2013 authorisation required. Extraction Codes: Any 4 non-surgical extractions per member per year \u2013 ONLY if clinically indicated.  Code 8202 is charged for each additional extraction in the same quadrant.",
    "crucialRules": "Emergency root canal treatment 1 Per member per year. Not covered on primary teeth. The following is limited to 4 per member per annum:  Resin restorations (anterior). Surgical removal of tooth  Maximum 4, restricted to posterior  permanent teeth per year. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual F = network agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Flexifed 1",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za"
      ],
      "fax": ["086 1100 219"],
      "phone": [
        "'0861100219"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "In-hospital dentistry benefit for children under 7 No benefit",
    "preAuth": "Maxillo-facial surgery Unlimited, subject to approval (see HPT) . Surgical extraction of impacted wisdom teeth You pay a co-payment of R5 100 on the hospital bill. Dentistry (Advanced): inlays, crowns, bridges, mounted study models, metal base partial dentures, oral surgery, orthodontic treatment, periodontists, prosthodontists and dental technicians\nPaid from Fedhealth Savings or self-funded. Accumulates at cost to Threshold level. ",
    "crucialRules": " Consultation   2 per beneficiary per year.    Xrays   maximum 2 per beneficiary per year.  infection control    4 per year, 2 per visit.    Sterilised instrumentation   2  per year: 1 per visit .  Scaling and polishing   2 per beneficiary  per year. Fluoride        ages 3-12,  2 per beneficiary per year. Fissure sealant, per tooth Patients younger than 14; maximum of 8 per year;  2 per quadrant",
    "notes": "Basic preventative dentistry which includes X-rays and scaling and polishing is covered from the Savings Account. When your day-to-day claims have accumulated to your threshold level, preventative dentistry is paid out of the threshold benefit. The preventative dentistry benefit covers two annual consultations per beneficiary including scaling and polishing. These consultations are subject to a list of approved procedures, dental tariff codes and protocols at the Fedhealth Rate.",
    "source": "https://www.fedhealth.co.za/media/4616/savings-plans-interactive-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Flexifed 2",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za"
      ],
      "fax": ["086 1100 220"],
      "phone": [
        "0861100219"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Metal base for complete dentures. Implants no benefits. No Advanced dentistry, implants, ortho. No benefits for rootcanal fillings.",
    "preAuth": "Any 4 amalgam restorations per member per year .  Any four resin restorations per member per year (anterior).  All restorations are restricted to a total of 3 per year per patient without pre-authorisation and a total of 3 can be made up of any combination of the restorative codes. One set of dentures allowed  Pre \u2013 authorisation required. Extraction Codes: Any 4 non-surgical extractions per member per year \u2013 ONLY if clinically indicated.  Code 8202 is charged for each additional extraction in the same quadrant.",
    "crucialRules": "Emergency root canal treatment 1 Per member per year. Not covered on primary teeth. The following is limited to 4 per member per annum:  Resin restorations (anterior). Surgical removal of tooth  Maximum 4, restricted to posterior  permanent teeth per year.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual F = network agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Flexifed3",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za"
      ],
      "fax": ["086 1100 220"],
      "phone": [
        "0861100220"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Metal base for complete dentures. Implants no benefits. No Advanced dentistry, implants, ortho. No benefits for rootcanal fillings.",
    "preAuth": "Any 4 amalgam restorations per member per year .  Any four resin restorations per member per year (anterior).  All restorations are restricted to a total of 3 per year per patient without pre-authorisation and a total of 3 can be made up of any combination of the restorative codes. One set of dentures allowed  Pre \u2013 authorisation required. Extraction Codes: Any 4 non-surgical extractions per member per year \u2013 ONLY if clinically indicated.  Code 8202 is charged for each additional extraction in the same quadrant.",
    "crucialRules": "Emergency root canal treatment 1 Per member per year. Not covered on primary teeth. The following is limited to 4 per member per annum:  Resin restorations (anterior). Surgical removal of tooth  Maximum 4, restricted to posterior  permanent teeth per year.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual F = network agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Flexifed 4",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za"
      ],
      "fax": ["086 1100 220"],
      "phone": [
        "0861100220"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Metal base for complete dentures. ",
    "preAuth": "Maxillo-facial surgery Unlimited, subject to approval (see HPT) . Surgical extraction of impacted wisdom teeth You pay a co-payment of R5 100 on the hospital bill. Dentistry (Advanced): inlays, crowns, bridges, mounted study models, metal base partial dentures, oral surgery, orthodontic treatment, periodontists, prosthodontists and dental technicians\nPaid from Fedhealth Savings or self-funded. Accumulates at cost to Threshold levelIn-hospital dentistry benefit for children under 7 . ",
    "crucialRules": "Dentistry (Basic) Paid from Fedhealth Savings or self-funded. Once your Threshold level has been reached, the following benefits will be paid from the Threshold benefit. 2 annual consultations per beneficiary incl. x-rays and scaling and polishing. Paid from Fedhealth Savings or self\u0002funded and Threshold. Unlimited once Threshold is reached. Dentistry (Advanced):   Paid from Fedhealth Savings or selffunded and Threshold.R8 270 per beneficiary per year. R24 700 per family per year before and  after Threshold.",
    "notes": "Emergency root canal treatment 1 Per member per year. Not covered on primary teeth. sterilised and wrapped instrumentation limited to 2 per annum. Any 4 non-surgical extractions per member per year \u2013 ONLY if clinically indicated. Pulpotomy limited to 4 on primary teeth. Root canal limited to 1per beneficiary per year.",
    "source": "https://www.fedhealth.co.za/media/4616/savings-plans-interactive-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Maxima Exe",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za"
      ],
      "fax": ["086 1100 220"],
      "phone": [
        "0861100220"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "yes",
    "implants": "yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin.  No Benefit for Panorex, specialised dentistry, or in hospital procedures.",
    "preAuth": "In-hospital dentistry for children under 7 We pay for dentistry performed in-hospital for children up to the age of 7. The hospital account and anaesthetist costs are paid from the in-hospital benefit while the dentist\u2019s account comes from day-to-day benefits.Maxillo-facial surgery Unlimited, subject to approval. Surgical extraction of impacted wisdom teeth You pay a co-payment of R5 100 on the hospital bill. Dentistry advanced: inlays, crowns, bridges, mounted study models, metal base partial dentures, oral surgery, orthodontic treatment, periodontists, prosthodontists and dental technicians R8 270 per beneficiary per year, R24 700 per family per year before and after Threshold. Paid from Savings and Threshold  Osseo-integrated implants, orthognathic surgery Paid from Savings. Does not accumulate to or pay from Threshold. ",
    "crucialRules": "Dentistry (basic) Paid from Savings and threshold. Unlimited once threshold is reached",
    "notes": "Dentistry advanced: includes: inlays, crowns, bridges, mounted study models, metal base partial dentures, oral surgery, orthodontic treatment, periodontists, prosthodontists",
    "source": "https://www.fedhealth.co.za/media/4614/maxifed-interactive-brochure-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fedhealth",
    "plan": "Maxima Plus",
    "administrator": "Medscheme",
    "contactInfo": {
      "email": [
        "specialist@medscheme.co.za"
      ],
      "fax": ["086 1100 220"],
      "phone": [
        "'0861100220"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "yes",
    "implants": "yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious amalgam with resin.  No Benefit for Panorex, specialised dentistry, or in hospital procedures.",
    "preAuth": "In-hospital dentistry for children under 7 We pay for dentistry performed in-hospital for children up to the age of 7. The hospital account and anaesthetist costs are paid from the in-hospital benefit while the dentist\u2019s account comes from day-to-day benefits.Maxillo-facial surgery Unlimited, subject to approval. Surgical extraction of impacted wisdom teeth You pay a co-payment of R5 100 on the hospital bill. Dentistry advanced: inlays, crowns, bridges, mounted study models, metal base partial dentures, oral surgery, orthodontic treatment.Paid from Savings, OHEB and Threshold. R8 270 per beneficiary per year, R24 700 per family per year before and after Threshold. ",
    "crucialRules": "Dentistry (basic) Paid from Savings, OHEB and threshold. Unlimited once threshold is reached.",
    "notes": "Dentistry advanced: includes: inlays, crowns, bridges, mounted study models, metal base partial dentures, oral surgery, orthodontic treatment, periodontists, prosthodontists",
    "source": "https://www.fedhealth.co.za/media/4614/maxifed-interactive-brochure-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fishmed",
    "plan": "PRIMARY OPTION (CARECROSS)",
    "administrator": "Fishmed",
    "contactInfo": {
      "email": [
        "enquiries@fishmed.co.za"
      ],
      "fax": ["0860 104 123"],
      "phone": [
        "0860104121"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "no",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Bleaching of teeth that have not been root canal treated and metal inlays in dentures and front teeth. No benefit for advanced/ specialised dentistry",
    "preAuth": "Conservative dentistry Dentures limited to a maximum of R5 500 per family per annum (dental fee  is inclusive of the laboratory  fee) .",
    "crucialRules": "Conservative Dentistry:If a non-network dentist is used only tariff codes applicable to MHS network apply \u2013 members are responsible for paying any\ndifference in cost directly to the dentist",
    "notes": "If a non-network dentist is used, only tariff codes applicable to MHS network apply \u2013 members are responsible for paying any difference in cost directly to the dentist",
    "source": "https://www.fishmed.co.za/Files/(1114202312936%20PM)%20fishmed_benefits2024_14nov23.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Fishmed",
    "plan": "STANDARD OPTION (CARECROSS)",
    "administrator": null,
    "contactInfo": {
      "email": [
        "enquiries@fishmed.co.za"
      ],
      "fax": ["0860 104 123"],
      "phone": [
        "0860104121"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "no",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Bleaching of teeth that have not been root canal treated and metal inlays in dentures and front teeth. No benefit for advanced/ specialised dentistry",
    "preAuth": "Conservative dentistry Dentures limited to a maximum of R5 500 per family per annum (dental fee  is inclusive of the laboratory  fee) .",
    "crucialRules": "Conservative Dentistry:If a non-network dentist is used only tariff codes applicable to MHS network apply \u2013 members are responsible for paying any\ndifference in cost directly to the dentist",
    "notes": "If a non-network dentist is used, only tariff codes applicable to MHS network apply \u2013 members are responsible for paying any difference in cost directly to the dentist",
    "source": "https://www.fishmed.co.za/Files/(1114202312936%20PM)%20fishmed_benefits2024_14nov23.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Gems",
    "plan": "Beryl",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "'enquiries@gems.gov.za"
      ],
      "fax": ["0860 104 123"],
      "phone": [
        "'0860104121"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " No benefit available for metal frame dentures. No benefit for retreatment of a previously root canal treated tooth.",
    "preAuth": "Any four amalgam fillings per beneficiary per year; limited to four restorations overall. Any four resin fillings per beneficiary per year (anterior); limited to four restorations overall. Any four resin fillings per beneficiary per year (posterior); limited to four restorations overall the same rand value as same surfaces amalgam filling. ; preauthorisation needed for more than four fillings . Any four non-surgical extractions per beneficiary per year; only if clinically indicated. Surgical removal of residual tooth roots \u2013 first tooth per quadrant Maximum of one procedure \u2013 more than one requires clinical motivation. Surgical removal of impacted tooth \u2013\nfirst tooth Pre-authorisation required for in-hospital. One set of plastic dentures allowed per beneficiary per 48 months  Pre-authorisation necessary. General anaesthesia and conscious\nsedation Subject to preauthorisation Only available for beneficiaries under the age of six, severe trauma, and impacted third molars. ",
    "crucialRules": "Surgical removal of erupted tooth \u2013 report per tooth Maximum of two removals. Rebase only allowed once every two years. Reline only allowed once every\ntwo years. Pulp removal (pulpectomy) Once per beneficiary per 12 months; one event per beneficiary per benefit year allowed for emergency dentistry. Root canal  Limited to once per tooth per lifetime and one complete root canal therapy event (one tooth only) per beneficiary per 12 months.",
    "notes": "Denture not funded: (immediate upper  & lower denture. . Patient written consent needed for procedures not covered",
    "source": "https://www.gems.gov.za/en/Healthcare-Providers/Provider-Guides",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Gems",
    "plan": "Tanzanite  One",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "'enquiries@gems.gov.za"
      ],
      "fax": ["0860 104 123"],
      "phone": [
        "'0860104121"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " No benefit available for metal frame dentures. No benefit for retreatment of a previously root canal treated tooth.",
    "preAuth": "Any four amalgam fillings per beneficiary per year; limited to four restorations overall. Any four resin fillings per beneficiary per year (anterior); limited to four restorations overall. Any four resin fillings per beneficiary per year (posterior); limited to four restorations overall the same rand value as same surfaces amalgam filling. ; preauthorisation needed for more than four fillings . Any four non-surgical extractions per beneficiary per year; only if clinically indicated. Surgical removal of residual tooth roots \u2013 first tooth per quadrant Maximum of one procedure \u2013 more than one requires clinical motivation. Surgical removal of impacted tooth \u2013\nfirst tooth Pre-authorisation required for in-hospital. One set of plastic dentures allowed per beneficiary per 48 months  Pre-authorisation necessary. General anaesthesia and conscious\nsedation Subject to preauthorisation Only available for beneficiaries under the age of six, severe trauma, and impacted third molars. ",
    "crucialRules": "Surgical removal of erupted tooth \u2013 report per tooth Maximum of two removals. Rebase only allowed once every two years. Reline only allowed once every\ntwo years. Pulp removal (pulpectomy) Once per beneficiary per 12 months; one event per beneficiary per benefit year allowed for emergency dentistry. Root canal  Limited to once per tooth per lifetime and one complete root canal therapy event (one tooth only) per beneficiary per 12 months.",
    "notes": "Denture not funded: (immediate upper  & lower denture. . Patient written consent needed for procedures not covered",
    "source": "https://www.gems.gov.za/en/Healthcare-Providers/Provider-Guides",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Gems",
    "plan": "Emerald & Value",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "'enquiries@gems.gov.za"
      ],
      "fax": ["0860 104 123"],
      "phone": [
        "'0860104121"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Diagnostic dentures, Snoring apparatus,  Clasp or rest \u2013 cast gold,  Clasp or rest \u2013 wrought gold,  Inlay in denture,  Metal base to full dentures,  Emergency crowns not placed for immediate protection of injured teeth. Temporary and provisional crowns, including laboratory costs. Pontics on second molars. On primary teeth or third molars. All implant related clinical and laboratory associated procedures (includes implant placement, cost of components, restorations/crowns/bridges/dentures/repairs associated with implants). Occlusal adjustment minor (pre-authorisation necessary for major occlusal adjustment). ",
    "preAuth": "Pre-authorisation required for all other specialised dentistry procedres. General anaesthesia and conscious sedation  Subject to pre-authorisation, and managed care protocols and\nprocesses  Applicable only to beneficiaries younger than six, severe trauma, and removal of impacted teeth  Impacted third molars: 200% of Scheme rate payable for removal\nunder conscious sedation in doctor\u2019s rooms  Anaesthetists are required to obtain a separate authorisation for dental-related conscious sedation procedures. 8116 (Cephalometric\nradiograph), 8114 (Extraoral radiograph): For orthodontic treatment only, benefit subject to\npre-authorisation. Crowns and bridges Pre-authorisation necessary; benefit available once per tooth per four years. Orthodontics Treatment plan necessary; benefit limited to patients under 21 years. ",
    "crucialRules": "Panoramic radiograph: Benefit from the age of six \u2013 maximum one every 36 months. Restorations/ fillings Benefits available where clinically indicated \u2013 once per tooth in 720 days. One set of full, or full upper or full lower, or partial upper and/ or partial lower plastic dentures every four years; relines, rebase, soft base every two years; metal framework every five years.",
    "notes": " Patient written consent needed for procedures not covered",
    "source": "https://www.gems.gov.za/en/Healthcare-Providers/Provider-Guides",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Gems",
    "plan": "Onyx",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "enquiries@gems.gov.za"
      ],
      "fax": [],
      "phone": [
        "0860436777 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Diagnostic dentures, Snoring apparatus,  Clasp or rest \u2013 cast gold,  Clasp or rest \u2013 wrought gold,  Inlay in denture,  Metal base to full dentures,  Emergency crowns not placed for immediate protection of injured teeth. Temporary and provisional crowns, including laboratory costs. Pontics on second molars. On primary teeth or third molars. All implant related clinical and laboratory associated procedures (includes implant placement, cost of components, restorations/crowns/bridges/dentures/repairs associated with implants). Occlusal adjustment minor (pre-authorisation necessary for major occlusal adjustment). ",
    "preAuth": "Pre-authorisation required for all other specialised dentistry procedres. General anaesthesia and conscious sedation  Subject to pre-authorisation, and managed care protocols and\nprocesses  Applicable only to beneficiaries younger than six, severe trauma, and removal of impacted teeth  Impacted third molars: 200% of Scheme rate payable for removal\nunder conscious sedation in doctor\u2019s rooms  Anaesthetists are required to obtain a separate authorisation for dental-related conscious sedation procedures. 8116 (Cephalometric\nradiograph), 8114 (Extraoral radiograph): For orthodontic treatment only, benefit subject to\npre-authorisation. Crowns and bridges Pre-authorisation necessary; benefit available once per tooth per four years. Orthodontics Treatment plan necessary; benefit limited to patients under 21 years. ",
    "crucialRules": "Panoramic radiograph: Benefit from the age of six \u2013 maximum one every 36 months. Restorations/ fillings Benefits available where clinically indicated \u2013 once per tooth in 720 days. One set of full, or full upper or full lower, or partial upper and/ or partial lower plastic dentures every four years; relines, rebase, soft base every two years; metal framework every five years.",
    "notes": " Patient written consent needed for procedures not covered",
    "source": "https://www.gems.gov.za/en/Healthcare-Providers/Provider-Guides",
    "lastUpdated": "Feb-24"
  },
  {
    "scheme": "Gems",
    "plan": "Ruby",
    "administrator": "MHG",
    "contactInfo": {
      "email": [
        "enquiries@gems.gov.za"
      ],
      "fax": [],
      "phone": [
        "0860436777 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Diagnostic dentures, Snoring apparatus,  Clasp or rest \u2013 cast gold,  Clasp or rest \u2013 wrought gold,  Inlay in denture,  Metal base to full dentures,  Emergency crowns not placed for immediate protection of injured teeth. Temporary and provisional crowns, including laboratory costs. Pontics on second molars. On primary teeth or third molars. All implant related clinical and laboratory associated procedures (includes implant placement, cost of components, restorations/crowns/bridges/dentures/repairs associated with implants). Occlusal adjustment minor (pre-authorisation necessary for major occlusal adjustment). ",
    "preAuth": "Pre-authorisation required for all other specialised dentistry procedres. General anaesthesia and conscious sedation  Subject to pre-authorisation, and managed care protocols and\nprocesses  Applicable only to beneficiaries younger than six, severe trauma, and removal of impacted teeth  Impacted third molars: 200% of Scheme rate payable for removal\nunder conscious sedation in doctor\u2019s rooms  Anaesthetists are required to obtain a separate authorisation for dental-related conscious sedation procedures. 8116 (Cephalometric\nradiograph), 8114 (Extraoral radiograph): For orthodontic treatment only, benefit subject to\npre-authorisation. Crowns and bridges Pre-authorisation necessary; benefit available once per tooth per four years. Orthodontics Treatment plan necessary; benefit limited to patients under 21 years. ",
    "crucialRules": "Panoramic radiograph: Benefit from the age of six \u2013 maximum one every 36 months. Restorations/ fillings Benefits available where clinically indicated \u2013 once per tooth in 720 days. One set of full, or full upper or full lower, or partial upper and/ or partial lower plastic dentures every four years; relines, rebase, soft base every two years; metal framework every five years.",
    "notes": " Patient written consent needed for procedures not covered",
    "source": "https://www.gems.gov.za/en/Healthcare-Providers/Provider-Guides",
    "lastUpdated": "Mar-24"
  },
  {
    "scheme": "Genesis",
    "plan": "MED Plus 200",
    "administrator": "Genesis",
    "contactInfo": {
      "email": [
      ],
      "fax": ["0214474707"],
      "phone": [
        "0860 10 20 10"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " excluding root canal treatment on wisdom teeth ",
    "preAuth": "In Hospital: DENTAL: Cost up to 100% of Scheme Tariff for the surgical removal of bony \nimpacted wisdom teeth, where pathology and pain are directly \nassociated with wisdom teeth Limited to the lower of cost or R15 000 per case (all inclusive)\nCost up to 100% of Scheme Tariff for child beneficiaries, prior to attaining the age of 9 years, for extractions and fillings (once only, lifetime limit), limited to the lower of cost or R10 000 per case Subject to Genesis protocols and approval Limited to one (1) hospital admission per beneficiary p.a. ADVANCED DENTISTRY (i.e. orthodontic treatment) 100% of cost subject to available SMF balance. ",
    "crucialRules": "- Three (3) dental oral examinations - Six (6) fillings - Tooth extractions - Plain X-rays and/or wide angle / Panorex imaging limited to the lower of cost or Scheme Tariff further limited to R750 - Two (2) root canal treatments, excluding root canal treatment on wisdom teeth - Crowns, bridges or dentures limited to the lower of cost or Scheme Tariff, further limited to R5 500",
    "notes": "One (1) dental implant limited to R10 000 per three year financial year cycle of membership",
    "source": "https://www.genesismedical.co.za/wp-content/uploads/2023/10/genesis-medical-scheme-benefits-brochure-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Genesis",
    "plan": "Med 200",
    "administrator": "Genesis",
    "contactInfo": {
      "email": [
      ],
      "fax": ["0214474707"],
      "phone": [
        "0860 10 20 10"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " excluding root canal treatment on wisdom teeth",
    "preAuth": "DENTAL (part of \u201cBasic dentistry\u201d benefit) Cost up to 100% of Scheme Tariff for the surgical removal of bony impacted wisdom teeth, where pathology and pain are directly associated with wisdom teeth Limited to the lower of cost or R15 000 per case (all inclusive) Cost up to 100% of Scheme Tariff for child beneficiaries, prior to attaining the age of 9 years, for extractions and fillings (once only, lifetime limit), limited to the lower of cost or R10 000 per case Subject to Genesis protocols and approval Limited to one (1) hospital admission per beneficiary p.a",
    "crucialRules": "BASIC DENTISTRY Covered at the lower of cost or Scheme Tariff for the following qualifying dental benefits (per beneficiary p.a.) when obtained from a registered Dental Practitioner: - Three (3) dental oral examinations - Six (6) fillings - Tooth extractions - Plain X-rays and/or wide angle / Panorex imaging limited to the lower of cost or Scheme Tariff further limited to R750 - Two (2) root canal treatments, excluding root canal treatment on wisdom teeth - Crowns, bridges or dentures limited to the lower of cost or Scheme Tariff, further limited to R5 500.  Surgical removal of bony impacted wisdom teeth, where pathology  and pain are directly associated with wisdom teeth\n One (1) scale and polish,  One (1) dental implant limited to R10 000 per three year financial year,  cycle of membership",
    "notes": "ADVANCED DENTISTRY   No Benefit",
    "source": "https://www.genesismedical.co.za/wp-content/uploads/2023/10/genesis-medical-scheme-benefits-brochure-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Genesis",
    "plan": "Med 100",
    "administrator": "Genesis",
    "contactInfo": {
      "email": [
      ],
      "fax": ["0214474707"],
      "phone": [
        "0860 10 20 10"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " excluding root canal treatment on wisdom teeth. orthodontic treatment No benefit",
    "preAuth": "Cost up to 100% of Scheme Tariff for the surgical removal of bony impacted wisdom teeth, where pathology and pain are directly associated with wisdom teeth Limited to the lower of cost or R15 000 per case (all inclusive) Cost up to 100% of Scheme Tariff for child beneficiaries, prior to attaining the age of 9 years, for extractions and fillings (once only, lifetime limit), limited to the lower of cost or R10 000 per case Subject to Genesis protocols and approval Limited to one (1) hospital admission per beneficiary p.a.. MAXILLO-FACIAL SURGERY Cost up to 100% of Scheme Tarif. Required as a result of major trauma or accident (excluding tooth implants,",
    "crucialRules": " Six (6) fillings - Tooth extractions.  Six (6) plain X-rays for conservative dentistry (excluding wide angle / panorex imaging .  Two (2) root canal treatments, excluding root canal treatment on wisdom teeth.    Crowns, bridges or dentures limited to the lower of cost or Scheme Tariff, further limited to R5 000  Surgical removal of bony impacted wisdom teeth, where pathology and pain are directly associated with wisdom teeth. \n\u2013\u2013 One (1) dental implant limited to R10 000 per three year financial year cycle of membership.    ADVANCED DENTISTRY No benefits.",
    "notes": "Two (2) root canal treatments. Six (6)                    \nBonus: Includes day-to-day dentistry benefits.",
    "source": "https://www.genesismedical.co.za/wp-content/uploads/2023/10/genesis-medical-scheme-benefits-brochure-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Genric",
    "plan": "COMPREHENSIVE ADVANCED",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for  Root canal fillings, Ortho, Implants,Dentures, In Hospital, ",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.   More than 4 extractions per visit require pre-authorisation. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service.  X-ray  2 per member per visit not more than 4 per  year. Emergency dental treatment where no other treatment item is applicable Maximum of 2 per annum. 8132 cannot be claimed with 8131 or extraction codes. Maximum of 1 per treatment date. Not covered on primary . \nteeth. 1 Restoration code per tooth number in a 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Genric",
    "plan": "COMPREHENSIVE PLUS",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "'0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for  Root canal fillings, Ortho, Implants,Dentures, In Hospital, ",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.   More than 4 extractions per visit require pre-authorisation. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service.  X-ray  2 per member per visit not more than 4 per  year. Emergency dental treatment where no other treatment item is applicable Maximum of 2 per annum. 8132 cannot be claimed with 8131 or extraction codes. Maximum of 1 per treatment date. Not covered on primary . \nteeth. 1 Restoration code per tooth number in a 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Genric",
    "plan": "COMPREHENSIVE STANDARD",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "'0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for  Root canal fillings, Ortho, Implants,Dentures, In Hospital, ",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.   More than 4 extractions per visit require pre-authorisation. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service.  X-ray  2 per member per visit not more than 4 per  year. Emergency dental treatment where no other treatment item is applicable Maximum of 2 per annum. 8132 cannot be claimed with 8131 or extraction codes. Maximum of 1 per treatment date. Not covered on primary . \nteeth. 1 Restoration code per tooth number in a 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Genric",
    "plan": " PRIMARY STANDARD",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "'0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for  Root canal fillings, Ortho, Implants,Dentures, In Hospital, ",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.   More than 4 extractions per visit require pre-authorisation. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service.  X-ray  2 per member per visit not more than 4 per  year. Emergency dental treatment where no other treatment item is applicable Maximum of 2 per annum. 8132 cannot be claimed with 8131 or extraction codes. Maximum of 1 per treatment date. Not covered on primary . \nteeth. 1 Restoration code per tooth number in a 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Genric",
    "plan": " PRIMARY STANDARD WITH HOSPITAL PLAN",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["0861707070"],
      "phone": [
        "0861707070"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for  Root canal fillings, Ortho, Implants,Dentures, In Hospital, ",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.   More than 4 extractions per visit require pre-authorisation. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year.  Pre-authorisation and x-rays are required for any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination, charting and treatment planning  Once per 6 months per member from date of service.  X-ray  2 per member per visit not more than 4 per  year. Emergency dental treatment where no other treatment item is applicable Maximum of 2 per annum. 8132 cannot be claimed with 8131 or extraction codes. Maximum of 1 per treatment date. Not covered on primary . \nteeth. 1 Restoration code per tooth number in a 9-month period.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option.  Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual E - Insurance agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "GETSAVVI",
    "plan": "Getsavvi Dental Top-Up",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes ",
    "nonNetworkedPayment": "Yes ",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "On extraction and restorations codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires authorisation.  No panorex",
    "preAuth": " Periapical x-ray,  Bitewing x-ray  Maximum quantity of 7 per annum.  Pre-authorisation and motivation required. Extraction 4 per annum and more than 4 required pre-authorization.. X-ray: Pre-authorisation and motivation required prior to claims submission when quantity is more than the following:  With 8101, 8112x2 and 8107x1,  With 8104, 8112x1 or 8107x1,  No consult 8112x1 or 8107x1,  Maximum quantity of 7 per annum.  Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Restoration Codes: Pre-authorisation and x-rays required for:  More than 4 restorations per annum.  Two or more: 3 or 4 surface restorations per visit.  More than 2 anterior  restorations per visit.  Multiple restorations on an anterior tooth on the same service date.  Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised. Any 3 or 4 surface restorations on wisdom teeth.",
    "crucialRules": "Full mouth examination charting and treatment plan  Once every 6 months per member from date of service. Examination or consultation for a specific problem, not requiring charting and treatment planning  Not within 4 weeks of 8101, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged). 8132 cannot be claimed with 8131 or any restoration, root canal and extraction code.  Maximum of 1 per member per annum. 8131 Emergency dental treatment where no other treatment item is applicable  Cannot be claimed with 8132 and limited to 1 per member per annum. \n",
    "notes": " 1. Very basic option             2. Lower rate applies to non network provider         3. Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual E Insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "GETSAVVI",
    "plan": "Getsavvi B",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Specialised Dental          No Panorex",
    "preAuth": "Extraction Codes:  Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require preauthorisation. Restoration Codes: Pre-authorisation and x\u0002rays required for: More than 4 restorations per annum.  Three or Four surface restorations done on more than 2 teeth per visit.  More than 2 anterior  restorations per visit.  Multiple restorations on an anterior tooth on the same service date. Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre\u0002authorised.  Any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination charting and treatment plan 1 Per annum with a 6-month interval per member from date of service. X-ray  Maximum 2 per visit. 8132 Emergency root canal treatment 1 Per member per annum. 1 Restoration code per tooth number in a 9 month period. \n",
    "notes": "1. Very basic option             2. Lower rate applies to non network provider         3. Patient written consent needed for procedures not covered",
    "source": "2025 DRC Ops manual E Insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "GETSAVVI",
    "plan": "Getsavvi B Plus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Specialised Dental          No Panorex",
    "preAuth": "Extraction Codes:  Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require preauthorisation. Restoration Codes: Pre-authorisation and x\u0002rays required for: More than 4 restorations per annum.  Three or Four surface restorations done on more than 2 teeth per visit.  More than 2 anterior  restorations per visit.  Multiple restorations on an anterior tooth on the same service date. Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre\u0002authorised.  Any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination charting and treatment plan 1 Per annum with a 6-month interval per member from date of service. X-ray  Maximum 2 per visit. 8132 Emergency root canal treatment 1 Per member per annum. 1 Restoration code per tooth number in a 9 month period. \n",
    "notes": "1. Very basic option             2. Lower rate applies to non network provider         3. Patient written consent needed for procedures not covered",
    "source": "2026 DRC Ops manual E Insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "GETSAVVI",
    "plan": "Getsavvi C",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Specialised Dental          No Panorex",
    "preAuth": "Extraction Codes:  Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require preauthorisation. Restoration Codes: Pre-authorisation and x\u0002rays required for: More than 4 restorations per annum.  Three or Four surface restorations done on more than 2 teeth per visit.  More than 2 anterior  restorations per visit.  Multiple restorations on an anterior tooth on the same service date. Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre\u0002authorised.  Any 3 or 4 surface restorations on wisdom teeth. ",
    "crucialRules": "Full mouth examination charting and treatment plan 1 Per annum with a 6-month interval per member from date of service. X-ray  Maximum 2 per visit. 8132 Emergency root canal treatment 1 Per member per annum. 1 Restoration code per tooth number in a 9 month period. \n",
    "notes": "1. Very basic option             2. Lower rate applies to non network provider         3. Patient written consent needed for procedures not covered",
    "source": "2027 DRC Ops manual E Insurance products.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Health4me",
    "plan": "silver",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        "dentalmotivations@carecross.co.za"
      ],
      "fax": [],
      "phone": [
        "0860101159"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,\ngold fillings, dentures and braces are not covered",
    "preAuth": "Extraction codes \u2013 combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon pre-authorisation.  Denture pre authorisation. Intra-oral radiographs, Maximum four per annum unless otherwise pre auth.\n",
    "crucialRules": "Restoration codes \u2013 posterior amalgam and resin fillings are remunerated at the same tariff rate",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Brochures/2024/H4Me/2024_Health4Me_Marketing%20Brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Health4me",
    "plan": "Gold",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        " dentalmotivations@carecross.co.za"
      ],
      "fax": [],
      "phone": [
        "'0860101159"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,\ngold fillings, dentures and braces are not covered",
    "preAuth": "Extraction codes \u2013 combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon pre-authorisation.  Denture pre authorisation.",
    "crucialRules": "Restoration codes \u2013 posterior amalgam and resin fillings are remunerated at the same tariff rate",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Ingwe",
    "plan": "Ingwe",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        "dentalmotivations@carecross.co.za"
      ],
      "fax": [],
      "phone": [
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No panorex. Specialised dentistry such as bridges, crowns, surgical extractions, implants, root canals,gold fillings, dentures and braces are not covered. Gum guards and gold used in dentures;",
    "preAuth": "Extraction codes \u2013 combined maximum of four per year with an additional four allowed upon pre-authorisation. Maximum four restorations per year with an additional four allowed only upon pre-authorisation.  Denture pre authorisation.",
    "crucialRules": "Restoration codes \u2013 posterior amalgam and resin fillings are remunerated at the same tariff rate",
    "notes": " Patient written consent needed for procedures not covered.",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Focus%20Pages/2024/Options/Focus_on_the_Ingwe_Option_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Keyhealth",
    "plan": "silver",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "keyhealthenq@denis.co.za"
      ],
      "fax": ["0860104926"],
      "phone": [
        "0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Fillings  damaged due to toothbrush abrasion, attrition, erosion and fluorosis,  Resin bonding for restorations that are charged as a separate procedure to the restoration,   The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root canal therapy on primary (milk) teeth,  Root canal therapy on third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Plastic Dentures Benefit not available for\nthe clinical fee of denture repairs, denture tooth replacements and the addition of a soft . base to new dentures; the laboratory fee will be covered (no preauthorisation required). Partial Chrome Cobalt Frame Dentures. No benefits.",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required. Plastic Dentures Pre-authorisation required. Orthodontics and Associated Laboratory Costs Pre-authorisation required Benefit for orthodontic treatment granted once per beneficiary per lifetime\nOnly one family member may commence orthodontic treatment in a calendar year.Hospitalisation (General Anaesthetic) General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment. General anaesthetic benefit available for the removal of impacted teeth. Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. Fillings  Granted once per tooth in 720 days. Benefit for scale and polish:\nTwo scale and polish  treatments per beneficiary  per year (once every 6 \nmonths) Benefit for fissure sealants: Limited to beneficiaries younger than 16 years of age Benefit for fluoride: Limited to beneficiaries from \nage 5 and younger than 13 years of age. Benefit for a mouth guard:  Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre\u0002authorisation required).",
    "notes": " Patient written consent needed for procedures not covered. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/KeyHealth-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Keyhealth",
    "plan": "Gold",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "keyhealthenq@denis.co.za"
      ],
      "fax": ["0860104926"],
      "phone": [
        "0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis,   Resin bonding for restorations that are charged as a separate procedure to the restoration,  The polishing of restorations,  Gold foil restorations.  Root canal therapy on primary  and on  third molars (wisdom teeth),  Direct and indirect pulp  capping procedures.  Diagnostic dentures ,  Provisional dentures and associated laboratory costs,  Snoring appliances,  High impact acrylic,  The cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees.   Crowns on third molars,  Crown and bridge procedures for cosmetic reasons.  Laboratory fabricated temporary crowns,  Occlusal rehabilitation  Provisional crown. Porcelain veneers and  inlays, Emergency crowns that are not placed for the immediate protection in tooth injury ,  The cost of gold, precious metal, semi-precious metal and platinum foi,  Laboratory delivery fees. Implants No Benefits.",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required. Plastic Dentures Pre-authorisation required. Orthodontics and Associated Laboratory Costs Pre-authorisation required Benefit for orthodontic treatment granted once per beneficiary per lifetime\nOnly one family member may commence orthodontic treatment in a calendar year.Hospitalisation  General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment. General anaesthetic benefit available for the removal of impacted teeth. Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment. ",
    "crucialRules": " X-rays: Extraoral One per beneficiary in a  3-year period.  Benefit not available for  the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre\u0002authorisation required)",
    "notes": " Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/KeyHealth-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Keyhealth",
    "plan": "Platinum",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "keyhealthenq@denis.co.za"
      ],
      "fax": ["0860104926"],
      "phone": [
        "0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis,   Resin bonding for restorations that are charged as a separate procedure to the restoration,  The polishing of restorations,  Gold foil restorations.  Root canal therapy on primary  and on  third molars (wisdom teeth),  Direct and indirect pulp  capping procedures.  Diagnostic dentures ,  Provisional dentures ,  Snoring appliances,  High impact acrylic,  The cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees.   Crowns on third molars,  Crown and bridge procedures for cosmetic reasons.  Laboratory fabricated temporary crowns,  Occlusal rehabilitation  Provisional crown. Porcelain veneers and  inlays, Emergency crowns that are not placed for the immediate protection in tooth injury ,  The cost of gold, precious metal, semi-precious metal and platinum foi,   Implants on third molars (wisdom teeth)",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required. Plastic Dentures Pre-authorisation required. Orthodontics and Associated Laboratory Costs Pre-authorisation required Benefit for orthodontic treatment granted once per beneficiary per lifetime\nOnly one family member may commence orthodontic treatment in a calendar year.Hospitalisation  General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment. General anaesthetic benefit available for the removal of impacted teeth. Deep Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment. Implants Pre-authorisation required\nCost of implant components limited to R5 250 per beneficiary per year.",
    "crucialRules": " X-rays: Extraoral One per beneficiary in a  3-year period.  Benefit not available for  the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre\u0002authorisation required)",
    "notes": " Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/KeyHealth-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Keyhealth",
    "plan": "EQUILIBRIUM",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "keyhealthenq@denis.co.za"
      ],
      "fax": ["0860104926"],
      "phone": [
        "0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis,   Resin bonding for restorations that are charged as a separate procedure to the restoration,  The polishing of restorations,  Gold foil restorations.  Root canal therapy on primary  and on  third molars (wisdom teeth),  Direct and indirect pulp  capping procedures.  Diagnostic dentures ,  Provisional dentures ,  Snoring appliances,  High impact acrylic,  The cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees.   Crowns on third molars,  Crown and bridge procedures for cosmetic reasons.  Laboratory fabricated temporary crowns,  Occlusal rehabilitation  Provisional crown. Porcelain veneers and  inlays, Emergency crowns that are not placed for the immediate protection in tooth injury ,  The cost of gold, precious metal, semi-precious metal and platinum foi,   Implants on third molars (wisdom teeth)",
    "preAuth": "Fillings: Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required . Plastic Dentures Pre-authorisation required. Ortho Pre-authorisation required Benefit for orthodontic treatment granted once per beneficiary per lifetime Only one family member may commence orthodontic treatment in a calendar year\nOn pre-authorisation, cases will be clinically assessed by using an orthodontic needs \nanalysis. Benefit allocation is subject to the outcome of the needs analysis. Funding can \nbe granted up to 80% of the KDT per beneficiary per lifetime. General anaesthetic benefit available for the removal of impacted teeth. ",
    "crucialRules": "X-rays: Intraoral One per beneficiary in a 3-year period Additional benefit may be granted where specialised dental treatment is required. No Specialised  Dental. Four X-rays in total per beneficiary per year. Benefit not available for the clinical fee of denture repairs, denture tooth replacements \nand the addition of a soft base to new dentures; the laboratory fee will be covered (no  pre-authorisation required).Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Surgery in the dental chair: \nRemoval of impacted teeth only. ",
    "notes": " Patient written consent needed for procedures not covered.  Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/KeyHealth-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Keyhealth",
    "plan": "Origin",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "keyhealthenq@denis.co.za"
      ],
      "fax": ["0860104926"],
      "phone": [
        "0860104926"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for Root Canal Therapy, Dentures, Specialised Dentistry ",
    "preAuth": "Fillings: Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required . Extractions Benefit subject to managed care protocols. Hospitalisation (General Anaesthetic) Pre-authorisation required Admission protocols apply A co-payment of R1 890 per hospital admission applies All authorised dental cases in Day Clinics will be exempt from a co-payment. Sedation in the Dental Rooms Pre-authorisation required. ONLY for the removal of impacted teeth (code 8941)",
    "crucialRules": "X-rays: Intraoral One per beneficiary in a 3-year period . No Specialised  Dental. CONSERVATIVE DENTISTRY  Subject to Day-to-day Family Limit. Four X-rays per beneficiary per year. X-rays: Extraoral One per beneficiary in a 3-year period. One scale and polish treatment per beneficiary per year (once every 6 months). ",
    "notes": " Patient written consent needed for procedures not covered.  Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/KeyHealth-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "La Health ",
    "plan": "Focus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "service@discovery.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Emergency root canal treatment. Not covered on primary teeth. The subsequent restoration will  need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin\nRestorations on non-functional wisdom teeth.",
    "preAuth": "Extraction Codes: 8101 & 8202,  Maximum 1 x 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. 8132 Emergency root canal treatment,  Not covered on primary teeth.The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Restoration Codes: Pre-authorisation and x-rays required for:  More than 5 restorations per year.  2 or more 3 or 4 surface  restorations per visit.  More than 2 anterior restorations per visit. Denture Codes: Once every 4 years per member  One per jaw (set of dentures) every 4 years. . Repair, Rebase, Reline complete or partial denture,  Adjustment of denture  Benefit covered if denture is in patient history, or suitable motivation/evidence of existing denture is supplied.  More than one per 365 days require pre\u0002authorisation.",
    "crucialRules": "Consultations not covered if no clinical procedure is charged. Maximum of 2 x-rays per visit per member when claimed with 8101.NB: If claimed with 8104 or no consultation maximum of 1 per visit date. sterilised and wrapped instrumentation Will only be paid if code 9011, 8201 or 8202 is claimed. Maximum of 2 x-rays per visit per member when claimed with 8101.                       1 Restoration code per tooth number in a 9 month period ",
    "notes": " Patient written consent needed for procedures not covered.  Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": "2024 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "La Health ",
    "plan": "KeyPlus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Sterilised and wrapped instrumentation  will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed.  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin.  Restorations on non-functional  wisdom teeth.",
    "preAuth": "Periapical x-ray  Pre-authorisation and motivation required prior to claims  submission when quantity is more than the following   : With 8101, 8112x2 and 8107x1 ,  With 8104, 8112x1 or 8107x1,  No consult 8112x1 or 8107x1,  Maximum quantity of 7 per year. Pulp removal  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Extraction Codes: Maximum 1 x 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Pre-authorisation and x-rays required for:  More than 4 restorations per year.  Two or more 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.  Multiple restorations on an anterior tooth on the same service date.  Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  Any 3- or 4 surface restorations on wisdom teeth with supporting x-rays.",
    "crucialRules": "1 Restoration code per tooth number in a 9 month period. Motivation required for code 8104 if no covered clinical procedure is charged.",
    "notes": " Patient written consent needed for procedures not covered.  Patient written consent needed for procedures not covered. Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": "2025 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "La Health ",
    "plan": "Active",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "service@discovery.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 103 933"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "osseo-integrated implant related procedure.",
    "preAuth": "In- and Out-of-Hospital Dentist and related accounts paid from the Major Medical Benefit, up to 100% of the Scheme Rate Dental appliances and prostheses All dental appliances and prostheses, and the placement thereof, as well as orthodontics (surgical and non-surgical) paid from the Major Medical Benefit. Maxillo-facial procedures Subject to preauthorisation. Paid from Major Medical Benefit. ",
    "crucialRules": "Basic dentistry First R4 515 per family per year paid from Major Medical Benefit. Thereafter, paid from and limited to funds in Medical Savings Account and Extended Day-to-day Benefit. Specialised dentistry Paid from and limited to funds in Medical Savings Account and Extended \nDay-to-day Benefit. ",
    "notes": "Panorex once every 3 years.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "La Health ",
    "plan": "Comprehensive",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "service@discovery.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 103 933"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "osseo-integrated implant related procedure.",
    "preAuth": "In- and Out-of-Hospital Dentist and related accounts paid from the Major Medical Benefit, up to 100% of the Scheme Rate Dental appliances and prostheses All dental appliances and prostheses, and the placement thereof, as well as orthodontics (surgical and non-surgical) paid from the Major Medical Benefit. Maxillo-facial procedures Subject to preauthorisation. Paid from Major Medical Benefit. Specialised dentistry Paid from and limited to funds in Medical Savings Account and Above Threshold Benefit, subject to a joint limit of R36 780 per person per year for specialised dentistry, performed in- or out-of-hospital.",
    "crucialRules": "Basic dentistry Paid from and limited to funds in Medical Savings Account and Above Threshold Benefit, subject to a joint limit of R19 000 per person per year for basic dentistry, performed in- or out-of-hospital. ",
    "notes": "Panorex once every 3 years.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "La Health ",
    "plan": "Core",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "service@discovery.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 103 933"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "osseo-integrated implant related procedure.",
    "preAuth": "Maxillo-facial procedures. Subject to preauthorisation. Paid from Major Medical Benefit. Specialised dentistry Paid from and limited to funds in Medical Savings Account and Extended Day-to-day Benefit. ",
    "crucialRules": "Basic dentistry Paid from and limited to funds in Medical Savings Account and Extended Day-to\u0002day",
    "notes": "Panorex once every 3 years.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Malcor",
    "plan": "Plan D",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Partial Chrome Cobalt Frame Dentures and Associated Laboratory Costs No benefit, Crown & Bridge No benefit. Implants No benefit, Orthodontics No benefit. ",
    "preAuth": "Benefit for more than three fillings per beneficiary: Subject to pre-notification. Plastic Dentures Pre-authorisation required. Maxillo-facial Surgery Benefit only available for the following procedure codes: 8931, 8935, 9011 and 9013. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. Benefit for fillings: Granted once per tooth in 720 days. One check-up per beneficiary per year. One scale and polish treatment per beneficiary per year. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no preauthorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "notes": "Surgery in the dental chair: Benefit subject to managed care protocols. Patient written Accountability form needed for procedures not covered.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Enablemed-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Malcor",
    "plan": "Plan A, B,  ",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [

      ],
      "fax": ["0860100698"],
      "phone": [
        "0861123267"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "general anaesthetics, conscious analogsedation and hospitalisation for dental work except in the case of patients under the age of 12 years and bony impaction of third molars. labial frenectomies in respect of beneficiaries under the age of 12 years. use of high impact acrylic and precious metal in dentures or the cost of precious metal as an\nalternative to semi-precious or non-precious metal in dental prosthesis. ",
    "preAuth": "Specialised dentistry pre authorisation. Dentistry (maxilla-facial procedures)\n100% of the Scheme Rate funded from overall annual in-hospital benefit Preauthorisation required . Conservative dentistry and specialised dentistry not covered in-hospital unless preauthorised. In theatre dentistry Children under the age of 12 years 100% of the Scheme Rate funded from overall annual in-hospital benefit. Dentistry (conservative\ndentistry and specialised dentistry, inclusive of osseo-integrated implants as well as related sinus lift and bone graph procedures) 100% of the Scheme Rate funded from overall\nannual out-of-hospital benefit. ",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 365 days. Panorex once every 3 years.",
    "notes": "Annual limit per family based on number of dependants: M \u2013 R12,312 M1 \u2013 R20,510 M2 \u2013 R26,665 M3 \u2013 R32,730 M4+ \u2013 R38,982",
    "source": "https://www.malcormedicalaid.co.za/wcm/medical-schemes/malcor/assets/benefit-guides/2024/benefit-guide-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Malcor",
    "plan": "Plan  B,  ",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [

      ],
      "fax": ["0860100698"],
      "phone": [
        "0861123267"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "general anaesthetics, conscious analogsedation and hospitalisation for dental work except in the case of patients under the age of 12 years and bony impaction of third molars. labial frenectomies in respect of beneficiaries under the age of 12 years. use of high impact acrylic and precious metal in dentures or the cost of precious metal as an\nalternative to semi-precious or non-precious metal in dental prosthesis. ",
    "preAuth": "Specialised dentistry pre authorisation. Dentistry (maxilla-facial procedures)\n100% of the Scheme Rate funded from overall annual in-hospital benefit Preauthorisation required . Conservative dentistry and specialised dentistry not covered in-hospital unless preauthorised. In theatre dentistry Children under the age of 12 years 100% of the Scheme Rate funded from overall annual in-hospital benefit. Dentistry (conservative\ndentistry and specialised dentistry, inclusive of osseo-integrated implants as well as related sinus lift and bone graph procedures) 100% of the Scheme Rate funded from overall\nannual out-of-hospital benefit. ",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 365 days. Panorex once every 3 years.",
    "notes": "Annual limit per family based on number of dependants: M \u2013 R5,491 M1 \u2013 R9,151 M2 \u2013 R11,883 M3 \u2013 R14,626 M4+ \u2013 R15,538",
    "source": "https://www.malcormedicalaid.co.za/wcm/medical-schemes/malcor/assets/benefit-guides/2024/benefit-guide-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Malcor",
    "plan": "Plan C",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [

      ],
      "fax": ["0860100698"],
      "phone": [
        "0861123267"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "general anaesthetics, conscious analogsedation and hospitalisation for dental work except in the case of patients under the age of 12 years and bony impaction of third molars. labial frenectomies in respect of beneficiaries under the age of 12 years. use of high impact acrylic and precious metal in dentures or the cost of precious metal as an\nalternative to semi-precious or non-precious metal in dental prosthesis. ",
    "preAuth": "Dentistry (maxilla-facial procedures) 100% of the Scheme Rate funded from overall annual in-hospital benefit Preauthorisation required. Overall annual in-hospital limit. In theatre dentistry Children under the age of 12 years .Overall annual in-hospital limit. ",
    "crucialRules": "Dentistry (conservative dentistry and specialised dentistry, inclusive of osseo-integrated implants as well as related sinus lift and bone graph procedures). Overall annual out-of-hospitalbenefit limit. ",
    "notes": "",
    "source": "https://www.malcormedicalaid.co.za/wcm/medical-schemes/malcor/assets/benefit-guides/2024/benefit-guide-2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Massmart Health Plan",
    "plan": "Network Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2  per beneficiary  per annum",
    "crucialRules": " Root Canal Treatment Emergency only",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of paymen",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Massmart Health Plan",
    "plan": "Essential Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation required if >2  per beneficiary  per annum.    Denture Benefit  Pre-authorisation required. Only agreed Network codes and tariffs at a Universal network Dentist.  All specialised dentistry must be pre-authorised by Universal Care.",
    "crucialRules": " Root Canal Treatment Emergency only",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of paymen",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medicall",
    "plan": "CLASSIC,",
    "administrator": "PHA Administrators",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "For extraction and restoration codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires pre-authorisation. Benefits for amalgam restorations to be replaced with composite are only available where such \ntreatment is necessary to restore secondary caries. Replacement of non-carious amalgam restorations with resin restorations is not covered.",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per  quadrant per visit.   More than 4 extractions per visit require pre-authorisation. Restoration Codes: Posterior Amalgam and Resin restorations Pre-authorisation and x-rays required for:  More than 4 restorations per  year. More than one: 3 or 4 surface restoration per visit.  More than 2 anterior  restorations per visit.",
    "crucialRules": "  1 Restoration code per tooth number in a 9 month period. X RAYS:  Maximum of 2 x-rays per visit per member.  Maximum quantity of  7 per annum.. 8132 Pulp removal (pulpectomy)  Not covered on primary teeth",
    "notes": "Consultations: (Kindly be advised a motivation will be required if no \ncovered clinical treatment is indicated). 1. Very basic option             2. Lower rate applies to non network provider         3. Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual C Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medicall",
    "plan": "OPTIMA ESTABLISH",
    "administrator": "PHA Administrators",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "For extraction and restoration codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires pre-authorisation. Benefits for amalgam restorations to be replaced with composite are only available where such \ntreatment is necessary to restore secondary caries. Replacement of non-carious amalgam restorations with resin restorations is not covered.",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per  quadrant per visit.   More than 4 extractions per visit require pre-authorisation. Restoration Codes: Posterior Amalgam and Resin restorations Pre-authorisation and x-rays required for:  More than 4 restorations per  year. More than one: 3 or 4 surface restoration per visit.  More than 2 anterior  restorations per visit.",
    "crucialRules": "  2 Restoration code per tooth number in a 9 month period. X RAYS:  Maximum of 2 x-rays per visit per member.  Maximum quantity of  7 per annum.. 8132 Pulp removal (pulpectomy)  Not covered on primary teeth",
    "notes": "Consultations: (Kindly be advised a motivation will be required if no \ncovered clinical treatment is indicated). 1. Very basic option             2. Lower rate applies to non network provider         3. Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual C Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medicall",
    "plan": "ENHANCE OPTIONS",
    "administrator": "PHA Administrators",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "For extraction and restoration codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any deviation requires pre-authorisation. Benefits for amalgam restorations to be replaced with composite are only available where such \ntreatment is necessary to restore secondary caries. Replacement of non-carious amalgam restorations with resin restorations is not covered.",
    "preAuth": "Extraction Codes: Maximum of 1 X 8201 per  quadrant per visit.   More than 4 extractions per visit require pre-authorisation. Restoration Codes: Posterior Amalgam and Resin restorations Pre-authorisation and x-rays required for:  More than 4 restorations per  year. More than one: 3 or 4 surface restoration per visit.  More than 2 anterior  restorations per visit.",
    "crucialRules": "  3 Restoration code per tooth number in a 9 month period. X RAYS:  Maximum of 2 x-rays per visit per member.  Maximum quantity of  7 per annum.. 8132 Pulp removal (pulpectomy)  Not covered on primary teeth",
    "notes": "Consultations: (Kindly be advised a motivation will be required if no \ncovered clinical treatment is indicated). 1. Very basic option             2. Lower rate applies to non network provider         3. Patient written consent needed for procedures not covered",
    "source": "2024 DRC Ops manual C Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "MediClub i-Series",
    "plan": "iConnect",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861033647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital. No benefits for Panorex. Pulpectomy (pain relief) Not covered on primary teeth.",
    "preAuth": "Motivation and records required for more than 5 fillings per dependent per year\n\u2212 Motivation required for 3 or 4 surface fillings on wisdom teeth (3 rd Molars) ",
    "crucialRules": "Maximum of 2 x-rays films per visit per dependent. \u2212 Benefit for fillings is granted once per tooth in 9 months",
    "notes": "Benefits payable on the iConnect,  Plans are subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/MediClub-iSeries-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "MediClub i-Series",
    "plan": "iPremier",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital. No benefits for Panorex. Pulpectomy (pain relief) Not covered on primary teeth.",
    "preAuth": "Motivation and records required for more than 5 fillings per dependent per year\n\u2212 Motivation required for 3 or 4 surface fillings on wisdom teeth (3 rd Molars) ",
    "crucialRules": "Maximum of 2 x-rays films per visit per dependent. \u2212 Benefit for fillings is granted once per tooth in 9 months",
    "notes": "DSP Network Benefits payable on the MediClub Elite Plan is subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/MediClub-iSeries-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "MediClub i-Series",
    "plan": "iElite",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital. No benefits for Panorex. Pulpectomy (pain relief) Not covered on primary teeth.",
    "preAuth": "Motivation and records required for more than 5 fillings per dependent per year\n\u2212 Motivation required for 3 or 4 surface fillings on wisdom teeth (3 rd Molars) ",
    "crucialRules": "Maximum of 2 x-rays films per visit per dependent. \u2212 Benefit for fillings is granted once per tooth in 9 months",
    "notes": "Benefits payable on the TymeHealth MediClub Connect, Elite and Premier Plans are subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/MediClub-iSeries-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedVital & Med Vital  Elect",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " INHALATION SEDATION IN THE DENTIST\u2019S ROOMS    No Benefits.                                      CONSERVATIVE SERVICES  No Benefits\nSPECIALISED DENTAL SERVICES      No Benefits                                                                                     SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS\nNo Benefits.  EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 7 YEARS    No Benefits                                                                                                   ",
    "preAuth": "IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA): REMOVAL OF IMPACTED TEETH. Once per lifetime\nA co-payment of R3 880 is applica\u0002ble per admission.  Limited to the removal of im\u0002pacted third molars once per life\u0002time. ",
    "crucialRules": "IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA) Benefits are subject to managed care protocols and pre-authorisation. Failure to pre-authorise the procedure will result in a 20% co-payment on scheme tariff (of approved codes). Limited to extensive dental treatment for the removal of impacted third molars. Only the below stated codes are covered in-hospital unless related to a valid PMB event. NB: Dental Procedures require a 35% co-payment per admission if voluntarily obtained in a hospital/facility other than a day procedure network facility, subject to PMB",
    "notes": "THIS IS NOT A GUARANTEE OF PAYMENT BUT A CLINICAL INDICATION AS AT THE TIME AND DATE THAT THE AUTHORISATION WAS PROCESSED. PLEASE NOTE THAT IT IS PART OF THE NETWORK PROVIDERS\u2019 RESPONSIBILITY TO INFORM THE MEMBERS PRIOR TO PERFORMING PROCEDURES OUTSIDE OF THEIR BENEFITS OF THE COSTS THAT THEY MAY BE RESPONSIBLE FOR",
    "source": "2024 DRC Ops manual B Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedAdd & MedAdd Elect",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars.  Clear, well defined (no cone\u0002cutting) radiographs are re\u0002quired to support crown authorisations. Teeth with sub-gingival caries OR that are periodontally compromised, OR have pa\u0002thology at the apices, OR Root canal treatment without apical and coronal sealing will not be considered for restorative treatment. (eg. Crown and bridge; restorations).  Access through a prosthetic crown or inlay  No crown codes may be charged within 6 months of code 8136. No theatre or hospital benefit \nfor placement of implants SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS No Benefits",
    "preAuth": "EXTRACTIONS:Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Surgical removal of residual root -per tooth, pre-authorisation. One restoration per tooth number within 12 months from date of service. Pre-authorisation and x-rays required for:  More than 4 restorations per year  2 or more, 3 or 4 surface res\u0002torations per visit. ore than 2 anterior restorations per visit. 3 or 4 surface restorations on third molar teeth. ROOT CANAL TREATMENT  pre-authorisation and managed care protocols. INTRA-ORAL X-RAYS: Pre-authorisation  when quantity is  more than the following:  More than 6 per member per annum irrespective of the tariff code used. Code 8133 requires motivation if claimed on same tooth within 365 days. Code 8514 requires motivation if claimed on same teeth  within 365 days. Two partial metal frame dentures (an upper or/and a lower jaw) per member per 5-year period from date of service.  ORTHODONTICS: Only valid for members younger than 18 years. PERIODONTICS Documentation required with authorisation request:  Periodontal charting.  Supporting radiographs. MAXILLOFACIAL SURGERY AND ORAL PATHOLOGY authorisation. INHALATION SEDATION IN THE DENTIST\u2019S ROOMS Benefits are limited to extensive dental treatment in accordance with managed care protocols (3 or more teeth involved). Procedure must be covered for sedation benefit to be payable. CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS, SEDATION: REMOVAL OF IMPACTED TEETH. IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA) Limited to the removal of impacted third molars once per lifetime. EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 7 YEARS More than 3 teeth need to be involved.",
    "crucialRules": "NB: *Conservative services for members younger than 18 years, as indicated in the protocol, will be paid from the member\u2019s annual dental benefit. All claims for members older than 18 years will be paid from the savings account.  EXTRA-ORAL X-RAYS One per member in a 3-year period from date of service.\nBenefit limited to members older than 5 years unless motivated.   PLASTIC DENTURES Bite plate (8169) will be covered under denture benefits. No benefits shall be granted for the clinical fee for denture repair, denture tooth replace\u0002ments and the addition of a soft base to new dentures, but the laboratory fee will be covered. INCISION AND DRAINAGE OF ABSCESS Savings account  Limited to treatment in the consultation rooms. ROOT CANAL TREATMENT Savings account. RCT limited to members 12 years and older. Ortho Retainers are covered once in a member\u2019s lifetime.  Lower jaw posterior restorations to be performed across the midline on the same visit. No cover for code 8131.",
    "notes": "   Patient written consent needed for procedures not covered    CONSULTATIONS: Not within 4 weeks of 8101, 8104 (Motivation required for code 8104 if no covered clinical procedure is charged). Tooth numbers required.  Immediate dentures are treated as normal dentures \nand members can only re-ac\u0002cess benefit after 4 years from  date of service.  Bite plate (8169) will be cov\u0002ered under denture benefit. \nOne per member every 365  days from date of service.  One reline or rebase per  member per 12 month period  from date of service.",
    "source": "2024 DRC Ops manual B Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedPrime & MedPrime Elect",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for oral hygiene in\u0002structions/evaluation.No benefits shall be granted for the clinical fee for denture re\u0002pair, denture tooth replace\u0002ments and the addition of a soft base to new dentures, but the laboratory fee will be covered. No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars.\n Clear, well defined (no cone\u0002cutting) radiographs are re\u0002quired to support crown authorisations. Teeth with sub-gingival caries OR that are periodontally compromised, OR have pathology at the apices, OR Root canal treat\u0002ment without apical and coronal sealing will not be considered for restorative treatment. (eg. Crown and bridge; restorations).  ",
    "preAuth": "EXTRACTIONS:  Maximum 1 per quadrant more than 4 extractions per visit require pre-authorisation.  Surgical removal of tooth  Subject to pre-authorisation. RESTORATION: Pre-authorisation  More than 4 restorations per year (especially anterior teeth). 2 or more, 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit. 3 or 4 surface restorations on  third molar teeth. ROOT CANAL TREATMENT  Retreatment subject to pre-authorisation. INTRA-ORAL X-RAYS Pre-authorisation : More than 6 per member per  annum. SPECIALISED DENTAL SERVICES: Pre authorisation. METAL FRAME DENTURES: Pre authorisation. One crown annually per family. SEDATION: REMOVAL OF IMPACTED TEETH:  Limited to the removal of impacted third molars once per lifetime. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS. IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA)  REMOVAL OF IMPACTED TEETH. EXTRACTION: More than 4 extractions per visit require pre-authorisation. ORTHO: Only valid for members younger than 18 years. ",
    "crucialRules": "Panoramic x-ray  One per member in a 3-year period from date of service. Benefit limited to members older than 5 years unless motivated. No benefits shall be granted on crowns or crown retainers on third molars and Pontics on sec\u0002ond molars.  Clear, well defined (no cone cutting) radiographs are re\u0002quired to support crown authorisations. Teeth with sub-gingival caries OR that are periodontally com\u0002promised, OR have pathology at  the apices, OR Root canal treatment without apical and coronal sealing will not be considered  for restorative treatment. (eg. Crown and bridge; restorations)",
    "notes": "CONSULTATIONS: (Motivation required for code 8104 if no covered clinical procedure is charged).  Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the  laboratory fee will be covered.  Retainers are covered once in \na member\u2019s lifetime.  Patient written consent needed for procedures not covered .",
    "source": "2024 DRC Ops manual B Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedElite",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for oral hygiene in\u0002structions/evaluation.No benefits shall be granted for \nthe clinical fee for denture re\u0002pair, denture tooth replace\u0002ments and the addition of a soft base to new dentures, but the laboratory fee will be covered. No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars.\nClear, well defined (no cone\u0002cutting) radiographs are required to support crown authorisations. Teeth with sub-gingival caries OR that are periodontally compromised, OR have pathology at the apices, OR Root canal treatment without apical and coronal sealing will not be considered for restorative treatment. (eg. Crown and bridge; restorations).  ",
    "preAuth": "EXTRACTIONS:  Maximum 1 per quadrant more than 4 extractions per visit require pre-authorisation.  Surgical removal of tooth  Subject to pre-authorisation. RESTORATION: Pre-authorisation  More than 4 restorations per year (especially anterior teeth). 2 or more, 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit. 3 or 4 surface restorations on  third molar teeth. ROOT CANAL TREATMENT  Retreatment subject to pre-authorisation. INTRA-ORAL X-RAYS Pre-authorisation : More than 6 per member per  annum. SPECIALISED DENTAL SERVICES: Pre authorisation. METAL FRAME DENTURES: Pre authorisation. One crown annually per family. SEDATION: REMOVAL OF IMPACTED TEETH:  Limited to the removal of impacted third molars once per lifetime. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS. IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA)  REMOVAL OF IMPACTED TEETH. EXTRACTION: More than 4 extractions per visit require pre-authorisation. ORTHO: Only valid for members younger than 18 years. ",
    "crucialRules": "Panoramic x-ray  One per member in a 3-year period from date of service. \uf0b7 Benefit limited to members older than 5 years unless motivated. No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars.  Clear, well defined (no cone\u0002cutting) radiographs are required to support crown authorisations.  Teeth with sub-gingival caries OR that are periodontally com\u0002promised, OR have pathology at  the apices, OR Root canal treatment without apical and coronal sealing will not be considered  for restorative treatment. (eg. Crown and bridge; restorations)",
    "notes": "CONSULTATIONS: (Motivation required for code 8104 if no covered clinical procedure is charged).  Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture re\u0002pair, denture tooth replace\u0002ments and the addition of a soft base to new dentures, but the  laboratory fee will be covered. Retainers are covered once in a member\u2019s lifetime.   Patient written consent needed for procedures not covered .",
    "source": "2024 DRC Ops manual B Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedPlus",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for oral hygiene in\u0002structions/evaluation.No benefits shall be granted for \nthe clinical fee for denture re\u0002pair, denture tooth replace\u0002ments and the addition of a soft base to new dentures, but the laboratory fee will be covered. No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars.\n\uf0b7 Clear, well defined (no cone\u0002cutting) radiographs are required to support crown authorisations. Teeth with sub-gingival caries OR that are periodontally compromised, OR have pathology at the apices, OR Root canal treatment without apical and coronal sealing will not be considered for restorative treatment. (eg. Crown and bridge; restorations).  ",
    "preAuth": "EXTRACTIONS:  Maximum 1 per quadrant more than 4 extractions per visit require pre-authorisation.  Surgical removal of tooth  Subject to pre-authorisation. RESTORATION CODES: One restoration per tooth number within 12 months from date of ser\u0002vice Pre-authorisation and x-rays re\u0002quired for: More than 4 restorations per year (especially anterior teeth).  2 or more, 3 or 4 surface restorations per visit. More than 2 anterior restorations per visit. 3 or 4 surface restorations on  third molar teeth. ROOT CANAL TREATMENT  Retreatment subject to pre-authorisation . INTRA-ORAL X-RAYS Pre-authorisation and motivation required prior to claims submission when quantity is more than the following:  More than 6 per member per  annum irrespective of the tariff code used. SPECIALISED DENTAL SERVICES:  One set of full/partial dentures (upper and/or lower jaw) per member per 4-year period from date of service.Benefits are subject to managed care protocols and pre-authorisation. METAL FRAME DENTURES: Pre authorisation. CROWNS AND BRIDGES: Two crowns annually per family, limited to once per tooth per 5 year period from date of service. IMPLANTS:  Two implants per member per 5 year period. SEDATION: REMOVAL OF IMPACTED TEETH:  Limited to the removal of impacted third molars once per lifetime. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS. IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA)  REMOVAL OF IMPACTED TEETH, ",
    "crucialRules": "Panoramic x-ray  One per member in a 3-year period from date of service. \uf0b7 Benefit limited to members older than 5 years unless motivated. No benefits shall be granted on crowns or crown retainers on third molars and Pontics on second molars.  Clear, well defined (no cone\u0002cutting) radiographs are required to support crown authorisations.  Teeth with sub-gingival caries OR that are periodontally com\u0002promised, OR have pathology at  the apices, OR Root canal treatment without apical and coronal sealing will not be considered  for restorative treatment. (eg. Crown and bridge; restorations)",
    "notes": "CONSULTATIONS: (Motivation required for code 8104 if no covered clinical procedure is charged).  Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture repair, denture tooth replace\u0002ments and the addition of a soft base to new dentures, but the  laboratory fee will be covered.    Patient written consent needed for procedures not covered .",
    "source": "2028 DRC Ops manual B Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedElect (NETWORK PROVIDER AND HOSPITALS)",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for oral hygiene in\u0002structions/evaluation.No benefits shall be granted for \nthe clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the laboratory fee will be covered. SPECIALISED DENTAL SERVICES: No Benefits. Posterior resins not covered",
    "preAuth": "EXTRACTIONS: Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Surgical removal of tooth  Subject to pre-authorisation. RESTORATION CODES: Limited to 4 restorations annually per member. One restoration per tooth number within 12 months from date of service. Pre-authorisation and x-rays  required for:  2 or more, 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.3 or 4 surface restorations on third molar teeth. INCISION AND DRAINAGE OF ABSCESS Sedation would require authorisation. ROOT CANAL TREATMENT 8132 Emergency root canal treatment Limited to 2 teeth annually per member. ROOT CANAL TREATMENT   8132 Emergency root canal treatment  Limited to 2 teeth annually per member. PLASTIC DENTURES  One set of plastic dentures (an upper and a lower jaw) per family every 2-years for patients 21 years and older. The benefit is payable at 80%  of the MDT. INTRA-ORAL X-RAYS 8107 Periapical x-ray 4 x-rays annually per member. Pre-authorisation. EXTRA-ORAL X-RAYS 8115 Panoramic x-ray  One per member in a 3-year period from date of service. INHALATION SEDATION IN THE DENTIST\u2019S ROOMS Benefits are limited to extensive dental treatment in accordance with managed care protocols (3 or more teeth in\u0002volved). SEDATION: REMOVAL OF IMPACTED TEETH imited to the removal of im\u0002pacted third molars once per lifetime. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS  Limited to 4 restorations annually per member. Posterior resins not covered. IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA) ). Limited to extensive dental treatment for children younger than 12 years of age and the removal of impacted third molars.",
    "crucialRules": "Full mouth examination, charting and treatment planning  1 per 365 days per member from date of service. SCALE AND POLISH 1 per 365 days per member from date of service. ",
    "notes": "CONSULTATIONS: (Motivation required for code 8104 if no covered clinical procedure is \ncharged).  Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture re\u0002pair, denture tooth replace\u0002ments and the addition of a soft \nbase to new dentures, but the  laboratory fee will be covered.    Patient written consent needed for procedures not covered .",
    "source": "2024 DRC Ops manual B Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedMove",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "YES",
    "nonNetworkedPayment": "NO",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "NO",
    "dentures": "NO",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "NO",
    "exclusions": "Specialised dentistry, root canals, extraction, dentures, metal base dentures, Implants, Ortho, crown and Bridges, Hospital admissions, Sedations. ",
    "preAuth": "",
    "crucialRules": "CONSERVATIVE SERVICES: Full mouth examination, charting and treatment planning.  One consultation per beneficiary per 365 days from date of \nservice. 8109 Infection Control Maximum 2 per valid consultation.  8110 Provision of heat or vapour sterilised and wrapped instrumentation\n Maximum 1 per valid consultation.  8107 / 8112 Maximum of 4 x-rays. ",
    "notes": "",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medihelp",
    "plan": "MedSaver",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " SPECIALISED DENTAL SERVICES: No benefits shall be granted on crowns or crown retain\u0002ers on third molars and Pontics on second molars. Clear and well defined (no cone-cutting) radiographs are required to support crown authorisations. Teeth with sub-gingival caries OR that are periodontally compromised, OR have pathology at the apices, OR Root canal treatment without apical and coronal sealing will not be considered for restorative treatment. (eg. Crown and bridge; restorations). No benefits for oral hygiene instructions/evaluation. No cover for 8348 or 8131.",
    "preAuth": "EXTRACTIONS:  Maximum 1 per quadrant more than 4 extractions per visit require pre-authorisation. Surgical removal of tooth  Subject to pre-authorisation. RESTORATION CODES: Pre-authorisation:  More than 4 restorations per year (especially anterior teeth).  2 or more, 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.. 3 or 4 surface restorations on third molar teeth. ROOT CANAL TREATMENT:  Retreatment subject to preauthorisation. INTRA-ORAL X-RAYS : Pre-authorisation and motivation required when quantity is more than the following:  More than 6 per member per annum irrespective of the tariff code used. METAL FRAME DENTURES :  authorisation. PLASTIC DENTURES One set of full/partial dentures (upper and/or lower jaw) per member per 4-year period from date of service. IMPLANTS  authorisation. REMOVAL OF IMPACTED TEETH: Once per lifetime.  Limited to the removal of impacted third molars once per lifetime. EXTRACTIONS 8201,8202 Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Surgical removal of tooth Subject to pre-authorisation. CROWNS AND BRIDGES pre authorisation. ORTHO  Only valid for members younger than 18 years. IMPLANTS No hospital benefit for placement of implants. INHALATION SEDATION IN THE DENTIST\u2019S ROOMS benefits are limited to extensive dental treatment in accordance with managed care protocols (3 or more teeth involved). CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS Surgical removal of impacted tooth imited to the removal of impacted third molars once per lifetime. SEDATION: EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 12 YEARS No Benefits. IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA) Extensive dental treatment for children younger than 7 years is not covered by the Scheme and payable only from the members available savings at time of submission of the claim. ",
    "crucialRules": "8101 Full mouth examination, charting and treatment planning  consultation for a specific problem (Motivation required for code 8104 if no covered clinical procedure is charged).  EXTRA-ORAL X-RAYS:  One per member in a 3-year period from date of service. EXTENSIVE DENTAL TREATMENT FOR CHILDREN YOUNGER THAN 7 YEARS: Hospital, Anaesthetist and Dentist account payable from available savings account.    8107 / 8112  Periapical x-ray   Maximum of 4 x-rays per valid consultation (8101).\n",
    "notes": "CONSULTATIONS: (Motivation required for code 8104 if no covered clinical procedure is charged).  Bite plate (8169) will be covered under denture benefit. One per member every 365 days from date of service. No benefits shall be granted for the clinical fee for denture repair, denture tooth replacements and the addition of a soft base to new dentures, but the  laboratory fee will be covered.    Patient written consent needed for procedures not covered .  Posterior restorations to be performed across the midline on the same visit. ",
    "source": "2024 DRC Ops manual B Capitation agreement",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medimed",
    "plan": "Alpha",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "hospauth@medimed.co.za"
      ],
      "fax": [],
      "phone": [
        "0861777660"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis,   Resin bonding for restorations that are charged as a separate procedure to the restoration, Gold foil restorations.  Root canal therapy on primary  and on  third molars , Direct and indirect pulp  capping procedures.  Diagnostic dentures ,  Provisional dentures ,  Snoring appliances,  High impact acrylic,  The cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees.   Crowns on third molars,  Crown and bridge procedures.  Laboratory fabricated temporary crowns,  Provisional crown. Porcelain veneers and  inlays, Emergency crowns that are not placed for the immediate protection in tooth injury , Implants on third molars (wisdom teeth) ",
    "preAuth": "N-HOSPITAL DENTISTRY Includes hospitalisation and all other in-hospital providers\u2019 costs.  R12,000 per family Anaesthetist, maxillofacial - 75%, dentist  60% of the MEDIMED Scheme Tariff  Limited to impacted wisdom teeth and children under 12 years. ",
    "crucialRules": " Specialised R5,000 per beneficiary up to R10, 000 per family Paid at 60% of the MEDIMED Scheme Tariff . ",
    "notes": "Dentures shall be limited to one set PB per two consecutive financial year period. All orthodontic services are subject to prior approval by the Scheme\u2019s designated agent. General anaesthetic and hospitalisation for conservative dental work excluded except in the case of trauma, patients under the age of 12 years and impacted 3rd molars in which as an annual limit of R12 000 per member family including all hospital and doctor cost will apply",
    "source": "https://www.medimed.co.za/wp-content/uploads/Alpha_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medimed",
    "plan": "Medisave Standard",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "hospauth@medimed.co.za"
      ],
      "fax": [],
      "phone": [
        "0861777660"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis,   Resin bonding for restorations that are charged as a separate procedure to the restoration, Gold foil restorations.  Root canal therapy on primary  and on  third molars , Direct and indirect pulp  capping procedures.  Diagnostic dentures ,  Provisional dentures ,  Snoring appliances,  High impact acrylic,  The cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees.   Crowns on third molars,  Crown and bridge procedures.  Laboratory fabricated temporary crowns,  Provisional crown. Porcelain veneers and  inlays, Emergency crowns that are not placed for the immediate protection in tooth injury , Implants on third molars (wisdom teeth) ",
    "preAuth": "IN-HOSPITAL DENTISTRY Includes hospitalisation and all other in-hospital providers\u2019 costs. R12,000 per beneficiary Limited to impacted wisdom teeth and children under 12 years Special dentistry (Including metal base dentures ). ",
    "crucialRules": "Special dentistry (Including metal base dentures )",
    "notes": "Basic and advanced dentistry SUBJECT TO THE MEDICAL SAVINGS ACCOUNT AND ELECTIVE BENEFITS AVAILABLE",
    "source": "https://www.medimed.co.za/wp-content/uploads/Medisave_Standard_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medimed",
    "plan": "Medisave Max",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "hospauth@medimed.co.za"
      ],
      "fax": [],
      "phone": [
        "0861777660"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis,   Resin bonding for restorations that are charged as a separate procedure to the restoration, Gold foil restorations.  Root canal therapy on primary  and on  third molars , Direct and indirect pulp  capping procedures.  Diagnostic dentures ,  Provisional dentures ,  Snoring appliances,  High impact acrylic,  The cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees.   Crowns on third molars,  Crown and bridge procedures.  Laboratory fabricated temporary crowns,  Provisional crown. Porcelain veneers and  inlays, Emergency crowns that are not placed for the immediate protection in tooth injury , Implants on third molars (wisdom teeth) ",
    "preAuth": "IN-HOSPITAL DENTISTRY Includes hospitalisation and all other in-hospital providers\u2019 costs. R14,000 per beneficiary Limited to impacted wisdom teeth and children under 12 years",
    "crucialRules": "DENTAL SERVICES 1. Conservative and Restorative dentistry 2. Special dentistry (including dentures). Savings account",
    "notes": "",
    "source": "https://www.medimed.co.za/wp-content/uploads/Medisave_Max_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medimed",
    "plan": "Medisave Essential",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "hospauth@medimed.co.za"
      ],
      "fax": [],
      "phone": [
        "0861777660"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis,   Resin bonding for restorations that are charged as a separate procedure to the restoration, Gold foil restorations.  Root canal therapy on primary  and on  third molars , Direct and indirect pulp  capping procedures.  Diagnostic dentures ,  Provisional dentures ,  Snoring appliances,  High impact acrylic,  The cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees.   Crowns on third molars,  Crown and bridge procedures.  Laboratory fabricated temporary crowns,  Provisional crown. Porcelain veneers and  inlays, Emergency crowns that are not placed for the immediate protection in tooth injury , Implants on third molars (wisdom teeth) ",
    "preAuth": "IN-HOSPITAL DENTISTRY Includes hospitalisation and all other in-hospital providers\u2019 costs. R10,000 per family Limited to impacted wisdom teeth and children under 12 years. SURGICAL PROCEDURES INCLUDING MAXILLO FACIAL SURGERY",
    "crucialRules": "R3,200 per beneficiary up to a maximum of R5,500 per family.  One set of plastic dentures every two years",
    "notes": "",
    "source": "https://www.medimed.co.za/wp-content/uploads/Medisave_Essential_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDIBONUS (550)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings  due to toothbrush abrasion, attrition, erosion and fluorosis. \u2022 Resin bonding for restorations that are charged as a separate procedure to the restoration, Polishing of restorations,  Gold foil restorations,  Ozone therapy. Root canal therapy on primary (milk)  teeth and  Root canal therapy on third molars, (wisdom teeth),  Direct and indirect pulp capping  procedures. . Diagnostic dentures,  Snoring appliances,  High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil  Laboratory delivery fees,  Provisional dentures,  SPECIALISED DENTISTRY  The metal base to full dentures, High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil,  Crowns and bridges Crowns on third molars,  Laboratory fabricated temporary  crowns,  Occlusal rehabilitations . Provisional crowns ,  Emergency crowns that are not placed  for the immediate protection in tooth injury,  Implants on third molars (wisdom  teeth)",
    "preAuth": " Multiple fillings: A treatment plan and X\u0002rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required . Partial Chrome Cobalt  Frame Dentures Pre-authorisation required, Root Canal Therapy. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Payable from the Specialised Dentistry limit of R20 350 per family. Crown & Bridge Pre-authorisation required Benefit for crowns will be granted once per tooth in a 5-year period. Implants Pre-authorisation required. Orthodontics Pre-authorisation required. Oral Surgery Payable from the Maxillo-facial limit of R21 950 per family. Hospitalisation:  available for children under the age of 6 years for extensive dental treatment. Hospitalisation: Impacted Teeth. Pre-authorisation required. Sedation in Dental Rooms Basic Dentistry (beneficiaries under and  over the age of 6): Anaesthetist and dental accounts payable at the MDT from the OAL Impacted Teeth and Apicectomy: Anaesthetist account payable at the MDT from the OAL",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 720 days Benefit for re-treatment of a tooth: Subject to managed care protocols Multiple fillings: A treatment plan and X\u0002rays may be required for multiple fillings. Benefit not available for the clinical fee of  denture repairs, denture tooth  replacements and the addition of a soft  base to new dentures; the laboratory fee  will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Panorex every 3 years.",
    "notes": " Patient written consent needed for procedures not covered.             2. Lower rate applies to non network provider      ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDIPLUS PRIME (551)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings  due to toothbrush abrasion, attrition, erosion and fluorosis. \u2022 Resin bonding for restorations that are charged as a separate procedure to the restoration, Polishing of restorations,  Gold foil restorations,  Ozone therapy. Root canal therapy on primary (milk)  teeth and  Root canal therapy on third molars, (wisdom teeth),  Direct and indirect pulp capping  procedures. . Diagnostic dentures,  Snoring appliances,  High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil  Laboratory delivery fees,  Provisional dentures,  SPECIALISED DENTISTRY  The metal base to full dentures, High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil,  Crowns and bridges Crowns on third molars,  Laboratory fabricated temporary  crowns,  Occlusal rehabilitations . Provisional crowns ,  Emergency crowns that are not placed  for the immediate protection in tooth injury,  Implants on third molars (wisdom  teeth)",
    "preAuth": " Multiple fillings: A treatment plan and X\u0002rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required . Partial Chrome Cobalt  Frame Dentures Pre-authorisation required, Root Canal Therapy. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Payable from the Specialised Dentistry limit of R 14 600  per family. Crown & Bridge Pre-authorisation required Benefit for crowns will be granted once per tooth in a 5-year period. Implants Pre-authorisation required. Orthodontics Pre-authorisation required. Oral Surgery Payable from the Maxillo-facial limit of R 14 600  per family. Hospitalisation:  available for children under the age of 6 years for extensive dental treatment. Hospitalisation: Impacted Teeth. Pre-authorisation required. Sedation in Dental Rooms Basic Dentistry (beneficiaries under and  over the age of 6): Anaesthetist and dental accounts payable at the MDT from the OAL Impacted Teeth and Apicectomy: Anaesthetist account payable at the MDT from the OAL",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 720 days Benefit for re-treatment of a tooth: Subject to managed care protocols Multiple fillings: A treatment plan and X\u0002rays may be required for multiple fillings. Benefit not available for the clinical fee of  denture repairs, denture tooth  replacements and the addition of a soft  base to new dentures; the laboratory fee  will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Panorex every 3 years.",
    "notes": " Patient written consent needed for procedures not covered.             2. Lower rate applies to non network provider      ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDIPLUS COMPACT (561)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings  due to toothbrush abrasion, attrition, erosion and fluorosis. \u2022 Resin bonding for restorations that are charged as a separate procedure to the restoration, Polishing of restorations,  Gold foil restorations,  Ozone therapy. Root canal therapy on primary (milk)  teeth and  Root canal therapy on third molars, (wisdom teeth),  Direct and indirect pulp capping  procedures. . Diagnostic dentures,  Snoring appliances,  High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil  Laboratory delivery fees,  Provisional dentures,  SPECIALISED DENTISTRY  The metal base to full dentures, High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil,  Crowns and bridges Crowns on third molars,  Laboratory fabricated temporary  crowns,  Occlusal rehabilitations . Provisional crowns ,  Emergency crowns that are not placed  for the immediate protection in tooth injury,  Implants on third molars (wisdom  teeth)",
    "preAuth": " Multiple fillings: A treatment plan and X\u0002rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required . Partial Chrome Cobalt  Frame Dentures Pre-authorisation required, Root Canal Therapy. Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Pre-authorisation required Two partial frames (an upper and a lower) per beneficiary in a 5-year period. Payable from the Specialised Dentistry limit of R 14 600  per family. Crown & Bridge Pre-authorisation required Benefit for crowns will be granted once per tooth in a 5-year period. Implants Pre-authorisation required. Orthodontics Pre-authorisation required. Oral Surgery Payable from the Maxillo-facial limit of R 14 600  per family. Hospitalisation:  available for children under the age of 6 years for extensive dental treatment. Hospitalisation: Impacted Teeth. Pre-authorisation required. Sedation in Dental Rooms Basic Dentistry (beneficiaries under and  over the age of 6): Anaesthetist and dental accounts payable at the MDT from the OAL Impacted Teeth and Apicectomy: Anaesthetist account payable at the MDT from the OAL",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 720 days Benefit for re-treatment of a tooth: Subject to managed care protocols Multiple fillings: A treatment plan and X\u0002rays may be required for multiple fillings. Benefit not available for the clinical fee of  denture repairs, denture tooth  replacements and the addition of a soft  base to new dentures; the laboratory fee  will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Panorex every 3 years.",
    "notes": " Patient written consent needed for procedures not covered.  Lower rate applies to non network provider      ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "Medphila",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Partial Chrome Cobalt Frame Dentures : No benefit        Crown & Bridge No benefits   Implants: No Benefits    Orthodontics    No Benefits.  Hospitalisation: Basic Dentistry No Benefit. ",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required for multiple fillings; payable from a sub-limit of R1 700 per family, limited to and included in the Specialised Dentistry limit of         R6 900 per family. Plastic Dentures Pre-authorisation required. Hospitalisation:Impacted Teeth. Pre-authorisation required. Sedation in Dental  Rooms  Only applicable to Impacted teeth and\nApicectomy. ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period. Fillings  Granted once per tooth in 720 days. Partial Chrome Cobalt Frame Dentures No benefits. . Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). Oral Surgery PMB only. Maxillo-facial Surgery PMB only.",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required). Oral Surgery (Non-elective surgery\nonly).  Patient written consent needed for procedures not covered.   Lower rate applies to non network provider. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDIVALUE PRIME (552)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings  due to toothbrush abrasion, attrition, erosion and fluorosis. \u2022 Resin bonding for restorations that are charged as a separate procedure to the restoration, Polishing of restorations,  Gold foil restorations,  Ozone therapy. Root canal therapy on primary (milk)  teeth and  Root canal therapy on third molars, (wisdom teeth),  Direct and indirect pulp capping  procedures. . Diagnostic dentures,  Snoring appliances,  High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil  Laboratory delivery fees,  Provisional dentures,  SPECIALISED DENTISTRY  The metal base to full dentures, High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil,  Crowns and bridges Crowns on third molars,  Laboratory fabricated temporary  crowns,  Occlusal rehabilitations . Provisional crowns ,  Emergency crowns that are not placed  for the immediate protection in tooth injury,  Implants on third molars (wisdom  teeth)",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required . Benefit not available for the clinical fee of\ndenture repairs, denture tooth replacements and the addition of a soft base to new\ndentures; the laboratory fee will be covered (no pre-authorisation required).  Partial Chrome Cobalt Frame Dentures and  Crown & Bridge Pre-authorisation required. Implants and Orthodontics Pre-authorisation required.Hospitalisation: Impacted Teeth Pre-authorisation required. Sedation in Dental Rooms: Pre-authorisation required. Oral Surgery Covered at the MDT; payable from the Maxillo-facial limit of R8 275 per family. General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6  years for extensive dental treatment. General anaesthetic benefit available for the removal of impacted teeth. Sedation: Basic Dentistry (beneficiaries under and over the age of 6), Impacted teeth and Apicectomy.",
    "crucialRules": "CONSERVATIVE  DENTISTRY ; payable from the  family limit of R2 550 and then from the day-to-day limit.      X-rays: Extraoral   Once every 3 years. Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. ",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required).Patient written Accountability form needed for procedures not covered.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDIVALUE COMPACT (562)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings  due to toothbrush abrasion, attrition, erosion and fluorosis. \u2022 Resin bonding for restorations that are charged as a separate procedure to the restoration, Polishing of restorations,  Gold foil restorations,  Ozone therapy. Root canal therapy on primary (milk)  teeth and  Root canal therapy on third molars, (wisdom teeth),  Direct and indirect pulp capping  procedures. . Diagnostic dentures,  Snoring appliances,  High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil  Laboratory delivery fees,  Provisional dentures,  SPECIALISED DENTISTRY  The metal base to full dentures, High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil,  Crowns and bridges Crowns on third molars,  Laboratory fabricated temporary  crowns,  Occlusal rehabilitations . Provisional crowns ,  Emergency crowns that are not placed  for the immediate protection in tooth injury,  Implants on third molars (wisdom  teeth)",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required . Benefit not available for the clinical fee of\ndenture repairs, denture tooth replacements and the addition of a soft base to new\ndentures; the laboratory fee will be covered (no pre-authorisation required).  Partial Chrome Cobalt Frame Dentures and  Crown & Bridge Pre-authorisation required. Implants and Orthodontics Pre-authorisation required.Hospitalisation: Impacted Teeth Pre-authorisation required. Sedation in Dental Rooms: Pre-authorisation required. Oral Surgery Covered at the MDT; payable from the Maxillo-facial limit of R8 275 per family. General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6  years for extensive dental treatment. General anaesthetic benefit available for the removal of impacted teeth. Sedation: Basic Dentistry (beneficiaries under and over the age of 6), Impacted teeth and Apicectomy.",
    "crucialRules": "CONSERVATIVE  DENTISTRY ; payable from the  family limit of R2 550 and then from the day-to-day limit.      X-rays: Extraoral   Once every 3 years. Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. ",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required).  Patient written consent needed for procedures not covered.    Lower rate applies to non network provider   ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDISAVER (683)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings  due to toothbrush abrasion, attrition, erosion and fluorosis.  Resin bonding for restorations that are charged as a separate procedure to the restoration, Polishing of restorations,  Gold foil restorations,  Ozone therapy. Root canal therapy on primary (milk)  teeth and  Root canal therapy on third molars, (wisdom teeth),  Direct and indirect pulp capping  procedures. . Diagnostic dentures,  Snoring appliances,  High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil  Laboratory delivery fees,  Provisional dentures,  SPECIALISED DENTISTRY  The metal base to full dentures, High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil,  Crowns and bridges Crowns on third molars,  Laboratory fabricated temporary  crowns,  Occlusal rehabilitations . Provisional crowns ,  Emergency crowns that are not placed  for the immediate protection in tooth injury,  Implants on third molars (wisdom  teeth)",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required .One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period   Partial Chrome Cobalt Frame Dentures: Pre-authorisation required. Crown & Bridge Pre-authorisation required. Implants Pre-authorisation required. Orthodontics Pre-authorisation required.Hospitalisation: Impacted Teeth Pre-authorisation required. Sedation in Dental Rooms: Pre-authorisation required. Maxillo-facial Surgery Payable from the Maxillo-facial limit of  R21 950 per family. Hospitalisation General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age \nof 6 years for extensive dental treatment. Hospitalisation Impacted Teeth General anaesthetic benefit available for the removal of impacted teeth. Sedation in Dental Rooms Basic Dentistry (beneficiaries under the age of 6), Basic Dentistry (beneficiaries 6 years and older):  Both the anaesthetist and the dental accounts payable from the PSA. Impacted Teeth and Apicectomy:\n Anaesthetist account payable from the OAL. Implants:  Both the anaesthetist and the \ndental accounts payable from the Specialised Dentistry limit of R14 550 per family. \n",
    "crucialRules": " CONSERVATIVE DENTISTRY, Payable from the Personal Savings Account (PSA).       X-rays: Extraoral   Once every 3 years. Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Root Canal Therapy and Extractions: Payable from the PSA.   Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required).",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required). Patient written consent needed for procedures not covered. Lower rate applies to non network provider   ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "PREMIUM PLUS (686) ",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Fillings  due to toothbrush abrasion, attrition, erosion and fluorosis. \u2022 Resin bonding for restorations that are charged as a separate procedure to the restoration, Polishing of restorations,  Gold foil restorations,  Ozone therapy. Root canal therapy on primary (milk)  teeth and  Root canal therapy on third molars, (wisdom teeth),  Direct and indirect pulp capping  procedures. . Diagnostic dentures,  Snoring appliances,  High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil  Laboratory delivery fees,  Provisional dentures,  SPECIALISED DENTISTRY  The metal base to full dentures, High impact acrylic, The cost of gold, precious metal, semi\u0002precious metal and platinum foil,  Crowns and bridges Crowns on third molars,  Laboratory fabricated temporary  crowns,  Occlusal rehabilitations . Provisional crowns ,  Emergency crowns that are not placed  for the immediate protection in tooth injury,  Implants on third molars (wisdom  teeth)",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Plastic Dentures Pre-authorisation required .One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period   Partial Chrome Cobalt Frame Dentures: Pre-authorisation required. Crown & Bridge Pre-authorisation required. Implants Pre-authorisation required. Orthodontics Pre-authorisation required.Hospitalisation: Impacted Teeth Pre-authorisation required. Sedation in Dental Rooms: Pre-authorisation required. Maxillo-facial Surgery Payable from the Maxillo-facial limit of  R21 950 per family. Hospitalisation General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age \nof 6 years for extensive dental treatment. Hospitalisation Impacted Teeth General anaesthetic benefit available for the removal of impacted teeth. Sedation in Dental Rooms Basic Dentistry (beneficiaries under the age of 6), Basic Dentistry (beneficiaries 6 years and older):  Both the anaesthetist and the dental accounts payable from the PSA. Impacted Teeth and Apicectomy:\n Anaesthetist account payable from the OAL. Implants:  Both the anaesthetist and the \ndental accounts payable from the Specialised Dentistry limit of R14 550 per family. ",
    "crucialRules": " CONSERVATIVE DENTISTRY, Payable from the Personal Savings Account (PSA).       X-rays: Extraoral   Once every 3 years. Benefit for fillings: Granted once per tooth in 720 days. Multiple fillings: A treatment plan and X-rays may be required for multiple fillings. Root Canal Therapy and Extractions: Payable from the PSA.   Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required).",
    "notes": "Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no preauthorisation required). Oral Surgery (Non-elective surgery only)  Patient written consent needed for procedures not covered.    Lower rate applies to non network provider   ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDICURVE",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "yes",
    "exclusions": "Root Canal Therapy and Extractions No benefit,  Plastic Dentures s No benefit, Hospitalisation: Basic Dentistry. No benefit. ",
    "preAuth": "Benefit for 4 fillings per beneficiary per year.  Benefit for fillings are granted once per tooth in 720 days. Maxillo-facial Surgery Pre-authorisation required. Sedation in the Dental \nRooms  Pre-authorisation required Benefit subject to managed care protocols ONLY for Impacted Teeth: Both accounts (the anaesthetist and dental accounts) are covered at the MDT from the OAL",
    "crucialRules": "One dental examination (code 8101) every six months with a R150 upfront co-payment per beneficiary. One Extraoral X-ray (8115) per beneficiary per lifetime will be considered; subject to motivation and approval for the removal of impacted wisdom teeth ONLY.",
    "notes": "",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDICORE (685)",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "no",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Hospitalisation: Impacted Teeth No benefit.  No Basic  or specialised dentistry.mpacted Teeth and Apicectomy: No benefit Implants: No benefit",
    "preAuth": "Hospitalisation: Basic Dentistry: General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years for extensive dental treatment. ",
    "crucialRules": "Deep Sedation in Dental Rooms Pre-authorisation required Benefit subject to managed care protocols Basic Dentistry (beneficiaries under the age of 6): Anaesthetist and dental accounts payable at the MDT from the OAL",
    "notes": " Patient written consent needed for procedures not covered.  Lower rate applies to non network provider      ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Medshield",
    "plan": "MEDISWIFT (564) hospitaal plan",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
          "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "no",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Basic or Advanced dentistry. Hospitalisation: Impacted Teeth No benefit",
    "preAuth": "Oral Surgery (Non-elective surgery only) ; payable from the Maxillo-facial limit of R14 300 per family.  Maxillo-facial Surgery payable from the Maxillo-facial limit of R14 300 per family. General anaesthetic and hospitalisation benefits for Basic Dentistry are only available for children under the age of 6 years for extensive dental treatment. ",
    "crucialRules": "Deep Sedation in Dental Rooms* Pre-authorisation required Benefit subject to managed care protocols Basic Dentistry (beneficiaries under the age of 6): Anaesthetist and dental accounts payable at the MDT from the OAL Impacted Teeth and Apicectomy: No benefit Implants: No benefit",
    "notes": " Patient written consent needed for procedures not covered.  Lower rate applies to non network provider      ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Medshield-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Momentum",
    "plan": "Evolve",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [

      ],
      "fax": ["+27 31 573 4016"],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "In-hospital dental and oral benefits Not covered. Maxillo-facial trauma covered at State facilities, limited to Prescribed Minimum Benefits. ",
    "preAuth": "Dentistry \u2013 basic (such as extractions or fillings) Subject to HealthSaver if available Dentistry \u2013 specialised Subject to HealthSaver if available",
    "crucialRules": "Dental consultation (incl. sterile tray and gloves) All beneficiaries  Once a year. Dentistry \u2013 basic (such as extractions or fillings) Subject to HealthSaver if available",
    "notes": " Patient written consent needed for procedures not covered.  Lower rate applies to non network provider      ",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Focus%20Pages/2024/Options/Focus_on_the_Evolve_Option_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Momentum",
    "plan": "Custom",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [

      ],
      "fax": ["+27 31 573 4016"],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "If a member chooses to have primary care dentistry done under sedation, it is important to inform them that they will be responsible for the sedation account.",
    "preAuth": "maxillo-facial surgery (excluding implants) and general anaesthesia for children under 7. The hospital account is paid at the negotiated rate, subject to a  R1 830 co-payment per authorisation.  The dental, dental specialist and maxillo-facial surgeon accounts are paid from HealthSaver, if available. Extraction of impacted wisdom teeth The hospital account is paid at the negotiated rate, subject to a R3 300 co-payment for day hospitals and a R6 150 co-payment for other hospitals per authorisation.",
    "crucialRules": "Dentistry \u2013 basic (such as extractions or fillings) Subject to HealthSaver if available. Dentistry \u2013 specialised Anaesthetist and dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to R1 830 co-payment and pre-authorisation Other specialised dentistry: Subject to HealthSaver if available. ",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Focus%20Pages/2024/Options/Focus_on_the_Custom_Option_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Momentum",
    "plan": "Insentive",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [

      ],
      "fax": ["+27 31 573 4016"],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations,  Bone and tissue regeneration procedures,  Crowns and bridges for cosmetic reasons and associated laboratory costs,  Enamel micro abrasion,  Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees,  Othognatic surgery,  Sinus lift,  Gum guards or mouth protectors",
    "preAuth": "In-hospital dental and oral benefits - maxillo-facial surgery (excluding implants) and general anaesthesia for children under 7.  dentistry related to trauma The hospital account is paid at the negotiated rate. The anaesthetist, dentist, dental specialist and maxillo-facial surgeon accounts are  covered up to 200% of the Momentum Medical Scheme Rate. extraction of impacted wisdom teeth The hospital account is paid at the negotiated rate, subject to a \nR3 300 co-payment for day hospitals and a R6 150 co-payment for  other hospitals per authorisation. The anaesthetist account is  covered up to 200% of the Momentum Medical Scheme Rate and the dentist, dental specialist and maxillo-facial surgeon accounts are paid \nup to 100% of the Momentum Medical Scheme Rate. implants The cost of implants, as well as the hospital, anaesthetist, dentist,  dental specialist and maxillo-facial surgeon accounts are paid from Savings or HealthSaver, if available",
    "crucialRules": "Dentistry \u2013 specialised. Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to R1 670  copayment and pre-authorisation Other specialised dentistry: Subject to Savings, if available. Dentistry \u2013 basic (such as extractions or fillings) Subject to Savings, if available",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Focus%20Pages/2024/Options/Focus_on_the_Incentive_Option_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Momentum",
    "plan": "Extender",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [

      ],
      "fax": ["+27 31 573 4016"],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations,  Bone and tissue regeneration procedures,  Crowns and bridges for cosmetic reasons and associated laboratory costs,  Enamel micro abrasion,  Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees,  Othognatic surgery,  Sinus lift,  Gum guards or mouth protectors",
    "preAuth": "Pre authorisation all specialised dental. n-hospital dental and oral benefits: - maxillo-facial surgery (excluding implants) and general anaesthesia for children under 7. The hospital account is paid at the negotiated rate, subject to a R1 670  co-payment per authorisation. The anaesthetist account is covered up to  200% of the Momentum Medical Scheme Rate. The dentist, dental specialist and maxillo-facial surgeon accounts are paid from available day-to-day benefits, subject to the day-to-day limits. Extraction of impacted wisdom teeth.The hospital account is paid at the negotiated rate, subject to a R3 300 co-payment for day hospitals and a R6 150 co-payment for other hospitals per authorisation. Implants and all other in-hospital dental treatment . The cost of implants, as well as the hospital, anaesthetist, dentist, dental specialist and maxillo-facial surgeon accounts are paid from available  day-to-day benefits, subject to the day-to-day limits",
    "crucialRules": "Dentistry \u2013 basic (such as extractions or fillings) Unlimited . Dentistry \u2013 specialised (such as bridges or crowns). Both in-and out-of-hospital dental specialist accounts accumulate towards\nthe limit. Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Focus%20Pages/2024/Options/Focus_on_the_Extender_Option_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Momentum",
    "plan": "Summit",
    "administrator": "Momentum ",
    "contactInfo": {
      "email": [

      ],
      "fax": ["+27 31 573 4016"],
      "phone": [
        "0860117859"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations,  Bone and tissue regeneration procedures,  Crowns and bridges for cosmetic reasons and associated laboratory costs,  Enamel micro abrasion,  Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees,  Othognatic surgery,  Sinus lift,  Gum guards or mouth protectors",
    "preAuth": "Pre authorisation all specialised dental. n-hospital dental and oral benefits: - maxillo-facial surgery (excluding implants) and general anaesthesia for children under 7. The hospital account is paid at the negotiated rate, subject to a R1 670  co-payment per authorisation. The anaesthetist account is covered up to  200% of the Momentum Medical Scheme Rate. The dentist, dental specialist and maxillo-facial surgeon accounts are paid from available day-to-day benefits, subject to the day-to-day limits. Extraction of impacted wisdom teeth.The hospital account is paid at the negotiated rate, subject to a R3 300 co-payment for day hospitals and a R6 150 co-payment for other hospitals per authorisation. Implants and all other in-hospital dental treatment . The cost of implants, as well as the hospital, anaesthetist, dentist, dental specialist and maxillo-facial surgeon accounts are paid from available  day-to-day benefits, subject to the day-to-day limits",
    "crucialRules": "Dentistry \u2013 basic (such as extractions or fillings) Subject to overall annual day-to-day limit of R31 300 per beneficiary. Dentistry \u2013 specialised (such as bridges or \ncrowns). R18 800 per beneficiary, R45 300 per family. Subject to overall \nannual day-to-day limit of R31 300 per beneficiary. Both in- and out of hospital dental specialist accounts accumulate towards the limit. Dental specialist accounts for extraction of impacted wisdom teeth in doctors\u2019 rooms: Covered from Major Medical Benefit at 100% of the Momentum Medical Scheme Rate, subject to pre-authorisation. ",
    "notes": "You can access your Momentum HealthSaver funds with the Multiply Money Card. The card allows you to pay for purchases and services from your Momentum HealthSaver funds",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Focus%20Pages/2024/Options/Focus_on_the_Summit_Option_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Moto Health Care",
    "plan": "CLASSIC OPTION AND CLASSIC NETWORK",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered Replacement of non\u0002carious amalgam with resin will not be covered  Posterior teeth restorations cannot be performed across the midline.",
    "preAuth": "8169 Occlusal guard Pre-authorisation required. Once every 24 months. Extraction Codes 8201 & 8202;  Extraction first tooth  Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require preauthorisation. 8937 Surgical removal of erupted tooth.  Pre-Authorisation required. Restoration Codes: Pre-authorisation and x\u0002rays required for more than 3 restorations per visit. Pre-authorisation and x\u0002rays required for any 3 or 4 surface restorations on wisdom teeth (non\u0002functional wisdom teeth not covered). Multiple restorations on an anterior tooth on the same service date only per authorisation and x\u0002rays received. Dentures: One set of plastic dentures, full or partial (an upper and a lower) per beneficiary in a 4-year period for members older than 21 years. Specialised Dentistry - Pre-authorisation required: Metal Frame Dentures  1 Frame in 5 years per member. Crown and Bridges, Implants, Orthodontics Paid from Annual Savings Limit per member per lifetime. Hospitalisation and Anaesthetics: Children under the age of 8 with extensive conservative (basic) dental treatment, limited to 1 admission per member per 24 months. Removal of impacted wisdom teeth but must be pre-authorised. 8144 IV Conscious sedation in room  Clinical protocols apply \u2013must be authorised.",
    "crucialRules": "X-ray: Code 8107 and 8112 cannot be charged more than 7 times (per year). 8167 Treatment of hypersensitive dentine per visit  Twice a year per member (180 days apart from previous) Not with 8159 on the same day. 8131 Emergency dental treatment where no other treatment item is applicable  Cannot be claimed with a covered procedure. 1 Restoration code per tooth number in a 9 month period.   Lower jaw posterior teeth restorations cannot be performed across the midline. Root Canal:  Only covered on  permanent teeth. Reline, repair, rebase,Remodel once every 365 days. Bite Plate (lab codes must be included) Paid from Annual Savings. ",
    "notes": " Patient written consent needed for procedures not covered.             2. Lower rate applies to non network provider      ",
    "source": "2024 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Moto Health Care",
    "plan": "Optimum",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Soft base reline per denture (exclusion to new dentures, can only be claimed 6 months after  new denture). Diagnostic dentures Pontics on 2nd molars Provisional and emergency crowns and associated laboratory cost. Multiple hospital admission for extensive conservative (basic) dentistry in young children (only one admission per child every 24 months). Laboratory delivery fees. Surgical tooth exposure for orthodontic reasons in-hospital Surgical tooth exposure that was not pre-authorised as part of a orthodontic treatment plan. In-hospital dental implants, dentectomies, and apisectomies. ",
    "preAuth": "X-ray Code 8107 and 8112 cannot be charged more than 7 times (per year). 8115 Extra-oral radiograph \u2013 panoramic  Maximum 2 Panoramic radiograph per member per treatment plan \u2013 per 24 months (six-month time lapse applies). 8169 Occlusal guard Pre-authorisation required. Once every 24 months. Restoration Codes: Pre-authorisation and x\u0002rays required for more than 3 restorations per visit. Pre-authorisation and x\u0002rays required for any 3 or 4 surface restorations on wisdom teeth (non\u0002functional wisdom teeth not covered). Multiple restorations on an anterior tooth on the same service date only per authorisation and x\u0002rays received. Root Canal Codes \u2013 Pre-authorisation needed .Dentures Codes,  Crown and Bridges,  Orthodontics (paid at Scheme rate)  Pre-authorisation required. 8144 IV Conscious sedation in room Clinical protocols apply \u2013 must be authorised. Hospitalisation and Anaesthetics: Pre-authorisation required. Children under the age of 8 with extensive conservative (basic) dental treatment, limited to 1 admission per member per 24 months. Removal of impacted wisdom teeth but must be pre-authorised. ",
    "crucialRules": "1 Restoration code per tooth number in a 9 month period. 8167 Treatment of hypersensitive dentine per visit  Twice a year per member (180 days apart from previous)  Not with 8159 on the same day. ",
    "notes": " Patient written consent needed for procedures not covered.  Lower rate applies to non network provider.   Conservative Dentistry Subject to the Dental sub limit and the Day to Day limit of: Member = R2 849 and Family = R5 742. Please confirm benefits with Momentum at (086 1000 300). Only codes stated below with limitations are covered should a code not be in the manual then it is not covered.",
    "source": "2025 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Moto Health Care",
    "plan": "Custom",
    "administrator": "Momentum Carecross",
    "contactInfo": {
      "email": [
        "dental.motivations@momentum.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 000 300"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No specialised dentistry. No panorex. Root canal treatment, crowns and other advanced dentistry are not covered.",
    "preAuth": "3 extractions will be processed automatically and any additional must be pre-authorised .  4 fillings will be processed automatically and any additional must be pre-authorised",
    "crucialRules": "Restoration codes \u2013 posterior amalgam and resin fillings are remunerated at the same tariff rate",
    "notes": " Patient written consent needed for procedures not covered.  Lower rate applies to non network provider      ",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Moto Health Care",
    "plan": "Essential",
    "administrator": "Momentum  Carecross",
    "contactInfo": {
      "email": [
        "dental.motivations@momentum.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 000 300"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No specialised dentistry. No panorex. Root canal treatment, crowns and other advanced dentistry are not covered.",
    "preAuth": "4 extractions will be processed automatically and any additional must be pre-authorised .  4 fillings will be processed automatically and any additional must be pre-authorised",
    "crucialRules": "Restoration codes \u2013 posterior amalgam and resin fillings are remunerated at the same tariff rate",
    "notes": " Patient written consent needed for procedures not covered.             2. Lower rate applies to non network provider      ",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "NBCPSS",
    "plan": "NBCPSS",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No Panorex    No specialised dentistry",
    "preAuth": "Surgical removal of tooth  Surgical removal is only covered when authorised ",
    "crucialRules": "One full mouth examination per covered period. Limited to 180 days apart from date of service.  x-ray 120.00 116.00 2 per member per covered period. Limited to 3 extractions per member per covered period.  Maximum of 1 X 8201 per quadrant per visit. Limited to 3 restorations per member per covered period. 1 Restorations code per tooth number in a 12-month period.",
    "notes": " Very basic option. Patient written consent needed for procedures not covered",
    "source": "2024 DRC op manual E Insurance",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "NBCRFLI",
    "plan": "NBCRFLI",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "087 943 9611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No Specialised dental. No Restorations. No Preventative care. No Panorex.",
    "preAuth": " Extraction: Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require preauthorisation. Surgical removal of tooth  Surgical removal is only covered when authorised with an intraoral radiograph. Treatment of septic socket More than 1 treatment per visit requires authorisation.",
    "crucialRules": "Full mouth examination, charting and treatment planning  Limited to once per annum. Must be 180 days apart. x-ray  Limited to 2 per member per annum. ",
    "notes": "Only Emergency Treatment, Infection Control, Extraction, Surgical Removals. 1. Very basic option. Patient written consent needed for procedures not covered",
    "source": "2025 DRC op manual E Insurance",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Netcare",
    "plan": "Savings",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "member@netcaremedicalscheme.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 638 633"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Panorex every 3 years.",
    "preAuth": "Pre-authorisation is required for in-hospital dentistry. For those under the age of 8 years cover is unlimited, (paid at 100% of the Scheme tariff) for theatre and anaesthetist whilst the combined in-and out-of-hospital annual dental family limit will apply to services rendered by the dentists/dental surgeon/Maxillo Facial Surgeon. We will fund for Maxillo-Facial Surgery from the Risk Benefit as long as you have pre-authorised the event. Claims will be funded at 100% of the Scheme Rate.",
    "crucialRules": "Combined in- and out-of-hospital dentistry limit . Preferred Provider use recommended to minimise co-payments. ",
    "notes": "",
    "source": "https://www.netcaremedicalscheme.co.za/wcm/medical-schemes/netcare/assets/benefit-guides/2024/netcare-dental-benefit.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "NHG MediClub Elite",
    "plan": "NHG MediClub Elite",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Dental benefits are subject to managed care protocols and managed care interventions which may include the requirement of treatment plans and/or radiographs prior to benefit application. Motivation and records required for more than 5 fillings per dependent per year \u2212 Motivation required for 3 or 4 surface fillings on wisdom teeth (3rd Molars) \u2212 Benefit for fillings is granted once per tooth in 9 months",
    "crucialRules": "Maximum of 2 x-rays films per visit per dependent. No panorex",
    "notes": "Benefits payable on the TymeHealth MediClub Connect, Elite and Premier Plans are subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/NHG-MediClub-Elite-Benefit-Table-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Old Mutual Health Solutions",
    "plan": "COMPREHENSIVE STANDARD PLAN",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Fillings  required for more than 5 fillings per dependant per year. Motivation required for 3 or 4 surface fillings on wisdom teeth (3 rd Molars). ",
    "crucialRules": "Two consultations per dependant per year (once every 6 months). One specific consultation for pain and sepsis per dependant per year; not within 4 weeks of 8101. Maximum of 2 X-rays films per visit per dependant. Benefit for fillings is granted once per tooth in 9 months. 8201 Extractions tooth or exposed roots. 8132 Pulpectomy (pain relief) Not covered on primary teet",
    "notes": "Benefits on the Comprehensive Standard Plan and Primary Standard Plus Hospital Plans are subject to the use of a Network Service Provider on the DENIS Dental Network. There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Old-Mutual-Health-Solutions-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Old Mutual Health Solutions",
    "plan": "PRIMARY STANDARD PLUS HOSPITAL PLAN",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": ["086 000 2121"],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital.",
    "preAuth": "Fillings  required for more than 5 fillings per dependant per year. Motivation required for 3 or 4 surface fillings on wisdom teeth (3 rd Molars). ",
    "crucialRules": "Two consultations per dependant per year (once every 6 months). One specific consultation for pain and sepsis per dependant per year; not within 4 weeks of 8101. Maximum of 2 X-rays films per visit per dependant. Benefit for fillings is granted once per tooth in 9 months. 8201 Extractions tooth or exposed roots. 8132 Pulpectomy (pain relief) Not covered on primary teet",
    "notes": "Benefits on the Comprehensive Standard Plan and Primary Standard Plus Hospital Plans are subject to the use of a Network Service Provider on the DENIS Dental Network. There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Old-Mutual-Health-Solutions-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Old Mutual Staff ",
    "plan": "Savings Plan",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "enquiries@omsmaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860100076"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Exclusions: Bleaching of teeth and  Adult fluoride.  Fillings to restore teeth due to toothbrush abrasion; attrition; erosion  Resin bonding that is charged separately  Polishing of restorations  Gold foil restorations,  Ozone Therapy. ",
    "preAuth": "Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if less than 2 years. 1 set of plastic dentures (an upper and a lower) per beneficiary per three years  Partial dentures consisting of more than 9 teeth require clinical motivation by your dentist. 2 metal dentures (2 frames for upper and lower jaws) allowed per beneficiary per 4 year cycle,  Partial metal dentures of more than 9 teeth require motivation as to why a full denture cannot be made. Implants Preauthorisation required. Orthodontics: Pre-authorisation required. (General anaesthetic: Children younger than 8 years. Removal of impacted wisdom teeth (if the beneficiary is older than 30 years, a clinical motivation will be \nrequired). s sedation in rooms  Pre-authorisation is required.",
    "crucialRules": "Consultations:  2 annual checks ups per beneficiary per year.  Further consultations \u2013 subject to clinical protocols and require authorisation, One root canal therapy allowed per tooth per lifetime. ",
    "notes": "Root canal therapy,  May only be done if a tooth is not responding to normal conservative measure of a filling with a base,  One root canal therapy allowed per tooth per lifetime  Only emergency root canals are covered on the Network (including Select) Plan.",
    "source": "https://omsmaf.co.za/wp-content/uploads/2021/12/OMSMAF-DENTAL-GUIDELINE_v6.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Old Mutual Staff ",
    "plan": "Network & network select",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "enquiries@omsmaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860100076"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for Ortho, Maxilla-Facial surgery and Oral Pathology.  Immediate dentures: one tissue conditioning and one reline/rebase allowed. Not funded on the Network Plan.  Metal dentures are excluded on the Network (including Select) Plan. Crowns; Bridges and associated costs Not funded on the Network and Hospital Plan. Implants and associated costs  Not funded on the Hospital and Network Plans. Fillings: Exclusions:  Fillings to restore teeth due to toothbrush abrasion; attrition; erosion\n Resin bonding that is charged separately  Polishing of restorations  Gold foil restorations  Ozone Therapy",
    "preAuth": "Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if less than 2 years. One pair of plastic dentures per beneficiary per 3 year period at a Universal Network provider. (dentists only). Specialised dentistry: Only PMBs & pre-authorisation required. Pre\u0002authorisation required if >3 per annum per beneficiary. Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions \nper annum per beneficiary. Teeth  extractions to be  done in dentist\u2019s  rooms under local \nanaesthesia.",
    "crucialRules": "Only emergency root canals are covered on the Network (including Select) Plan.  Extraoral Once every 3 years. Limited to 2 consultations per beneficiary per annum. ",
    "notes": "Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if less than 2 years. Occlusal guards \u2022 Only allowed in cases for preservation of tooth structure and maintenance of the periodontium. ",
    "source": "https://omsmaf.co.za/wp-content/uploads/2021/12/OMSMAF-DENTAL-GUIDELINE_v6.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Old Mutual Staff ",
    "plan": "Traditional & Traditional select",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "enquiries@omsmaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860100076"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Exclusions: Bleaching of teeth and  Adult fluoride.  Fillings to restore teeth due to toothbrush abrasion; attrition; erosion  Resin bonding that is charged separately  Polishing of restorations  Gold foil restorations,  Ozone Therapy. ",
    "preAuth": "Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if less than 2 years. 1 set of plastic dentures (an upper and a lower) per beneficiary per three years  Partial dentures consisting of more than 9 teeth require clinical motivation by your dentist. 2 metal dentures (2 frames for upper and lower jaws) allowed per beneficiary per 4 year cycle,  Partial metal dentures of more than 9 teeth require motivation as to why a full denture cannot be made. Implants Preauthorisation required. Orthodontics: Pre-authorisation required. (General anaesthetic: Children younger than 8 years. Removal of impacted wisdom teeth (if the beneficiary is older than 30 years, a clinical motivation will be ",
    "crucialRules": "Consultations:  2 annual checks ups per beneficiary per year.  Further consultations \u2013 subject to clinical protocols and require authorisation, One root canal therapy allowed per tooth per lifetime. ",
    "notes": "Implants and associated costs.One implant per tooth per lifetime\n Placement of up to two implants per tooth should be done under local anaesthetic, unless the beneficiary is  medically compromised\n OMSMAF allows funding of implants under general anaesthetic as follows on the Traditional, Traditional Plus, including Select Plans from available day-to-day benefits, including a defined implant benefit, and the Savings Plan from available savings",
    "source": "https://omsmaf.co.za/wp-content/uploads/2021/12/OMSMAF-DENTAL-GUIDELINE_v6.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Old Mutual Staff ",
    "plan": "Traditional plus & traditional Plus select",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "enquiries@omsmaf.co.za"
      ],
      "fax": [],
      "phone": [
        "0860100076"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": " Exclusions: Bleaching of teeth and  Adult fluoride.  Fillings to restore teeth due to toothbrush abrasion; attrition; erosion  Resin bonding that is charged separately  Polishing of restorations  Gold foil restorations,  Ozone Therapy. ",
    "preAuth": "Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if less than 2 years. 1 set of plastic dentures (an upper and a lower) per beneficiary per three years  Partial dentures consisting of more than 9 teeth require clinical motivation by your dentist. 2 metal dentures (2 frames for upper and lower jaws) allowed per beneficiary per 4 year cycle,  Partial metal dentures of more than 9 teeth require motivation as to why a full denture cannot be made. Implants Preauthorisation required. Orthodontics: Pre-authorisation required. (General anaesthetic: Children younger than 8 years. Removal of impacted wisdom teeth (if the beneficiary is older than 30 years, a clinical motivation will be ",
    "crucialRules": "Consultations:  2 annual checks ups per beneficiary per year.  Further consultations \u2013 subject to clinical protocols and require authorisation, One root canal therapy allowed per tooth per lifetime. ",
    "notes": "Implants and associated costs.One implant per tooth per lifetime\n Placement of up to two implants per tooth should be done under local anaesthetic, unless the beneficiary is  medically compromised\n OMSMAF allows funding of implants under general anaesthetic as follows on the Traditional, Traditional Plus, including Select Plans from available day-to-day benefits, including a defined implant benefit, and the Savings Plan from available savings",
    "source": "https://omsmaf.co.za/wp-content/uploads/2021/12/OMSMAF-DENTAL-GUIDELINE_v6.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "PG Group",
    "plan": "PG GROUP",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "pgenq@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860104939"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": " Multiple fillings: A treatment plan and X-rays may be required.  Plastic Dentures Pre-authorisation required. Partial Chrome Cobalt Frame Dentures. Two partial frames (an upper and a lower) per beneficiary in a 5-year period Pre-authorisation required. Crown & Bridge . Pre-authorisation required. Three crowns per family per year. Implants Pre-authorisation required\nTwo implants per beneficiary in a 5-year period. Orthodontics Pre-authorisation required\nOnly one family member may commence orthodontic treatment in a calendar year.Surgery in the dental chair: Benefit subject to managed care protocols | Covered at the PDT. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment. p Sedation in Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment . ",
    "crucialRules": "X-rays: Extraoral One per beneficiary in a 3-year period Additional benefit may be considered where specialised dental treatment is required. Fillings: Granted once per tooth in 720 days.Two check-ups per beneficiary per year (once every 6 months). ",
    "notes": "Root Canal Therapy and Extractions: Benefit subject to managed care protocols. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required). Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required).",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/PG-Group-Benefit-Table-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Pick n pay",
    "plan": "Primary ( carecross)",
    "administrator": "momentum",
    "contactInfo": {
      "email": [
        "picknpay@mhg.co.za"
      ],
      "fax": [],
      "phone": [
        "0800004389"
      ]
    },
    "networked": "NO",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Specialised dentistry - orthodontics, periodontics, crowns, bridgework, dental implants and osseo-integration . Root canal treatment and other advanced dentistry.",
    "preAuth": "Dentures: Subject to network dental protocols and specified benefits; only available to beneficiaries older than 21; a 24-month benefit cycle applies. Maxillofacial surgery\n(excluding specialised dentistry) 100% of cost PMBs only, at DSPs",
    "crucialRules": "",
    "notes": " Very basic option. Patient written consent needed for procedures not covered",
    "source": "https://www.pnpms.co.za/static-assets/siteFiles/pnpms_benefitsprimary_22ov23.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Pick n pay",
    "plan": "Plus",
    "administrator": "momentum",
    "contactInfo": {
      "email": [
        "picknpay@mhg.co.za"
      ],
      "fax": [],
      "phone": [
        "0800004389"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies",
    "preAuth": " Multiple fillings: A treatment plan and X-rays may be required.  Plastic Dentures Pre-authorisation required. Partial Chrome Cobalt Frame Dentures. Pre-authorisation required. Crown & Bridge . Pre-authorisation required. Maxillofacial surgery (excluding special dentistry)\n100% of Scheme rate Subject to pre-authorisation and managed care protocols. R2 750 per family per year, payable from the insured benefit, thereafter subject to MSA balance,.",
    "crucialRules": "Advanced dentistry and dental implants Includes dentures, inlays/onlays, periodontal surgery, crowns and bridges as well as orthodontic treatment .   All dentistry benefits are subject to the Scheme`s managed care protocols and benefits. ",
    "notes": " Patient written consent needed for procedures not covered",
    "source": "https://www.pnpms.co.za/static-assets/siteFiles/pnpms_benefitsplus_24nov23.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Platinum health",
    "plan": "Plat Comprehensive",
    "administrator": "Platinum health",
    "contactInfo": {
      "email": [
        "086 233 2406 "
      ],
      "fax": [],
      "phone": [
        "0800006942"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Gum guards for sport purposes, gold in dentures and the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges and bleaching of teeth",
    "preAuth": "Pre authorisation for specialised dentistry. Orthodontic treatment benefit limited to patients under 21 years, subject to scheme clinical protocol and Prosthodontics, periodontics, and endodontic treatment,  Porcelain veneers and inlays,  Crown and Bridge work,  Metal Dentures,  External laboratory services. ",
    "crucialRules": "A 15% co-payment of the benefit limit shall apply in respect of the repair and replacement of dentures. Dentures shall be limited to one set per three consecutive years per PB. ",
    "notes": "General anaesthetic and hospitalisation for conservative dental work excluded, except in the case of trauma, patients under the age of eight years and impacted third molars.",
    "source": "https://www.platinumhealth.co.za/wp-content/uploads/PlatComprehensive-2024-Option-Brochure-FINAL.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Platinum health",
    "plan": "Plat Freedom",
    "administrator": "Platinum health",
    "contactInfo": {
      "email": [
        "086 233 2406 "
      ],
      "fax": [],
      "phone": [
        "0800006942"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Gum guards for sport purposes, gold in dentures and the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges and bleaching of teeth",
    "preAuth": "Pre authorisation for specialised dentistry. Authorisation required for all dental treatment in-hospital. : Includes basic dentistry performed in\u0002hospital for children under eight (8) and for\nremoval of impacted wisdom teeth.  Orthodontic treatment benefit limited to patients under\n21 years, subject to scheme clinical protocol. ",
    "crucialRules": "Advanced: Oral surgery, metal base dentures, inlays, crowns, bridges, study models, orthodontics, periodontics, prosthodontics, osseointegrated implants, orthognathic surgery and dental technician fees.100% of the lower of cost or Scheme Rate . Basic: Includes plastic dentures and basic dentistry performed in-hospital for children under eight (8) and for removal of impacted wisdom teeth.",
    "notes": "General anaesthetic and hospitalisation for conservative dental work excluded, except in the case of trauma, patients under the age of eight years and impacted third molars.  Basic dentistry. R16 800 per member family. Advanced: R17 391 per member family.",
    "source": "https://www.platinumhealth.co.za/wp-content/uploads/PlatFreedom-2024-Option-Brochure-FINAL.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Platinum health",
    "plan": "Platcap CARECROSS",
    "administrator": "Platinum health",
    "contactInfo": {
      "email": [
        "hospitalconfirmations@platinumhealth.co.za"
      ],
      "fax": ["086 233 2406"],
      "phone": [
        "0800006942"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Specialised dentistry - orthodontics, periodontics, crowns, bridgework, dental implants and osseo-integration . Root canal treatment and other advanced dentistry. Gum guards for sport purposes, gold in dentures and the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges and bleaching of teeth.",
    "preAuth": "Hospital  patients under the age of eight years and impacted third molars.",
    "crucialRules": "Conservative Dentistry. One consultation PB per annum, with exception of extractions which are unlimited. One preventative treatment PB per annum for cleaning, fillings and x-rays with exception of extractions which are unlimited. Emergency Dentistry: One-episode PB for pain and sepsis only for in-or-out of network emergency dentistry per annum",
    "notes": "Dentures shall be limited to one set of plastic dentures per 3 consecutive years PB, applicable over age of 21 years. (20% co-payment applies)",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Polmed",
    "plan": "Marine",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "polmedcustomerservice@denis.co.za"
      ],
      "fax": ["0860765633"],
      "phone": [
        "0860 765 633 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Gold inlays in dentures, soft and metal base to new dentures, invisible retainers, Osseo Integrated implants and bleaching of vital (living) teeth. Tooth whitening. Root canal on primary teeth, wisdom teeth. Direct and Indirect pulp cupping. Mouth guards, diagnostic dentures, Metal base to full dentures. Crowns on third molars, emergency crowns, No benefits for Implants.",
    "preAuth": "Dentistry (specialised), Hospitalisation,  Ortho, Periodontics, Sedation Pre-authorisation required. 2 Plastic dentures per beneficiary in 4 years time. One partial metal frame denture in 5 year period.  Ortho granted once per lifetime. Hospital granted for children under the age of 7 years. Sedation  limited to extensive dental treatment and removal of impacted teeth.",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period. Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 720 days.  Oral hygiene instruction granted once per benefiecery every 12 months.",
    "notes": " Patient written consent needed for procedures not covered.     Benefit for specialised dental treatment is not automatic. The member does not have a guaranteed number of crowns or implants per year, each item is reviewed and benefit allocated according to the criteria described below. The published Dental Benefit Tables refer to a maximum benefit that can be awarded per option, provided the specific criteria is met.          ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/POLMED-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Polmed",
    "plan": "Aquarium",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "polmedcustomerservice@denis.co.za"
      ],
      "fax": ["0860765633"],
      "phone": [
        "0860 765 633 "
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Gold inlays in dentures, soft and metal base to new dentures, invisible retainers, Osseo Integrated implants and bleaching of vital (living) teeth; No benefits for Ortho, Implants, Partial metal frame dentures.",
    "preAuth": "Dentistry (specialised), Hospitalisation,  Periodontics, Sedation Pre-authorisation required. 2 Plastic dentures per beneficiary in 4 years time. Hospital granted for children under the age of 7 years. Sedation limited to extensive dental treatment and removal of impacted teeth.",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period. Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 720 days.  Oral hygiene instruction granted once per benefiecery every 12 months.",
    "notes": " Patient written consent needed for procedures not covered.     Benefit for specialised dental treatment is not automatic. The member does not have a guaranteed number of crowns or implants per year, each item is reviewed and benefit allocated according to the criteria described below. The published Dental Benefit Tables refer to a maximum benefit that can be awarded per option, provided the specific criteria is met.          ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/POLMED-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO PINNACLE",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months,  clinical motivation required). Hospitalisation: - Permanent tooth impaction removals - Extensive conservative dental \ntreatment only for children  younger than 8 years (24-month benefit). - Advanced dentistry (includes crowns, bridges, implants,  orthodontics) Orthodontics available only up to age 18.",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period . Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Conservative dentistry (includes  annual check-ups, restorations,  extractions, root canal treatment, dentures) ",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO SECURE ",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months,  clinical motivation required). Hospitalisation: - Permanent tooth impaction removals - Extensive conservative dental \ntreatment only for children  younger than 8 years (24-month benefit). - Advanced dentistry (includes crowns, bridges, implants,  orthodontics) Orthodontics available only up to age 18.",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period . Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Conservative dentistry (includes  annual check-ups, restorations,  extractions, root canal treatment, dentures) ",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO SECURE PLUS",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months,  clinical motivation required). Hospitalisation: - Permanent tooth impaction removals - Extensive conservative dental \ntreatment only for children  younger than 8 years (24-month benefit). - Advanced dentistry (includes crowns, bridges, implants,  orthodontics) Orthodontics available only up to age 18.",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period . Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Conservative dentistry (includes  annual check-ups, restorations,  extractions, root canal treatment, dentures). 2 Root canals allowed per family.",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO ACTIVE PLUS",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months,  clinical motivation required). Hospitalisation: - Permanent tooth impaction removals - Extensive conservative dental \ntreatment only for children  younger than 8 years (24-month benefit). - Advanced dentistry (includes crowns, bridges, implants,  orthodontics) Orthodontics available only up to age 18.",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period . Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Conservative dentistry (includes  annual check-ups, restorations,  extractions, root canal treatment, dentures) . 2Root canals allowed per family.",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO Select",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months,\n clinical motivation required).",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period.  Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. ",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO PINNACLE SAVVY",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months,  clinical motivation required). DENTAL PROCEDURES IN HOSPITAL Subject to pre-authorisation and protocols Permanent tooth impaction removals - Extensive conservative dental treatment only for children younger than 8 years (24-month  benefit)",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period . Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months.",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO SECURE PLUS SAVVY",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months,  clinical motivation required). DENTAL PROCEDURES IN HOSPITAL Subject to pre-authorisation and protocols Permanent tooth impaction removals - Extensive conservative dental treatment only for children younger than 8 years (24-month  benefit)",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period . Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months.",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO SECURE SAVVY",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months,  clinical motivation required). DENTAL PROCEDURES IN HOSPITAL Subject to pre-authorisation and protocols Permanent tooth impaction removals - Extensive conservative dental treatment only for children younger than 8 years (24-month  benefit)",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period . Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months.",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO ACTIVE PLUS SAVVY",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months, clinical motivation required). Hospitalisation: - Permanent tooth impaction removals - Extensive conservative dental \ntreatment only for children  younger than 8 years (24-month   benefit)",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period. Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. 135% Profmed Tariff R6 367 per beneficiary \nMaximum R12 837 per family Not subject to day-to-day limit",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits.",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Profmed",
    "plan": "PRO Select Savvy",
    "administrator": "PPS",
    "contactInfo": {
      "email": [
        "dental@profmed.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 679 200"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Preauthorisation: Specialised dentistry \u2013 implants, metal-based partial dentures, crowns and  bridgework, functional orthognathic surgery, orthodontics up to 18 years of age\n (must include cephalometric tracing), periodontal treatment (must include clear  x-rays, CPITN index, maintenance plan), occlusal guard (once every 24 months, clinical motivation required). Hospitalisation: - Permanent tooth impaction removals - Extensive conservative dental \ntreatment only for children  younger than 8 years (24-month   benefit)",
    "crucialRules": "X-rays: Extra-oral: One per beneficiary in a 3 year period. Additional benefit may be granted where specialised dental treatment planning/follow up is required.  Fillings :   Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. ",
    "notes": "Authorisation is not required for conservative and restorative dentistry (including consults, restorations, extractions, root canal treatment and plastic dentures). Subject to day-to-day dentistry benefit limits. R718 per \nbeneficiary  Maximum R1 848 per family .",
    "source": "https://profmed.co.za/wp-content/uploads/2023/10/DIGITAL-Profmed-Schedule-of-Benefits_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Rand water",
    "plan": "Option B",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Emergency root canal/pulp removal (pulpectomy) charged on the same day as complete therapy.No Benefit for root canal treatment on third molars (wisdom teeth \u2013 18/28/38/48) and primary teeth unless adequately motivated. Diagnostic dentures Metal base to full dentures. Crowns used to restore teeth for cosmetic reasons Crowns where the tooth has been recently restored to function Composite or porcelain veneers Laboratory fabricated crowns are not covered on primary teeth or third molars (wisdom teeth) Crowning of teeth involving failed R.C.T  Temporary /provisional and emergency crowns including lab costs.",
    "preAuth": "Plastic Dentures (pre-authorisation required)  Implants (pre-authorisation required).Orthodontics (pre-authorisation required). Crowns and Bridges (pre-authorisation required). x-ray Only 7 per member per year.  More requires motivation. Extraction Codes (payable form risk pool). Maximum of 1 X 8201 per quadrant per visit.  More than 5 extractions per visit require pre\u0002authorisation. 8169 Occlusal guard Pre-authorisation required. 4 Restorations per year. More than 4 restorations per member per year require preauthorisation and motivation. Maxillofacial Surgery and Oral Pathology (pre-authorisation required) CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS Conscious sedation up to 16 years of age every 24 months. Pre-Authorisation is required for hospitalisation related to dental trauma cases, removal of impacted teeth as well as children under 7 years of age.",
    "crucialRules": "Basic Dentistry All basic dentistry is payable from risk subject to preauthorisation and treatment protocol. Consultations (payable from risk pool) (Motivation required for code 8104 if no covered clinical procedure is charged). Restoration Codes (NB: Posterior restorations are covered to the value of amalgam restorations). Hospital authorisations are only valid for one (1) month and all other authorisation are valid for three (3 months within the benefit year",
    "notes": "Panoramic x-ray (Extra-oral) Maximum of 1 per year.In the case of fillings on posterior teeth (molars and pre-molars) the Dental Risk Company tariff for amalgam fillings will apply, regardless of the material used.",
    "source": "2024 DRC Op Manual D Administration",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Rand water",
    "plan": "OPTION A",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439612"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Emergency root canal/pulp removal (pulpectomy) charged on the same day as complete therapy. Diagnostic dentures Metal base to full dentures. Crowns used to restore teeth \nfor cosmetic reasons Crowns where the tooth has been recently restored to function Composite or porcelain veneers Laboratory fabricated crowns are not covered on primary teeth or third molars (wisdom teeth) Crowning of teeth involving failed R.C.T  Temporary /provisional and emergency crowns including lab costs.",
    "preAuth": "Plastic Dentures (pre-authorisation required) Limited to one set per beneficiary once every 4 years Implants (pre-authorisation required).Orthodontics (pre-authorisation required). Crowns and Bridges (pre-authorisation required). Limited to 1 crown per tooth number every 5 years. Implants (pre-authorisation required). Maxillofacial Surgery (pre-authorisation required). Orthodontics (pre-authorisation required). CONSCIOUS SEDATION IN THE DENTIST\u2019S ROOMS Conscious sedation up to 16 years of age every 24 months. IN-HOSPITAL TREATMENT (GENERAL ANAESTHESIA)Pre-authorisation is compulsory - Limited to available overall annual hospital benefits. Pre-Authorisation is required for hospitalisation related to dental trauma cases, removal of impacted teeth as well as children under 7 years of age. REMOVAL OF IMPACTED TEETH. Limited to the removal of impacted teeth once per lifetime. ",
    "crucialRules": "Examination or consultation for a specific problem. (Motivation required for code 8104 if no covered clinical procedure is charged) . Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. More than 4 restorations per member in a year require pre-authorisation and motivation. X-ray  Only 7 per member per year. More requires motivation. 8115 Panoramic x-ray (Extra-oral) Maximum of 1 per year. Maximum of 1 X 8201 per quadrant per visit.  More than 5 extractions per visit require preauthorisation. ",
    "notes": "Panoramic x-ray (Extra-oral) Maximum of 1 per year.In the case of fillings on posterior teeth (molars and pre-molars) the Dental Risk Company tariff for amalgam fillings will apply, regardless of the material used. Basic Dentistry All basic dentistry is subject to the annual day to day dental limits. Restoration Codes NB: Posterior restorations are covered to the value of amalgam restorations. Once per tooth number in 365 days from date of  service. ",
    "source": "2025 DRC Op Manual D Administration",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Remedi",
    "plan": "Standard",
    "administrator": "Drc Discovery",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Vapour sterilised and wrapped instrumentation  will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed.  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin.  Restorations on non-functional  wisdom teeth.",
    "preAuth": "Consultations (Motivation required for code 8104 if no covered clinical procedure is charged). Pre-authorisation and x-rays required for:  More than 4 restorations per year. Two or more 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.  Extraction Codes: 8201 and 8202   Maximum 1 x 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation.",
    "crucialRules": "8132 Pulp removal (pulpectomy)  Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. : Posterior Amalgam and Resin restorations remunerated at the same tariff.  Pre-authorisation required for more than 3 restorations per visit. (supporting radiographs  required).  Resin paid at the amalgam rates.  1 Restoration code per tooth number in a 9 month period. ",
    "notes": " Patient written consent needed for procedures not covered.     ",
    "source": "2024 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Remedi",
    "plan": "Comprehensive",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [

      ],
      "fax": ["0860116116"],
      "phone": [
        "0860116116"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations  Bone and tissue regeneration procedures ,  Crowns and bridges for cosmetic reasons and associated laboratory costs,  Enamel micro abrasion,  Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees,  Othognatic surgery,  Sinus lift,  Gum guards or mouth protectors.  Gold in dentures or the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges",
    "preAuth": "Specialised dentistry is paid from the Insured Out-of-Hospital Benefit and overall annual limit. Specialised dentistry in hospital is subject to preauthorisation, the claims are paid up to the overall annual limit. Related accounts will accumulate towards the Insured Out-of-Hospital Benefit limits, once the limit is reached the accounts will reject. Specialised dentistry out of hospital (in doctor\u2019s rooms) needs no authorisation. The account for the doctor will be paid from the Insured Out-of-Hospital Benefit and once the limit is reached the claims will be rejected.",
    "crucialRules": ".Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols.",
    "notes": "The hospital account and anaesthesiologists\u2019 account for patients under the age of seven years and for wisdom-teeth removal. The dentist\u2019s account is paid from the Insured Out-of-Hospital Benefit and thereafter from the Personal Medical Savings Account. Authorisation is\nneeded for the above.",
    "source": "https://www.yourremedi.co.za/schemes/remedi/dentists",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Remedi",
    "plan": "Classic",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [

      ],
      "fax": ["0860116116"],
      "phone": [
        "0860116116"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations  Bone and tissue regeneration procedures ,  Crowns and bridges for cosmetic reasons and associated laboratory costs,  Enamel micro abrasion,  Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees,  Othognatic surgery,  Sinus lift,  Gum guards or mouth protectors.  Gold in dentures or the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges",
    "preAuth": "Specialised dentistry is paid from the Insured Out-of-Hospital Benefit and overall annual limit. Specialised dentistry in hospital is subject to preauthorisation, the claims are paid up to the overall annual limit. Related accounts will accumulate towards the Insured Out-of-Hospital Benefit limits, once the limit is reached the accounts will reject. Specialised dentistry out of hospital (in doctor\u2019s rooms) needs no authorisation. The account for the doctor will be paid from the Insured Out-of-Hospital Benefit and once the limit is reached the claims will be rejected.",
    "crucialRules": ".Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols.",
    "notes": "The hospital account and anaesthesiologists\u2019 account for patients under the age of seven years and for wisdom-teeth removal. The dentist\u2019s account is paid from the Insured Out-of-Hospital Benefit and thereafter from the Personal Medical Savings Account. Authorisation is needed for the above.",
    "source": "https://www.yourremedi.co.za/schemes/remedi/dentists",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Sab breweries",
    "plan": "Comprehensive Option",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [

      ],
      "fax": ["0860445566"],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Bone Augmentations  Bone and tissue regeneration procedures ,  Crowns and bridges for cosmetic reasons and associated laboratory costs,  Enamel micro abrasion,  Fillings: the cost of gold, precious metal, semi-precious metal and platinum foil,  Laboratory delivery fees,  Othognatic surgery,  Sinus lift,  Gum guards or mouth protectors.  Gold in dentures or the cost of gold as an alternative to non-precious metal in crowns, inlays and bridges",
    "preAuth": "Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. 80% of lower cost or recommended tariffs. Subject to Day-to-Day limit upfront payment for Basic dentistry performed In Hospital / Day Clinic: 12 years and younger: R2 788 In-Hospital/R1 262 Day Clinic.13 years and older: R7 154 In-Hospital/ R4 576 in Day Clinic. Advanced dentistry 80% of lower cost or Scheme Rate. M R12 760 M1+ R16 693.  Orthodontic treatment 80% of lower cost or Scheme Rate. Subject to and included in  Advanced Dentistry Limit and prior scheme approval. Osseo-integrated implants and orthognatic surgery (including the cost of hospitalisation, dental practitioners,  anaesthetist fees and implants) Subject to and included in Advanced Dentistry limit and dentistry benefit management programme.",
    "crucialRules": "Advanced (specialised) dentistry is subject to the Advanced Dentistry limit and is funded at 80% of the claimed amount or the Scheme Rate, whichever is lower. We pay the remaining 20% from the available funds in your Medical Savings Account or it will come off your salary. Alternatively, for members that pay their contributions through debit order, the 20% portion will be debited from your account. Basic dentistry includes oral medical procedures, plastic dentures and dental technician fees for all dentists, oral hygienists and dental therapists. It is funded at 80% of the claimed amount or the Scheme Rate,\nwhichever is lower",
    "notes": "",
    "source": "https://sabmas.co.za/wp-content/uploads/2023/12/SABMAS-2024-Benefit-Guide_v1.8_digital_singles.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Sab breweries",
    "plan": "Essential",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [

      ],
      "fax": ["0860445567"],
      "phone": [
        "0860445567"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Advanced dentistry + Implants No Benefit. \u2013 Orthodontic treatment No Benefit. Osseo-integrated implants and Orthognatic surgery Orthodontic treatment No Benefit.",
    "preAuth": "Once per tooth within 12 months. Benefit for retreatment of a tooth is subject to clinical protocols. Oral Surgery and Maxillo-Facial Surgery 100% of the lower of cost or recommended tariff. Subject Overall Annual Limit and hospital management programme.",
    "crucialRules": "Advanced dentistry Your option does not offer cover advanced dentistry, including treatment such as inlays, crowns, bridges, mounted study models, metal base dentures, the treatment by periodontists (excluding oral medical procedures) and prosthodontists, osseo-integrated implants, orthodontic treatment and orthognathic surgery. Basic dentistry Basic dentistry includes oral medical procedures, plastic dentures and cost of dental technician fees for all dentists, oral hygienists and dental technicians. It is funded at 80% of the claimed amount or the Scheme Rate, whichever is lower.",
    "notes": "Advanced dentistry + Implants No Benefit.",
    "source": "https://sabmas.co.za/wp-content/uploads/2023/12/SABMAS-2024-Benefit-Guide_v1.8_digital_singles.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Samwumed",
    "plan": "Option A:",
    "administrator": "medscheme",
    "contactInfo": {
      "email": [
        "samwumed.authorisations@medscheme.co.za"
      ],
      "fax": [],
      "phone": [
        "0860104117"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Advanced dentistry + Implants No Benefit. \u2013 Orthodontic treatment No Benefit. Osseo-integrated implants and Orthognatic surgery Orthodontic treatment No Benefit.",
    "preAuth": "Dentistry is subject to quantity protocols.",
    "crucialRules": "Basic Dentistry benefits include:  Fillings. Scaling (which refers to deep cleaning of teeth that reaches below the gum line to remove plaque build-up). Polishing.  Extractions (removal of teeth).  Fissure sealants (treatment aimed at preventing tooth decay); and  Denture repairs (a removable plate or frame holding one or more artificial teeth)",
    "notes": "The amount reflected covers basic dentistry. \u2022 No benefit for advanced dentistry on this Option.",
    "source": "https://samwumed.org/documents/2024/Benefits/Dentistry%20Benefits.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Samwumed",
    "plan": "Option B",
    "administrator": "medscheme",
    "contactInfo": {
      "email": [
        "samwumed.authorisations@medscheme.co.za"
      ],
      "fax": [],
      "phone": [
        "0860104117"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "No benefits for: - laboratory fabricated crowns on primary teeth - root canal treatment on primary teeth - root canal treatment on wisdom teeth (18, 28, 38, 48) - crowns on wisdom teeth (18, 28, 38, 48) - pontics on 2nd molars (17, 27, 37, 47), unless 3rd molars are fully functional, and has been preauthorised - high-impact acrylic..  In-hospital dentectomies. Minor occlusal adjustment.",
    "preAuth": "Advanced Dentistry benefits include:  Orthodontists, crowns, bridge-work, inlays, root canal, treatment by periodontists, prosthodontists, dental technicians and any other anaesthetic procedure. Motivation, referrals and quotes required.",
    "crucialRules": "Basic Dentistry benefits include:  Fillings. Root canal treatments (dental treatment for removing infection from inside a tooth and protecting a tooth from future infections.)  Scaling (which refers to deep cleaning of teeth that reaches below the gum line to remove plaque build-up). Polishing.  Extractions (removal of teeth).  Fissure sealants (treatment aimed at preventing tooth decay); and  Denture repairs (a removable plate or frame holding one or more artificial teeth)",
    "notes": "Members have to claim according to the Scheme\u2019s approved cycles outlined below:  Full dentures - Every three years. Partial dentures - Every two years.",
    "source": "https://samwumed.org/documents/2024/Benefits/Dentistry%20Benefits.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Sasolmed",
    "plan": "sasolmed",
    "administrator": "Discovery",
    "contactInfo": {
      "email": [
        "enquiries@sasolmed.co.za"
      ],
      "fax": ["0860 665 357"],
      "phone": [
        "0860002134"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Scheme Exclusions:  Oral hygiene instruction.  Oral hygiene evaluation. fluoride for beneficiaries younger than 5, and 16 years and older. Tooth whitening.  Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis. Resin bonding for restorations that are charged as a separate procedure to the restoration  The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury. Implants on third molars.  Apicectomies,  Dentectomies,  Frenectomies.",
    "preAuth": "Cover for in-hospital consultations and oral surgery procedures is unlimited at 100% of the Scheme Tariff and at 150% for specialists. Members are responsible for a direct co-payment of R1 500 per admission for dental procedures in hospital. The direct co-payment may be waived. For children younger than eight years, the direct co-payment of R1 500 will not apply. Advanced Dentistry Advanced dentistry includes services for inlays, crowns, bridges, mounted study models, metal base partial dentures, treatment by periodontists, prosthodontists, and dental technicians\u2019 fees for all such dentistry. Cover is limited to R12 000 per beneficiary per year and further limited to R15 500 per family per year. Claims are paid at 100% of the Scheme Tariff and members are responsible for a 20% deductible co-payment. Orthodontic treatment Cover is limited to the advanced dentistry benefit limit. Claims are paid at 100% of the Scheme Tariff and members are responsible for a 20% deductible co-payment. General anaesthesia and conscious sedation for dental procedures General anaesthesia and conscious sedation are covered for children aged eight years and younger, or for the treatment/removal of bony impacted third molars. Claims are paid at 150% of the Scheme Tariff in hospital and at 100% of the Scheme Tariff out of hospital, subject to pre-authorisation. However, you may need to pay a R1,500 co-payment.",
    "crucialRules": "Basic Dentistry Basic dentistry includes out-of-hospital consultations and visits, including minor oral surgery and associated treatment, removal of teeth and roots, removal of wisdom teeth, exposure of teeth for orthodontic reasons, suturing of wounds, plastic dentures, and dental technician\u2019s fees. The benefit also includes cover for oral hygienist and dental therapist consultations. Cover is unlimited and claims are paid at 100% of the Scheme Tariff for in room procedures and 200% for removal of wisdom teeth and/or for bony impactions in healthcare provider\u2019s rooms as an alternative to hospitalisation",
    "notes": "1. Very basic option             2. Lower rate applies to non network provider         3. Patient written consent needed for procedures not covered",
    "source": "https://www.sasolmed.co.za/wcm/medical-schemes/sasolmed/assets/find-a-document/benefit-guides/2024/dental-treatment.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Sisonke",
    "plan": "Pride ( primecure)",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "dental@momentumhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 104 012"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Dentures no benefits, special dentistry No Benefits. Surgical procedures On the network options, members only have benefits for general extractions. Should you need to do a surgical removal of a tooth, patients need to be informed of this prior to or on the day of service for the patient to be held responsible for the outstanding amount.",
    "preAuth": "Restorations \u2013 Maximum four restorations per year with an additional four allowed only upon pre-authorisation.  Extractions - Maximum four extractions per year with an additional four allowed only upon pre-authorisation.  Pulpotomy - Can only be done on primary teeth. Maximum two per child per annum without pre-authorisation. Additional two per child per annum with authorisation. Extraction codes Combined maximum of four per year with an additional four allowed upon pre-authorisation. 8201 Extraction \u2013 single tooth One per quadrant per member per visit 8202 Extraction \u2013 each additional tooth in the same quadrant Four and more require pre-authorisation. 8131 Palliative dental pain treatment Only with diagnostic code on the same service date for the same tooth number 8132 Emergency root canal treatment Not covered on primary teeth.",
    "crucialRules": "Dental: Basic Dentistry Unlimited at Preferred Providers - Scheme Medical Centers - Momentum Dental Network. - As per list of approved codes. Intra-oral radiographs per film Maximum four per annum combined.",
    "notes": "8115 Panoramic radiograph Allowed once every 2 year",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Brochures/2024/MMS/2024_Momentum%20Dental%20Network%20Guide_Discipline%2054%20v2.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Sisonke",
    "plan": "Diversity ( primecure)",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "dental@momentumhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 104 012"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Dentures no benefits, special dentistry No Benefits. Surgical procedures On the network options, members only have benefits for general extractions. Should you need to do a surgical removal of a tooth, patients need to be informed of this prior to or on the day of service for the patient to be held responsible for the outstanding amount.",
    "preAuth": "Restorations \u2013 Maximum four restorations per year with an additional four allowed only upon pre-authorisation.  Extractions - Maximum four extractions per year with an additional four allowed only upon pre-authorisation.  Pulpotomy - Can only be done on primary teeth. Maximum two per child per annum without pre-authorisation. Additional two per child per annum with authorisation. Extraction codes Combined maximum of four per year with an additional four allowed upon pre-authorisation. 8201 Extraction \u2013 single tooth One per quadrant per member per visit 8202 Extraction \u2013 each additional tooth in the same quadrant Four and more require pre-authorisation. 8131 Palliative dental pain treatment Only with diagnostic code on the same service date for the same tooth number 8132 Emergency root canal treatment Not covered on primary teeth.",
    "crucialRules": "Dental: Basic Dentistry Unlimited at Preferred Providers - Scheme Medical Centers - Momentum Dental Network. - As per list of approved codes. Intra-oral radiographs per film Maximum four per annum combined.",
    "notes": "8116 Panoramic radiograph Allowed once every 2 year",
    "source": "https://content.momentum.co.za/mmsa/complete_health/pdfs/Sales%20Tools/Brochures/2024/MMS/2024_Momentum%20Dental%20Network%20Guide_Discipline%2054%20v2.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Sisonke",
    "plan": "Heritage",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "dental@momentumhealth.co.za"
      ],
      "fax": [],
      "phone": [
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Diagnostic dentures,  Snoring apparatus,  Clasp or rest \u2013 cast gold,  Clasp or rest \u2013 wrought gold,  Inlay in denture,  Metal base to full dentures,  Emergency crowns not placed for immediate protection of injured teeth. Temporary and provisional crowns, including laboratory costs. Pontics on second molars. On primary teeth or third molars. All implant related clinical and laboratory associated procedures (includes implant placement, cost of components,",
    "preAuth": "In hospital Dentistry R14 183 Per Beneficiary. OSSEO-INTEGRATED IMPLANTS Included in specialized dentistry benefit limit. ",
    "crucialRules": "Conservative dentistry including ordinary fillings, extractions, x-rays and prophylaxis.A cash co-payment of 20% shall be payable atvpoint of service by the Beneficiary for consultations at a non-preferred provider.",
    "notes": "Basic Dentistry Unlimited at Preferred Providers. Scheme rates apply. Dentures (every 3 years) R6 337 Per Beneficiary Specialised (Dentistry) R8 052 Per Beneficiary to max of R16 109 Per Family. ",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "ESSENTIAL COPPER ",
    "administrator": " 3Sixty Heath.",
    "contactInfo": {
      "email": [
        "0860 100 871"
      ],
      "fax": [],
      "phone": [
        "0860100871"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "X-rays: Extraoral No Benefits. No benefit for root canal therapy. No Benefits for specialised dentistry",
    "preAuth": "Multiple fillings: A treatment plan and X-rays may be required. Plastic Dentures Pre-authorisation required. Hospitalisation Pre-authorisation required (3Sixty Health). Advanced Dentistry Benefit in hospital limited to extensive conservative treatment for children younger than 7 years of age involving more than 3 teeth. Surgical removal of impacted tooth X-rays required.  Removal of symptomatic impacted wisdom teeth covered only as a Day Case.",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 365 days. ",
    "notes": "Patient written Accountability form needed for procedures not covered.",
    "source": "2024 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "ACCESS CORE ( HOSPITAAL PLAN)",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "'0879439611"
      ]
    },
    "networked": "YES",
    "nonNetworkedPayment": "YES",
    "balancedBilling": "",
    "basicTreatment": "NO",
    "rct": "NO",
    "dentures": "NO",
    "crownsOrBridges": "NO",
    "implants": "NO",
    "surgicalExtractions": "NO",
    "exclusions": "X-rays: Extraoral No Benefits. No benefit for root canal therapy. No Benefits for specialised dentistry",
    "preAuth": "Impactions (pre-authorisation required). Conscious sedation and extensive dental \ntreatment are limited to children older than 5 years and younger than 16 years of age. Extensive dental treatment for children under the age of 7 years of age and more than 3 teeth involved.\nLimited to once per 365 days per beneficiary.  Removal of symptomatic impacted wisdom teeth, covered only as a Day Case",
    "crucialRules": "Impactions (pre-authorisation required). Conscious sedation and extensive dental treatment are limited to children older than 5 years and younger than 16 years of age. Extensive dental treatment for children under the age of 7 years of age and more than 3 teeth involved. Limited to once per 365 days per beneficiary.  Removal of symptomatic impacted wisdom teeth, covered only as a Day Case",
    "notes": "Patient written Accountability form needed for procedures not covered.",
    "source": "2025 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": " PLUS",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "In-hospital Apisectomies and dentectomies. Metal, porcelain, or Resin inlays except where such inlays form part of a bridge. Implants on wisdom teeth (3rd molars) and associated laboratory costs. Crowns & bridge work, 4-surface fillings and root canal treatment on non-functional third molars (wisdom teeth) and Pontics on second molars. Pulp capping (direct and indirect). Fissure sealants on patients older than 16 years. Fillings to restore teeth damaged due to abrasion, attrition, erosion, and fluorosis. Diagnostic dentures. Metal base to full dentures, including laboratory cost. Lab codes associated with Mouth guards-Clinical fee will be covered at Scheme Dental Tariff, protocols apply. Soft base to new dentures. ",
    "preAuth": "8169 Occlusal guard  Pre-authorisation required. Once every 24 months. Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.   More than 4 extractions  per visit require preauthorisation. 8937 Surgical removal of tooth  More than 1 removal per visit requires authorisation. Restoration Codes:  Authorisation required for more than 4 restorations \nper year.  Pre-authorisation and x\u0002rays required for more than 3 restorations per visit.  Pre-authorisation and x\u0002rays required for any 3 or 4 surface restorations on wisdom teeth (non\u0002functional wisdom teeth not covered).  More than two: 3 or 4 surface restorations per visit must be preauthorised. More than 1 anterior restoration per visit. Conscious sedation in the dentist Rooms Limited to children younger than 16 years of age \u2013 pre-authorisation required. More than 4 fillings or extractions must be involved. Specialised Dentistry (e.g., Crowns & Bridgework, Dentures, Orthodontics, Impactions and Non-Surgical Periodontics) \u2013 Subject to pre-authorisation. No late authorisation requests will be accepted. Root Planning (pre-authorisation required). Two Implants per family per annum over a 5 year period with a limit of  R17 199. Impactions (pre-authorisation required). Ortho treatment (pre-authorisation required). Hospitalisation and Anaesthetics \u2013 No pre-authorisation, no cover. Extensive conservative treatment for children under 7 years of age and more than 3 teeth involved.",
    "crucialRules": "8132 Emergency root canal treatment  Not covered on primary teeth. 8131 Emergency dental treatment where no other treatment item is applicable  Cannot be claimed with a covered procedure. 8935 Treatment of septic socket  Once per tooth number per lifetime. Not on the same day as an extraction. Root Canal: Benefit is limited to 2 root canal treatments per family  per annum.  Only covered on permanent teeth. 1 Restoration code per tooth number in a 9 month period. ",
    "notes": "If a procedure does not attract benefit; all other treatment/codes associated with the specific event does not receive benefit. On extraction and restoration codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any  deviation requires authorisation.  Extra-oral radiograph \u2013 panoramic  1 Scan per member every  24 months.   Patient written consent needed for procedures not covered.                              Lower rate applies to non network provider      ",
    "source": "2026 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "VALUE",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "In-hospital Apisectomies and dentectomies. Metal, porcelain, or Resin inlays except where such inlays form part of a bridge. Implants on wisdom teeth (3rd molars) and associated laboratory costs. Crowns & bridge work, 4-surface fillings and root canal treatment on non-functional third molars (wisdom teeth) and Pontics on second molars. Pulp capping (direct and indirect). Fissure sealants on patients older than 16 years. Fillings to restore teeth damaged due to abrasion, attrition, erosion, and fluorosis. Diagnostic dentures. Metal base to full dentures, including laboratory cost. Lab codes associated with Mouth guards-Clinical fee will be covered at Scheme Dental Tariff, protocols apply. Soft base to new dentures. ",
    "preAuth": "8169 Occlusal guard  Pre-authorisation required. Once every 24 months. Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.   More than 4 extractions  per visit require preauthorisation. 8937 Surgical removal of tooth  More than 1 removal per visit requires authorisation. Restoration Codes:  Authorisation required for more than 4 restorations \nper year.  Pre-authorisation and x\u0002rays required for more than 3 restorations per visit.  Pre-authorisation and x\u0002rays required for any 3 or 4 surface restorations on wisdom teeth (non\u0002functional wisdom teeth not covered).  More than two: 3 or 4 surface restorations per visit must be preauthorised. More than 1 anterior restoration per visit. Conscious sedation in the dentist Rooms Limited to children younger than 16 years of age \u2013 pre-authorisation required. More than 4 fillings or extractions must be involved. Specialised Dentistry (e.g., Crowns & Bridgework, Dentures, Orthodontics, Impactions and Non-Surgical Periodontics) \u2013 Subject to pre-authorisation. No late authorisation requests will be accepted. Root Planning (pre-authorisation required). Two Implants per family per annum over a 5 year period with a limit of  R17 199. Impactions (pre-authorisation required). Ortho treatment (pre-authorisation required). Hospitalisation and Anaesthetics \u2013 No pre-authorisation, no cover. Extensive conservative treatment for children under 7 years of age and more than 3 teeth involved.",
    "crucialRules": "8132 Emergency root canal treatment  Not covered on primary teeth. 8131 Emergency dental treatment where no other treatment item is applicable  Cannot be claimed with a covered procedure. 8935 Treatment of septic socket  Once per tooth number per lifetime. Not on the same day as an extraction. Root Canal: Benefit is limited to 2 root canal treatments per family  per annum.  Only covered on permanent teeth. 1 Restoration code per tooth number in a 9 month period. ",
    "notes": "If a procedure does not attract benefit; all other treatment/codes associated with the specific event does not receive benefit. On extraction and restoration codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any  deviation requires authorisation.  Extra-oral radiograph \u2013 panoramic  1 Scan per member every  24 months.   Patient written consent needed for procedures not covered.                              Lower rate applies to non network provider      ",
    "source": "2027 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "VALUE CORE",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "In-hospital Apisectomies and dentectomies. Metal, porcelain, or Resin inlays except where such inlays form part of a bridge. Implants on wisdom teeth (3rd molars) and associated laboratory costs. Crowns & bridge work, 4-surface fillings and root canal treatment on non-functional third molars (wisdom teeth) and Pontics on second molars. Pulp capping (direct and indirect). Fissure sealants on patients older than 16 years. Fillings to restore teeth damaged due to abrasion, attrition, erosion, and fluorosis. Diagnostic dentures. Metal base to full dentures, including laboratory cost. Lab codes associated with Mouth guards-Clinical fee will be covered at Scheme Dental Tariff, protocols apply. Soft base to new dentures. ",
    "preAuth": "8169 Occlusal guard  Pre-authorisation required. Once every 24 months. Extraction Codes: Maximum of 1 X 8201 per quadrant per visit.   More than 4 extractions  per visit require preauthorisation. 8937 Surgical removal of tooth  More than 1 removal per visit requires authorisation. Restoration Codes:  Authorisation required for more than 4 restorations per year.  Pre-authorisation and x\u0002rays required for more than 3 restorations per visit.  Pre-authorisation and x\u0002rays required for any 3 or 4 surface restorations on wisdom teeth (non\u0002functional wisdom teeth not covered).  More than two: 3 or 4 surface restorations per visit must be preauthorised. More than 1 anterior restoration per visit. Conscious sedation in the dentist Rooms Limited to children younger than 16 years of age \u2013 pre-authorisation required. More than 4 fillings or extractions must be involved. Specialised Dentistry (e.g., Crowns & Bridgework, Dentures, Orthodontics, Impactions and Non-Surgical Periodontics) \u2013 Subject to pre-authorisation. No late authorisation requests will be accepted. Root Planning (pre-authorisation required). Two Implants per family per annum over a 5 year period with a limit of  R17 199. Impactions (pre-authorisation required). Ortho treatment (pre-authorisation required). Hospitalisation and Anaesthetics \u2013 No pre-authorisation, no cover. Extensive conservative treatment for children under 7 years of age and more than 3 teeth involved.",
    "crucialRules": "8132 Emergency root canal treatment  Not covered on primary teeth. 8131 Emergency dental treatment where no other treatment item is applicable  Cannot be claimed with a covered procedure. 8935 Treatment of septic socket  Once per tooth number per lifetime. Not on the same day as an extraction. Root Canal: Benefit is limited to 2 root canal treatments per family  per annum.  Only covered on permanent teeth. 1 Restoration code per tooth number in a 9 month period. ",
    "notes": "If a procedure does not attract benefit; all other treatment/codes associated with the specific event does not receive benefit. On extraction and restoration codes tooth numbers should not cross posterior quadrants but should be in a combination of 1st and 4th quadrants or 2nd and 3rd quadrants per visit. Any  deviation requires authorisation.  Extra-oral radiograph \u2013 panoramic  1 Scan per member every  24 months.   Patient written consent needed for procedures not covered.                              Lower rate applies to non network provider      ",
    "source": "2028 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": " ACCESS SAVER ",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " Will not be covered:  Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis.   Replacement of non-carious amalgam with resin .  Restorations on non-functional wisdom teeth. \uf0b7 The subsequent restoration will only be authorised once an emailed or scanned x-ray of the completed root canal is received. no specialised dentistry.",
    "preAuth": "x-ray: Pre-authorisation and motivation required prior to claims submission when quantity is \nmore than the following:  With 8101,8112x2 and 8107x1  With 8101, 8107x3,  With 8104, 8112x1 or 8107x1,  No consult 8112x1 or 8107x1. Extraction Codes:  Maximum of 1 X 8201 per \nquadrant per visit.  More than 4 extractions per visit require pre-authorisation. Surgical removal of tooth and roots More than 1 removal per visit requires authorisation. Restoration Codes: Pre-authorisation and x-rays required for:  More than 4 restorations.  More than two: 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.  Multiple restorations on an anterior tooth on the same service date.  Posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  Any 3 or 4 surface restorations on wisdom teeth. Conscious sedation in the dentist Rooms Limited to children up to the age of 16 years (pre-authorisation required). Extensive Dental treatment covered under conscious sedation (pre-authorisation required). Dentures (pre-authorisation required) One set of dentures per member per 4 years for members older  than 16 years. ",
    "crucialRules": "8110 Provision of heat or vapour sterilised and wrapped instrumentation  Will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed. 8132 Emergency root canal treatment (8132 Cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment \ndate)  Not covered on primary teeth. 1 Restoration code per tooth number in a 9-month period. SPECIALISED DENTISTRY (Payable from savings)",
    "notes": "Access Option \u2013 Conservative dentistry payable from the Risk Pool. All clinically valid specialised dental treatment covered from MSA (Medical Savings account) including 1 set of plastic denture per member every 4 years.  Patient written consent needed for procedures not covered.   Lower rate applies to non network provider      ",
    "source": "2029 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "TITANIUM EXECUTIVE PLAN",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "",
    "implants": "",
    "surgicalExtractions": "",
    "exclusions": "Multiple restorations on an anterior tooth on the same service date only per authorisation and x-rays received . Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered.  Replacement of non\u0002carious amalgam restored tooth with a resin will not be covered.  Posterior teeth \nrestorations cannot be performed across the midline. Inlay/onlay or \u00be crowns \nnot covered \uf0b7 Crowns on non\u0002functional 3rd molars are not covered.",
    "preAuth": "Extraction Codes:  Maximum of 1 X 8201 & 8202 per quadrant per visit.   More than 4 extractions \nper visit require preauthorisation. 8937 Surgical removal of tooth  More than 1 removal per visit requires authorisation. Restoration Codes: Authorisation required for more than 4 restorations per year.  Pre-authorisation and x\u0002rays required for more than 3 restorations per \nvisit.  Pre-authorisation and x\u0002rays required for any 3 or 4 surface restorations on \nwisdom teeth . Multiple restorations on an anterior tooth on the same service date only per authorisation and x-rays received. Dentures (pre-authorisation required) 1 Set of plastic dentures, full or partial (an upper and a lower) per beneficiary in a 4 year period for members older than 16 years. Root Planning (pre-authorisation required). Partial Metal frame Dentures (pre-authorisation required). Crowns & Bridgework (pre-authorisation required) A Limited to 3 crowns per family per year. Implants (pre-authorisation required) 2 Implants per beneficiary per annum over a period of 5 years limited to R17 199. Orthodontic treatment (pre-authorisation required). Conscious sedation in the dentist Rooms Limited to children up to the age of 16 years (pre-authorisation required). Impactions (pre-authorisation required). Dental Hospitalisation:  Extensive dental treatment for children under the age of 7 years and more than 3 teeth involved.",
    "crucialRules": "Extra-oral radiograph \u2013 panoramic 1 Scan per member every 3 years.  1 Restoration code per tooth number in a 9 month period   Xrays: Code 8107 and 8112 cannot be charged more than 7 times (per year). 8169 Occlusal guard  Pre-authorisation required. Once every 24 months. 8132 Emergency root canal treatment  Not covered on primary teeth. Root Canal:  Benefit is subject to managed care protocols.  Only covered on permanent teeth.  Excluding wisdom teeth (3rd molars). 2 Partial frames (an upper and lower) per beneficiary in a 5-year period, limited to 2 per family per annum. Benefit for fixed comprehensive orthodontic treatment is limited to members from \nage 9 to younger than 21 years of age.",
    "notes": " Root Canal  Only covered on permanent teeth. Where root canal treatment has failed; benefits are allocated for a re-root canal treatment on the tooth. In the event that the re-root canal treatment fails, benefits will be available for an apicectomy.Crowns and four surface restorations on third molars require pre-authorisation with supporting radiograph.  Patient written consent needed for procedures not covered.             2. Lower rate applies to non network provider      ",
    "source": "2030 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "PLATINUM ENHANCED AND PLATINUM ENHANCED EDO PLAN",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Multiple restorations on an anterior tooth on the same service date only per authorisation and x\u0002rays received  Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered . Replacement of non\u0002carious amalgam with resin will not be cover. Inlay/onlay or \u00be crowns \nnot covered. Crowns on non\u0002functional 3rd molars are not coveredered \uf0b7 Posterior teeth  restorations cannot be performed across the midline. ",
    "preAuth": "8169 Occlusal guard  Pre-authorisation required. Once every 24 months. Extraction Codes:  Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require preauthorisation. Surgical removal of tooth  More than 1 removal per visit requires Authorisation. Restoration Codes:  Authorisation required for more than 4 restorations \nper year.  Pre-authorisation and x\u0002rays required for more than 3 restorations per visit.  Pre-authorisation and x\u0002rays required for any 3 or 4 surface restorations on  wisdom teeth (non\u0002functional wisdom teeth not covered).  More than two: 3 or 4 surface restorations \nper visit must be preauthorised.  More than 1 anterior restoration per visit. Plastic Dentures (pre-authorisation required).  1 Set of plastic dentures, full or partial (an upper and a lower) per beneficiary in a 4-year period for members older than 16 years. Partial Metal frame Dentures 2 Partial frames (an upper and lower) per beneficiary in a 5-year period, limited to 1 per family per annum. (pre-authorisation required). Crowns & Bridgework Limited to 1 crown per \nfamily per year (pre-authorisation required). Implants (pre-authorisation required)  1 Implant per beneficiary per annum over a period of 5 years limited to R16 380. Ortho (pre-authorisation required) Conscious Sedation in the Dentist Rooms Limited to beneficiaries below the age of 16 years (pre-authorisation required). Conscious sedation and extensive dental treatment are limited to children older than 5 years and younger than 16 years of age.Impactions (pre-authorisation required).Extensive dental treatment for children under the age of 7 years of age and more than 3 teeth involved.",
    "crucialRules": "Code 8107 and 8112 cannot be charged more than 7 times (per year). 8115 Extra-oral radiograph 1 Scan per member every 2 years. 8132 Emergency root canal treatment  Not covered on primary teeth. Root Canal: Only covered on \npermanent teeth.  Excluding wisdom teeth (3rd molars). 1 Restoration code per tooth number in a 9 months period. Rebase, Remodel,Reline, Repair once in 365 days.",
    "notes": "Where root canal treatment has failed; benefits are allocated for a re-root canal treatment on the tooth. In the event that the re-root canal treatment fails, benefits will be available for an apicectomy. \uf0b7 Crowns and four surface restorations on third molars require pre-authorisation with supporting radiograph.  Patient written consent needed for procedures not covered.             2. Lower rate applies to non network provider      ",
    "source": "2031 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "SIZWE HOSMED",
    "plan": "GOLD ASCEND AND GOLD ASCEND EDO PLAN",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Multiple restorations on an anterior tooth on the same service date only per authorisation and x\u0002rays received. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered  Replacement of non\u0002carious amalgam with resin will not be cover.  Posterior teeth  restorations cannot be performed across the midline.  No Specialised dentistry. Metal, porcelain or Resin inlays except where such inlays form part of a bridge. Root canal treatment and laboratory fabricated crowns on primary teeth. ",
    "preAuth": "Xrays: Code 8107 and 8112 cannot be charged more than 7 times (per year). 8169 Occlusal guard  Pre-authorisation required. Once every 24 months. Extraction Codes:  Maximum of 1 X 8201 per quadrant per visit.  More than 4 extractions per visit require pre\u0002authorisation. Surgical removal of tooth More than 1 removal per visit requires authorisation. Restoration Codes: Authorisation required for more than 4 restorations per year. Pre-authorisation and x-rays required for more than 3 restorations per visit.  Pre-authorisation and  x-rays required for any 3 or 4 surface restorations on wisdom teeth (non-functional wisdom teeth not covered).  Multiple restorations on an anterior tooth on the same service date only per authorisation and \nx-rays received. Root Planning (pre-authorisation required). SPECIALISED DENTISTRY \u2013 pre-authorisation is required for all services.Dentures (pre-authorisation required). Conscious Sedation in the Dentist Rooms Limited to beneficiaries below the age of 16 years \u2013 pre-authorisation required.  Conscious sedation and extensive dental treatment are limited to \nchildren older than 5 years and younger than 16 years of age. Impactions (pre-authorisation required). \u27a2 Extensive dental treatment for children under the age of 7 years of age and more than 3 teeth involved.",
    "crucialRules": "8115 Extra-oral radiograph \u2013 panoramic  1 Scan per member every 2 years. 8132 Emergency root canal treatment  Not covered on primary teeth. 8131 Emergency dental treatment where no other treatment item is applicable  Cannot be claimed with a covered procedure. Root Canal:  Only covered on permanent teeth. 1 Restoration code per tooth number in a 9 months period.",
    "notes": "Where root canal treatment has failed; benefits are allocated for a re-root canal treatment on the tooth. In the event that the re-root canal treatment fails, benefits will be available for an apicectomy. Crowns and four surface restorations on third molars require pre-authorisation with supporting radiograph.  Patient written consent needed for procedures not covered. Lower rate applies to non network provider      ",
    "source": "2032 DRC Ops manual D administration & Manage care.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Suremed",
    "plan": "Excplorer ( primecure)",
    "administrator": "momentum",
    "contactInfo": {
      "email": [
        "claims@suremedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "086 008 0888"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "In Hospital Dentistry No benefit. Dentistry - Specialised No benefit. ",
    "preAuth": "Dentures: Benefits through Prime Cure dentist 1 set of Acrylic dentures PMF per 24 month cycle up to R4 290 per family paid at 80%. Authorisation required Members over 21 only. Prime cure approved list of codes. Submit claims to Prime Cure. Pre-Authorisation is needed for five or more extractions per beneficiary per year.One (1) surgical extraction per beneficiary per year. Must be authorised and requires a letter of motivation accompanied by the x-rays taken with the authorisation request. Applies to sutures as well. In the event of an emergency (i.e. tooth fracturing during a normal extraction procedure), the dentist may complete the surgical extraction. In such a situation post-operative authorisation is necessary and the dentist must submit a periapical (small) x-ray to prove that the situation demanded a surgical extraction.",
    "crucialRules": "Treatment of septic socket First treatment no authorisation is required. For second treatment and onward authorisation is necessary (historic treatment of 8201/8202).",
    "notes": "8367, 8368, 8369, 8370 TO BE PAID AT AMALGAM RATE, DENTIST CAN CLAIM EITHER AMALGAM OR RESIN AS PER THE SITUATION APPLYING AND THE TARIFF FOR BOTH WILL BE SAME RATE\u201d. Any claims for work performed without pre-authorisation where indicated will be rejected.",
    "source": "https://www.primecure.co.za/wp-content/uploads/2023/12/Prime-Cure-2024-Dental-Manual.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Suremed",
    "plan": "Navigator",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "hospauth@suremedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "086 008 0888"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "Multiple restorations on an anterior tooth on the same service date only per authorisation and x\u0002rays received. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis will not be covered  Replacement of non\u0002carious amalgam with resin will not be cover.  Posterior teeth  restorations cannot be performed across the midline.  No Specialised dentistry. Metal, porcelain or Resin inlays except where such inlays form part of a bridge. Root canal treatment and laboratory fabricated crowns on primary teeth. ",
    "preAuth": "Benefit for general anaesthetic for dental work for patients under the age of 12 years and impacted wisdom teeth. Benefit limit includes all hospital and doctors\u2019 costs. Subject to Suremed protocol and pre-authorisation.",
    "crucialRules": "Dentistry (Basic) Subject to available PMSA from the Day-to-Day limit. Dentistry (Advanced) Crowns, bridges, orthodontics, inlays, dental technician fees, osseointegrated implants.",
    "notes": "Patient written Accountability form needed for procedures not covered.",
    "source": "https://www.suremedhealth.co.za/wp-content/uploads/2023/12/NAVIGATOR_2024.pdf",
    "lastUpdated": ""
  },
  {
    "scheme": "Suremed",
    "plan": "Shuttle",
    "administrator": "Kaelo Primecure Momentum",
    "contactInfo": {
      "email": [
        "info@suremedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "086 008 0888"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "no",
    "dentures": "YES",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Other Dentistry and Specialised Dentistry (including Maxillo-Facial Benefits) and Dentures are not included as Benefits.  No Panorex.",
    "preAuth": "Limited to removal of impacted third molars, and procedures related to sudden and unanticipated injury to teeth and mouth that requires urgent dental treatment after an accident or trauma for children under the age of 7 years. Subject to pre-authorisation and case management. Co-payments apply to certain procedures performed in-hospital. One  consultation  for a full mouth examination.  One preventative treatment which includes cleaning, scaling and polishing. Additional consultations, when needed for restorations, extractions and fillings are covered  subject to Pre-authorisation. Cover for one set of plastic or acrylic dentures (beneficiaries over the age of 21 years) at a dentist in the Prime Cure Network. Pre-authorisation is needed before the visit. Upfront co-payment of 20% of the total account must be paid to  the dentist and the laboratory. Emergency pain and sepsis treatment and extractions only. Pre-authorisation is required. Limited to one event per dependant per year. Cover for removal of impacted third molars, paid at 100% of the Prime Cure Agreed Rate and limited to one event per dependant per year. Pre-authorisation is needed before the visit.",
    "crucialRules": "Emergency pain and sepsis treatment and extractions only, one per beneficiary per year.",
    "notes": "Emergency Root Canal only Subject to case management.  Patient written consent needed for procedures not covered.  Lower rate applies to non network provider      ",
    "source": "https://www.primecure.co.za/wp-content/uploads/2023/12/Prime-Cure-2024-Dental-Manual.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Suremed",
    "plan": "Challenger",
    "administrator": "Momentum",
    "contactInfo": {
      "email": [
        "specauth@suremedhealth.co.za",
        "hospauth@suremedhealth.co.za"
      ],
      "fax": [],
      "phone": [
        "041 395 4545"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "Yes",
    "surgicalExtractions": "Yes",
    "exclusions": "The polishing of restorations,  Gold foil restorations,  Ozone therapy. Root Canal Therapy and Extractions:Root canal therapy on primary (milk) teeth,    third molars (wisdom teeth),  Direct and indirect pulp capping procedures. Diagnostic dentures and . The metal base to full dentures. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury.No benefits for implants.  Apicectomies,  Dentectomies,  Frenectomies",
    "preAuth": "Benefit for general anaesthetic for dental work for patients under the age of 12 years and impacted wisdom teeth. Benefit limit includes all hospital and doctors\u2019 costs. Subject to Suremed protocol and pre-authorisation. Dentistry (Advanced) Crowns, bridges, orthodontics, inlays, dental technician fees, osseointegrated implants, etc.",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 365 days. ",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option  Patient written consent needed for procedures not covered.",
    "source": "https://www.suremedhealth.co.za/wp-content/uploads/2023/12/CHALLENGER_2024.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "TFG",
    "plan": "TFG Health",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "auth@dentalrisk.com"
      ],
      "fax": ["086 687 1285"],
      "phone": [
        "0860445566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Vapour sterilised and wrapped instrumentation  will only be paid if code 8731, 9013, 9011, 8201 or 8202 is claimed.  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. Maximum of 1 per treatment date. Not covered on primary teeth. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Repairing of teeth damaged due to bruxism, toothbrush abrasion, erosion and fluorosis. Replacement of non-carious  amalgam with resin.  Restorations on non-functional  wisdom teeth.",
    "preAuth": "Periapical x-ray  Pre-authorisation and motivation required prior to claims  submission when quantity is more than the following   : With 8101, 8112x2 and 8107x1 ,  With 8104, 8112x1 or 8107x1,  No consult 8112x1 or 8107x1,  Maximum quantity of 7 per year. Pulp removal  8132 cannot be claimed with 8131 or any restoration, root canal and extraction codes. The subsequent restoration will need to be pre-authorised with an emailed or scanned x-ray to show the complete root canal. Extraction Codes: Maximum 1 x 8201 per quadrant per visit.  More than 4 extractions per visit require pre-authorisation. Pre-authorisation and x-rays required for:  More than 4 restorations per year.  Two or more 3 or 4 surface restorations per visit.  More than 2 anterior restorations per visit.  Multiple restorations on an anterior tooth on the same service date.  Lower jaw posterior teeth restorations cannot be performed across the midline. Deviations will only be covered if pre-authorised.  Any 3- or 4 surface restorations on wisdom teeth with supporting x-rays.",
    "crucialRules": "1 Restoration code per tooth number in a 9 month period. Motivation required for code 8104 if no covered clinical procedure is charged.",
    "notes": "If a procedure does not attract benefit; all other treatment costs associated with the specific event does not receive benefit.  Very basic option  Patient written consent needed for procedures not covered.",
    "source": "2024 DRC Ops manual A Capitation agreement.",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Thebemed",
    "plan": "ENERGY ",
    "administrator": "DEnis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Implants and Associated Laboratory Costs No benefit. fluoride for beneficiaries younger than 5, and 16 years and older. Fillings to restore teeth damaged due to toothbrush abrasion, attrition, erosion and fluorosis .  Resin bonding for restorations that are charged as a separate procedure to the restoration. Root canal therapy on primary (milk) teeth  Root canal therapy on third molars (wisdom teeth)  Direct and indirect pulp capping procedures. Laboratory delivery fees. The metal base to full dentures and associated laboratory costs. Crowns on third molars. Provisional crowns. Porcelain veneers and inlays. Emergency crowns that are not placed for the immediate protection in tooth injury and associated laboratory costs. Conservative dental treatment (fillings, extractions and root canal therapy) for adults,  Professional oral hygiene procedures,  Surgical tooth exposures for orthodontic reasons. ",
    "preAuth": "Plastic Dentures Pre-authorisation required One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt Frame Dentures and Associated \nLaboratory Costs Pre-authorisation required .One partial metal frame (an upper or a lower) per beneficiary in a 5-year period. Crowns Pre-authorisation required One crown per beneficiary per year. Orthodontics and Associated Laboratory Costs Pre-authorisation required \nBenefit for orthodontic treatment is granted to a maximum of R8 304 per beneficiary \nper lifetime. Periodontics  Pre-authorisation required. Maxillo-facial Surgery and Oral Pathology\nSurgery in the dental chair: Covered at the TDT . Hospitalisation (General Anaesthetic)\nPre-authorisation required. General anaesthetic benefit available for children under the age of 5 years for extensive dental treatment General anaesthetic benefit available for the removal of impacted teeth. Sedation in the Dental Rooms Pre-authorisation required Benefit limited to extensive dental treatment",
    "crucialRules": "Benefit for fillings: Granted once per tooth in 720 days.  X-rays: Extraoral One per beneficiary in a 3-year period. Fillings Benefit for fillings: Granted once per tooth in 720 days. Root Canal Therapy and Extractions Benefit subject to managed care protocols. Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required).",
    "notes": "Benefit not available for the clinical fee of denture repairs, denture tooth replacements and the addition of a soft base to new dentures; the laboratory fee will be covered (no pre-authorisation required. To avoid unnecessary co-payments, members can request telephonic or written benefit confirmation prior to treatment. Benefit for a mouth guard: Benefit available for both the clinical and the associated laboratory fee for a mouth guard (no pre-authorisation required). ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Thebemed-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Thebemed",
    "plan": "UNIVERSAL PLAN & EDO",
    "administrator": "DEnis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "No Panorex No specialised dentistry. Root canal therapy on third molars (wisdom teeth)\nDirect and indirect pulp capping procedures",
    "preAuth": "Plastic Dentures Pre-authorisation required. One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period .",
    "crucialRules": "8107 8112 Four X-rays in total per beneficiary per calendar year. One set per beneficiary per visit (8109 x 2 and 8110 x 1). 8132 Benefit subject to managed care protocols. Emergency Pulp removal for the relief of acute pain prior to root\ncanal therapy. 8201 Extraction of tooth or exposed roots. Surgery in the Dental Rooms (Impacted teeth only). ",
    "notes": "Benefits payable on the Universal Plan and the Universal Plan EDO are subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. If there is no DSP in the member\u2019s area, the member needs to contact DENIS prior to treatment.  Patient written consent needed for procedures not covered. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Thebemed-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Thebemed",
    "plan": "Fantasy",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "Yes",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "The metal base to full dentures and associated laboratory costs.  High impact acrylic.  The cost of gold, precious metal, semi-precious metal and platinum foil.  Laboratory delivery fees. Implants No benefits. Crowns on third molars. Laboratory fabricated temporary crowns. Provisional crowns and associated laboratory costs. Porcelain veneers and inlays and associated laboratory costs. Orthodontics No benefit. ",
    "preAuth": "Surgery in the Dental Rooms (Impacted teeth only). Plastic Dentures. One set of plastic dentures (an upper and a lower) per beneficiary in a 4-year period. Partial Chrome Cobalt \nFrame Dentures and Associated Laboratory Costs Pre-authorisation required. Crowns Pre-authorisation required. One crown per family per year. Periodontics Pre-authorisation required. Maxillo-facial Surgery and Oral Pathology Surgery in the dental chair: Covered at the TDT. Hospitalisation (General Anaesthetic) Pre-authorisation required. Inhalation Sedation in Dental Rooms Benefit subject to managed care protocols.  Sedation in the Dental Rooms Pre-authorisation required",
    "crucialRules": "Four X-rays in total per beneficiary per calendar year. Emergency Pulp removal for the relief of acute pain prior to root canal therapy. Root Canal Treatment Benefit subject to managed care protocols. Code 8307 \u2013 Primary teeth ONLY. All other codes: ONLY covered on permanent teeth. 8201 Extraction of tooth or exposed roots. Maximum of four fillings per beneficiary per calendar year\nBenefit for fillings granted once per tooth in 720 days. ",
    "notes": "Conservative dentistry benefits are payable from Risk, limited to the specified list of procedure codes and benefit limitations stipulated in the Conservative Dentistry table below. Specialised dentistry benefits are payable from the Member\u2019s Savings Account, limited to R2 500 per family per year. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Thebemed-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Tiger Brands Medical Scheme",
    "plan": "Base Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation  required if >2  per beneficiary  per annum.  Root Canal Treatment . Emergency only.",
    "crucialRules": " Root Canal Treatment Emergency only. Limited to 1 consultation per beneficiaryper annum. One set plastic denture per beneficiary every \n36 months. 80% of cost payable by the scheme and includes professional and laboratory  fees. 20% member liability. ",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment.",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Transmed",
    "plan": "TRANSMED SELECT",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Extraoral X-ray \u2013 panoramic 8115 No benefit | Covered if provided with impacted wisdom tooth removal authorisation application; an additional 8115 can be allowed for Orthodontics. Implants and Associated Laboratory Costs No benefit",
    "preAuth": "Extraoral X-ray \u2013 panoramic 8115 No benefit | Covered if provided with impacted wisdom tooth removal authorisation application; an additional 8115 can be allowed for Orthodontics. Multiple fillings on anterior teeth on the same service date only per treatment plan and motivation, including X-rays, received. Pre-authorisation and X-rays required for any 3 or 4 surface fillings on wisdom teeth (non\u0002functional wisdom teeth not covered). Root Canal All other codes: ONLY covered on permanent teeth, One per beneficiary in 365 days ,  There is a benefit for re-root canal treatment on a tooth where root canal treatment has failed,  In the event that the re-root canal treatment fails, benefits will be available for an apicectomy. Plastic Dentures Pre-authorisation required.All codes (8231 \u2013 8273) are subject to the specialised dentistry limit of R5 360 per family per year.  Partial Chrome Cobalt Frame Dentures and Associated Laboratory Costs Pre-authorisation required. Crown  Pre-authorisation required. One crown per family per 2 years for beneficiaries 16 years and older. Orthodontics  Pre-authorisation is required. Surgery Pre-authorisation is required. Surgical removal of impacted tooth 8941 Surgery in Hospital Pre-authorisation is required.  Sedation in the Dental Rooms  Pre-authorisation required .General Anaesthetic benefits are available for children under the age of 6 years for extensive dental treatment. Limited to 1 admission per beneficiary per 24 months. General Anaesthetic benefits are available for the removal of impacted teeth.",
    "crucialRules": "8101 Two annual consultations per beneficiary per year (once in 6 months, i.e. 180 days apart from previous 8101). 8104 Not within 4 weeks of an 8101, 8102, 8104. Code 8107 and 8112 cannot be charged more than 7 times per year in total . 8155 Two annual treatments per beneficiary per year in total; once in 6 months (i.e. 180 days  8159 apart) for codes 8155 and/or 8159.   Scaling and polishing (for beneficiaries 12 years and older). Treatment of hypersensitive dentine per visit 8167 Two annual treatments per beneficiary per year (once in 6 months, i.e. 180 days apart from previous 8167). Extraction of tooth or exposed roots. 8201 A maximum of 8 per quadrant per permanent dentition and 5 per primary dentition Emergency dental treatment where no other treatment item is applicable 8131 Root canal treatment is not covered on Primary Teeth. Emergency Pulp removal for the relief of acute pain prior to root canal therapy 8132.  Dental fillings (Restorations): One restoration code per tooth number in 720 days. Posterior teeth restorations cannot be performed across the midline ",
    "notes": "Patient written consent needed for procedures not covered. Denture rebase, Repair denture, Denture reline, Denture soft base reline\nDenture remodel, Add a tooth to existing denture,Impression to repair denture Once in 365 days per beneficiary. Inhalation Sedation in the Dental Rooms Subject to the Specialised Dentistry Limit of R5 360 per family per annum. ",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Transmed-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Transmed",
    "plan": "GUARDIAN",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0860 445 566"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": "Extraoral X-rays 8115 No benefit. 8132 Root Canal Treatment is NOT covered on\nPrimary Teeth. Implants and Associated Laboratory Costs,  No benefit.  Orthodontics and Associated Laboratory Costs,  No benefit. Inhalation Sedation in the Dental Rooms No benefit. ",
    "preAuth": "Dental Fillings (Restorations): One restoration code per tooth number in 720 days  Multiple fillings on anterior teeth on the same service date only per treatment plan and motivation, including X-rays, received.  Pre-authorisation and X-rays required for any 3 or 4 surface fillings on wisdom teeth (non-functional wisdom teeth not covered). Plastic Dentures: Pre-authorisation required. Partial Chrome Cobalt Frame Dentures Pre-authorisation required. One frame in 5 years per beneficiary 21 years and older. Crowns Pre-authorisation required,  One Crown per family per 2 years for beneficiaries 16 years and older. Surgery in Hospital Pre-authorisation is required.  Sedation in Dental Rooms Pre-authorisation required. Hospitalisation (General Anaesthetic)   Subject to pre-authorisation. ",
    "crucialRules": "Code 8107 and 8112 cannot be charged more than 2 times per consultation in total; more requires motivation. Extraction of tooth or exposed roots . Maximum of 8 per quadrant per permanent dentition and 5 per primary dentition. Dental Fillings (Restorations) One restoration code per tooth number in 720 days. 8101 Once per beneficiary per year (180 days apart from previous 8101). Extraction of tooth or exposed roots:   8201 Maximum of 8 per quadrant per permanent dentition and 5 per primary dentition. Code 8307 - Primary teeth ONLY :  Root Canal Treatment One per beneficiary in 365 days. ",
    "notes": "Patient written consent needed for procedures not covered. There is a benefit for re-root canal treatment on  a tooth where root canal treatment has failed  In the event that the re-root canal treatment fails, benefits will be available for an apicectomy.  Denture rebase, Repair, reline,  soft base reline, Denture remodel Add a tooth to existing denture, Impression to repair denture, Once in 365 days per beneficiary. Surgery in the dental chair PMB and certain procedures only",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/Transmed-Benefit-Tables-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Transmed Medical Fund",
    "plan": "Link Plan",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "One set of acrylic dentures per family every 24 months. Limited to R4 490 per partial or full set of dentures. Pre\u0002authorisation required if >4 per annum per beneficiary. Only anterior composite fillings are covered. Pre-authorisation required for 4 or more extractions per annum per beneficiary. Teeth extractions to be done in dentist\u2019s  rooms under local anaesthesia. X ray Pre\u0002authorisation required if >2 per beneficiary per annum.",
    "crucialRules": " Root Canal Treatment Emergency only. Limited to 1 consultation per beneficiary per annum.",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "TymeHealth",
    "plan": " MediClub Premier Plan",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital. No Panorex.",
    "preAuth": "Fillings  Motivation required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3 rd Molars) Benefit for fillings is granted once per tooth in 9 months. ",
    "crucialRules": "Maximum of 2 x-rays films per visit per dependent. Two polishing and scaling treatments per dependant per year (once every 6 months 8155 or 8159). Two treatments per year for dependants younger than 12 years of age (once every 6 months). 8201 Extractions (removal of teeth) Extraction of tooth or exposed roots. 8132 Pulpectomy (pain relief) Not covered on primary teeth.",
    "notes": "Benefits payable on the TymeHealth MediClub Connect, Elite and Premier Plans are subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/TymeHealth-MediClub-Benefit-Table-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "TymeHealth",
    "plan": "MediClub Connect Plan",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital. No Panorex.",
    "preAuth": "Fillings  Motivation required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3 rd Molars) Benefit for fillings is granted once per tooth in 9 months. ",
    "crucialRules": "Maximum of 2 x-rays films per visit per dependent. Two polishing and scaling treatments per dependant per year (once every 6 months 8155 or 8159). Two treatments per year for dependants younger than 12 years of age (once every 6 months). 8201 Extractions (removal of teeth) Extraction of tooth or exposed roots. 8132 Pulpectomy (pain relief) Not covered on primary teeth.",
    "notes": "Benefits payable on the TymeHealth MediClub Connect, Elite and Premier Plans are subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/TymeHealth-MediClub-Benefit-Table-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "TymeHealth",
    "plan": "MediClub Elite Plan",
    "administrator": "Denis",
    "contactInfo": {
      "email": [
        "customercare@denis.co.za"
      ],
      "fax": [],
      "phone": [
        "0861 033 647"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "No",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "There is no benefit for: Root Canal Therapy, Dentures, Specialised Dentistry, Inhalation Sedation in Dental Rooms, Moderate/Deep Sedation in Dental Rooms and Dental Treatment in hospital. No Panorex.",
    "preAuth": "Fillings  Motivation required for more than 5 fillings per dependant per year Motivation required for 3 or 4 surface fillings on wisdom teeth (3 rd Molars) Benefit for fillings is granted once per tooth in 9 months. ",
    "crucialRules": "Maximum of 2 x-rays films per visit per dependent. Two polishing and scaling treatments per dependant per year (once every 6 months 8155 or 8159). Two treatments per year for dependants younger than 12 years of age (once every 6 months). 8201 Extractions (removal of teeth) Extraction of tooth or exposed roots. 8132 Pulpectomy (pain relief) Not covered on primary teeth.",
    "notes": "Benefits payable on the TymeHealth MediClub Connect, Elite and Premier Plans are subject to the use of a Designated Service Provider (DSP) on the DENIS Dental Network. \u2212 There will be no benefit for out-of-network visits and treatment.",
    "source": "https://www.denis.co.za/wp-content/uploads/2023/12/TymeHealth-MediClub-Benefit-Table-2024-v1.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Umvuzo",
    "plan": "EXTREME",
    "administrator": "Umvuzo",
    "contactInfo": {
      "email": [
        "info@umvuzohealth.co.za"
      ],
      "fax": ["0866700242"],
      "phone": [
        "060 070 2095"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " Exclusions:  Fillings to restore teeth due to toothbrush abrasion; attrition; erosion  Resin bonding that is charged separately  Polishing of restorations\n Gold foil restorations  Ozone Therapy. ",
    "preAuth": " Fillings:  Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if  less than 2 years. Plastic dentures  1 set of plastic dentures (an upper and a lower) per beneficiary per three years. Partial dentures consisting of more than 9 teeth require clinical motivation by your dentist. Authorisation for Ortho, Metal base dentures. ",
    "crucialRules": "only  Emergency root canal.",
    "notes": "This Option allows members to have more secondary benefits, makes use of the Universal Network",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Umvuzo",
    "plan": "ACTIVATOR",
    "administrator": "Umvuzo",
    "contactInfo": {
      "email": [
        "info@umvuzohealth.co.za",
        "auth@rxhealth.co.za"
      ],
      "fax": ["0866700242"],
      "phone": [
        "060 070 2095"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " Exclusions:  Fillings to restore teeth due to toothbrush abrasion; attrition; erosion  Resin bonding that is charged separately  Polishing of restorations\n Gold foil restorations  Ozone Therapy.  No benefits for theatre or sedation.",
    "preAuth": " Fillings:  Composite and amalgam fillings can be replaced once every two years per individual tooth. Motivation required if  less than 2 years.",
    "crucialRules": "only  Emergency root canal.",
    "notes": "Confirmation of benefits is not a guarantee of paymen",
    "source": "https://www.umvuzohealth.co.za/assets/documents/brochures/2024/dec/activator_brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Umvuzo",
    "plan": "SUPREME",
    "administrator": "Umvuzo",
    "contactInfo": {
      "email": [
        "info@umvuzohealth.co.za"
      ],
      "fax": ["0866700242"],
      "phone": [
        "060 070 2095"
      ]
    },
    "networked": "No",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "Yes",
    "exclusions": " Exclusions:  Fillings to restore teeth due to toothbrush abrasion; attrition; erosion  Resin bonding that is charged separately  Polishing of restorations\n Gold foil restorations  Ozone Therapy.  ",
    "preAuth": " Fillings:  Composite and amalgam fillings can be replaced once every two years per individual tooth.  Plastic dentures  1 set of plastic dentures (an upper and a lower) per beneficiary per three years.  Partia",
    "crucialRules": "only  Emergency root canal.",
    "notes": "Wisdom teeth extraction (in the dentists\u2019 rooms)",
    "source": "https://www.umvuzohealth.co.za/assets/documents/brochures/2024/dec/supreme_brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Umvuzo Health",
    "plan": "Standard Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation  required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation  required if >2  per beneficiary  per annum.  ",
    "crucialRules": " Root Canal Treatment Emergency only   ",
    "notes": "An authorisation granted is not a guarantee of payment",
    "source": "https://www.umvuzohealth.co.za/assets/documents/brochures/2024/dec/standard_brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Umvuzo Health",
    "plan": "Ultra Affordable Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "Yes",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation  required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation  required if >2  per beneficiary  per annum.  ",
    "crucialRules": "No  theatre or sedation.",
    "notes": "An authorisation granted is not a guarantee of payment",
    "source": "https://www.umvuzohealth.co.za/assets/documents/brochures/2024/dec/ultra_affordable_brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Umvuzo Health",
    "plan": "Ultra Affordable Value Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex. ",
    "preAuth": "Fillings . Pre-authorisation  required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation  required if >2  per beneficiary  per annum.  ",
    "crucialRules": " Root Canal Treatment Emergency only     Dentures No benefits.",
    "notes": "An authorisation granted is not a guarantee of payment",
    "source": "https://www.umvuzohealth.co.za/assets/documents/brochures/2024/dec/ultra_affordablevalue_brochure.pdf",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Universal Workplace Health Plan (WHP)",
    "plan": "truCARE",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation  required if >2  per beneficiary  per annum.  Root Canal Treatment ",
    "crucialRules": " Root Canal Treatment Emergency only",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Universal Workplace Health Plan (WHP)",
    "plan": "truHEALTH",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation  required if >2  per beneficiary  per annum.  Root Canal Treatment ",
    "crucialRules": " Root Canal Treatment Emergency only",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Universal Workplace Health Plan (WHP)",
    "plan": "truWELLNESS",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": "Fillings pre\u0002authorisation required if >4 per annum per beneficiary . Only anterior composite fillings are covered. Pre-authorisation required for 4 or  more extractions  per annum per  beneficiary. Wisdom  teeth extractions to  be done in dentist\u2019s  rooms under local anaesthesia. Oral Radiographs Pre authorisation  required if >2  per beneficiary  per annum.  Root Canal Treatment ",
    "crucialRules": " Root Canal Treatment Emergency only",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "WESMART",
    "plan": "COMPREHENSIVE ADVANCED",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered. No Specialised dentistry. Extraction Codes Only 1 per quadrant authorisation required for more than 4 or multiple extractions .",
    "preAuth": "Examination or consultation for a specific problem. (Motivation required for code 8104 if no \ncovered clinical procedure is charged. Pre-authorisation required for more than 4 restorations per year \uf0b7 Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. ",
    "crucialRules": " Posterior restorations are covered to the value of amalgam restorations. 1 Restoration code per tooth number in \na 9 month time period. 8132 cannot be claimed with 8131 or \nextraction codes. Maximum of 1 per treatment date. Not covered on primary teeth.",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered. ",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "WESMART",
    "plan": "COMPREHENSIVE PLUS",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered. No Specialised dentistry. Extraction Codes Only 1 per quadrant authorisation required for more than 4 or multiple extractions .",
    "preAuth": "Examination or consultation for a specific problem. (Motivation required for code 8104 if no \ncovered clinical procedure is charged. Pre-authorisation required for more than 4 restorations per year \uf0b7 Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. ",
    "crucialRules": ": Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year \uf0b7 Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. 1 Restoration code per tooth number in a 9 month time period. Extraction Codes Only 1 per quadrant authorisation required \nfor more than 4 or multiple extractions",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered. ",
    "source": null,
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "WESMART",
    "plan": "COMPREHENSIVE STANDARD",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered. No Specialised dentistry. Extraction Codes Only 1 per quadrant authorisation required for more than 4 or multiple extractions .",
    "preAuth": "Examination or consultation for a specific problem. (Motivation required for code 8104 if no \ncovered clinical procedure is charged. Pre-authorisation required for more than 4 restorations per year \uf0b7 Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. ",
    "crucialRules": ": Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year \uf0b7 Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. 1 Restoration code per tooth number in a 9 month time period. Extraction Codes Only 1 per quadrant authorisation required \nfor more than 4 or multiple extractions",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered. ",
    "source": "https://www.dentalrisk.com/medical_aid_members/list_of_benefits.html",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "WESMART",
    "plan": " PRIMARY STANDARD",
    "administrator": "DRC",
    "contactInfo": {
      "email": [
        "network@dentalrisk.com"
      ],
      "fax": [],
      "phone": [
        "0879439611"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": "No",
    "dentures": "No",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": "Repairing of teeth damaged due to bruxism, toothbrush, abrasion, erosion of fluorosis will not be covered. No Specialised dentistry. Extraction Codes Only 1 per quadrant authorisation required for more than 4 or multiple extractions .",
    "preAuth": "Examination or consultation for a specific problem. (Motivation required for code 8104 if no \ncovered clinical procedure is charged. Pre-authorisation required for more than 4 restorations per year \uf0b7 Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. ",
    "crucialRules": ": Posterior restorations are covered to the value of amalgam restorations. Pre-authorisation required for more than 4 restorations per year \uf0b7 Three or Four surface restorations on wisdom teeth require x-rays & prior pre-authorisation. 1 Restoration code per tooth number in a 9 month time period. Extraction Codes Only 1 per quadrant authorisation required \nfor more than 4 or multiple extractions",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment. Patient written consent needed for procedures not covered. ",
    "source": "https://www.dentalrisk.com/medical_aid_members/list_of_benefits.html",
    "lastUpdated": "Jan-24"
  },
  {
    "scheme": "Witbank Coalfields Medical Aid Scheme",
    "plan": "Ntsika Option",
    "administrator": "Universal",
    "contactInfo": {
      "email": [
        "network@universal.co.za"
      ],
      "fax": [],
      "phone": [
        "0803628677"
      ]
    },
    "networked": "Yes",
    "nonNetworkedPayment": "Yes",
    "balancedBilling": "",
    "basicTreatment": "Yes with limitations",
    "rct": " Root Canal Treatment Emergency only",
    "dentures": "Yes",
    "crownsOrBridges": "No",
    "implants": "No",
    "surgicalExtractions": "No",
    "exclusions": " No benefits for pulpotomy. No benefits for surgical removal of impacted teeth. No panorex.",
    "preAuth": " Root Canal Treatment Emergency only. Denture Benefit Limited to R3 630 per event. Denture Benefit  Only agreed Network codes and tariffs at Universal Network Dentist. Limited to R2 840 per employee. The employee will be liable for the full denture account at\npoint of service. Limited to R2 840, Universal will re-fund the employee 80% of R2 840.",
    "crucialRules": " Root Canal Treatment Emergency only",
    "notes": "Confirmation of benefits is not a guarantee of payment \u2013An authorisation granted is not a guarantee of payment",
    "source": null,
    "lastUpdated": "Jan-24"
  }
]