import { makeStyles } from "@mui/styles";
import FilterSelect from "./FilterSelect";
import Logo from "./Logo";
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';

const headerStyles = makeStyles(theme => ({
    container: {
        display: 'grid',
        height: '60px',
        columnGap: '1em',
        alignItems: 'center',
        '&.desktop': {
            gridTemplateColumns: 'min(50%, 250px) 300px 300px auto',
        }, 
        '&.mobile': {
            gridTemplateColumns: 'minmax(auto, 350px) auto',
        },
        '& .header-button': {
            width: 'max-content',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            background: theme.palette.background.button,
            borderRadius: '1.5em',
            padding: '0.5em 1em'
        },
    },    
    'menu-button': {
        gridColumn: '2 / span 1',
        justifySelf: 'end',
        alignSelf: 'center',
        fontSize: '1.75em !important',
    },
}));

const Header = ({platform, schemePlan='', schemePlans, setSchemePlan, medicalScheme='', medicalSchemes, setMedicalScheme, setSearchTerm, menuHandler}) => {
    const classes = headerStyles();

    if (platform === 'desktop') {
        return (
            <div className={[classes.container, 'desktop'].join(' ')}>
                <Logo />
                <FilterSelect value={medicalScheme} setValue={setMedicalScheme} options={medicalSchemes} placeholder="Medical scheme" />
                <FilterSelect value={schemePlan} setValue={setSchemePlan} options={schemePlans?.[medicalScheme]} placeholder="Scheme option"  />
                {(schemePlan || medicalScheme) && <Button className={'header-button'} onClick={() => {setMedicalScheme(''); setSchemePlan('')}}>Clear filters</Button>}
            </div>
        )
    } else {
        return (
            <div className={[classes.container, 'mobile'].join(' ')}>
                <Logo />
                <MenuIcon className={classes['menu-button']} onClick={() => menuHandler(true)}/>
            </div>
        )
    }
}

export default Header