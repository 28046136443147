import { makeStyles } from "@mui/styles";
import {useHistory} from "react-router-dom";
import logo from "../assets/logo_full.png";


const logoStyles = makeStyles(theme => ({
    logo: {
        gridColumn: '1 / span 1',
        display: 'flex',
        justifySelf: 'start',
        height: 'inherit',
        '& img': {
            overflow: 'auto',
            cursor: 'pointer'
        }
    }
}));

const Logo = () => {
    const history = useHistory(); 
    const classes = logoStyles();
    
    return (
        <div className={classes.logo}>
            <img src={logo} onClick={() => {history.push('/')}} alt='Netcare Medicross logo'/>
        </div>
    )
}

export default Logo