import React, {useState} from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./screens/Home";
import Results from "./screens/Results";
import theme from "./theme";

const App = () => {
    const [searchInput, setSearchInput] = useState('')

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route exact path='/'>
                        <Home setInput={setSearchInput}/>
                    </Route>
                    <Route path='/results'>
                        <Results input={searchInput} />
                    </Route>
                </Switch>
            </Router>
        </ThemeProvider>
    )
}

export default App;