import { makeStyles } from "@mui/styles";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useEffect, useState} from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const searchbarStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        borderRadius: '1.5em',
        background: 'white',
        boxShadow: theme.shadow.select,
        padding: '0 0.5rem',
    },
    field: {
        width: '100%',
        '& fieldset': {
            border: 'none',
        },
        '& input': {
            color: theme.palette.primary.main,
            padding: '0 !important'
        },
        '& svg': {
            color: theme.palette.primary.main,
        }
    },
    label: {
        margin: '1em 0 0.5em'
    }
}));

const searchInput = (params, placeholder) => {
    return <TextField {...params} placeholder={placeholder} />
}

const FilterSelect = ({options=[], value, setValue, placeholder='Select', label=null}) => {
    const classes = searchbarStyles();
    const [selectValue, setSelectValue] = useState(value || '')
    
    useEffect(() => {
        setSelectValue('')
    }, [options])
    
    useEffect(() => {
        setSelectValue(value || '')
    }, [value])
    
    return (
        <div>
            {label && <div className={classes.label}>{label}</div>}
            <div className={classes.container}>
                <Autocomplete 
                    className={classes.field}
                    sx={{
                        border: 'none',
                    }}
                    popupIcon={<ExpandMoreIcon />}
                    value={selectValue}
                    options={options}
                    renderInput={(params) => searchInput(params, placeholder)}
                    onInputChange={(event, value) => {setValue(value)}}
                />
            </div>
        </div>
    )
}

export default FilterSelect;