import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import LinkIcon from '@mui/icons-material/Link';

import {useState} from "react";

const medicalAidStyles = makeStyles(theme => ({
    'result-container': {
        boxShadow: theme.shadow.main,
        borderRadius: '0.5rem',
        margin: '0.5em auto',
        border: `1px solid ${theme.palette.background.button}`,
        '&.desktop': {
            width: 'calc(90% + 3em)',            
        },
        '&.mobile': {
            // width: 'calc(100%)',
        }
    },
    'column-headers':{
        display: 'grid',
        columnGap: '2.5%',
        fontSize: '0.8em',
        alignItems: 'end',
        gridTemplateColumns: '1fr 1.25fr 1fr 1fr 1fr 1fr 1fr',
        margin: '1em auto',
        color: theme.palette.primary.light,
    },
    'summary-row':{
        textAlign: 'left',
        alignItems: 'center',
        fontWeight: 'bold',
        background: 'white',
        borderRadius: '0.5em',
        margin: '0 auto',
        color: theme.palette.primary.main,
        '&.desktop': {
            display: 'grid',
            columnGap: '2.5%',
            padding: '0.5em 1.5em',
            gridTemplateColumns: '1fr 1.25fr 1fr 1fr 1fr 1fr 1fr',        
        },
        '&.mobile': {
            display: 'grid',
            padding: '0.5em 0.75em',
            gridTemplateColumns: '1fr auto',
            fontSize: '0.85em',        
        },
        '&.expanded': {
            borderRadius: '0.5em 0.5rem 0 0',
        },
        '& .action-buttons': {
            display: 'flex',
            justifyContent: 'flex-end',
            columnGap: '0.5em',
            '& Button': {
                padding: '0.5em',
                minWidth: '0'
            }
        },
        '& .info-container': {
            gridColumn: '1 / span 4',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            fontSize: '0.75em',
            rowGap: '0.25em',
            marginTop: '0.5em',
            '& .info': {
                display: 'flex',
                flexDirection: 'column',
                '& .label': {
                    fontWeight: 'normal'
                }
            }
        }
    },
    result: {
        display: 'grid',
        textAlign: 'left',
        columnGap: '2.5%',
        borderRadius: '0 0 0.5em 0.5rem',
        '&.desktop': {
            margin: '0',
            padding: '1em 1.5em',
            justifyItems: 'center',
            gridTemplateColumns: '1fr 1.25fr 1fr 1fr 1fr 1fr 1fr',
            background: theme.palette.background['expanded-desktop'],
            rowGap: '1em'
        },
        '&.mobile': {
            padding: '0em 0.75em 1em',
            fontSize: '0.75em',
            justifyItems: 'center',
            gridTemplateColumns: 'minmax(23.5%,1fr) 1fr 1fr 1fr',
            background: theme.palette.background['expanded-mobile'],
            rowGap: '1em',
            '& .plan': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                '&.section': {
                    gridColumn: '1 / span 4',
                    whiteSpace: 'pre-wrap',
                    color: theme.palette.primary.main
                },
                '& .label': {
                    color: theme.palette.primary.main,
                    fontSize:'0.85em', 
                    alignItems: 'center', 
                    display: 'flex',
                    opacity: '0.8',
                    overflowWrap: 'anywhere'
                },
            }
        },
        '& .plan': {
            justifySelf: 'left',
            width: '100%',
            '&.section': {
                gridColumn: '1 / span 1',
                whiteSpace: 'pre-wrap',
                color: theme.palette.primary.main,
                '& .section-label': {
                    fontSize:'0.8em', 
                    color: theme.palette.primary.main
                },
            },
            '& .value': {
                color: theme.palette.primary.main,
                fontWeight:'bold', 
                paddingBottom: '0.25em', 
                alignItems: 'center', 
                columnGap: '0.5em', 
                display: 'flex',
                whiteSpace: 'pre-wrap'
            },
            '& .label': {
                color: theme.palette.primary.main,
                fontSize:'0.8em', 
                alignItems: 'center', 
                display: 'flex',
                opacity: '0.8',
                overflowWrap: 'anywhere'
            },
            '&.multi_column': {
                gridColumn: 'auto / span 2',
                whiteSpace: 'pre-wrap'
            },
            '&.spanned': {
                gridColumn: 'auto / span 3',
                whiteSpace: 'pre-wrap'
            },
            '&.stretch': {
                gridColumn: 'auto / span 4',
                whiteSpace: 'pre-wrap'
            },
            '&.button_container': {
                justifyContent: 'flex-end',
                display: 'flex'
            }
        },
        '& .download_link': {
            textDecoration: 'none',
            marginRight: '0.5em'
        },
        '& Button': {
            alignSelf: 'center',
            justifySelf: 'end',
            width: 'max-content',
            height: 'fit-content',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            '&.download': {
                background: 'rgba(0,125,145,0.16)',
                borderRadius: '5em',
                padding: '0.5em 1em',
                '& svg': {
                    marginLeft: '0.25em'
                }
            }
        },
        '& .divider': {
            width: 'calc(100% + 1.5em)',
            gridColumn: '1 / span 4',
        },
    },
}));

const getFirstItem = (list) => {
    return typeof list === 'string' ? list : list[0]
}

const MedicalAid = ({result, platform, modalHandler}) => {
    const classes = medicalAidStyles()
    const [expanded, setExpanded] = useState(false)

    if (platform === 'desktop') {
        return (
            <div className={[classes['result-container'], platform].join(' ')}>
                <div className={[classes['summary-row'], platform, expanded ? 'expanded': ''].join(' ')}>
                    <div>{result.scheme}</div>
                    <div>{result.plan}</div>
                    <div>{result.networked || '-'}</div>
                    <div>{result.nonNetworkedPayment || '-'}</div>
                    <div>{result.balancedBilling || '-'}</div>
                    <div>{result.lastUpdated}</div>
                    <div className={'action-buttons'}>
                        <Button disabled={!getFirstItem(result.contactInfo?.phone)} onClick={() => navigator.clipboard.writeText(getFirstItem(result.contactInfo?.phone))}><PhoneIcon /></Button>
                        <Button disabled={!getFirstItem(result.contactInfo?.email)} onClick={() => navigator.clipboard.writeText(getFirstItem(result.contactInfo?.email))}><EmailIcon /></Button>
                        <Button onClick={() => setExpanded(!expanded)}>{expanded ? <ExpandLessIcon />: <ExpandMoreIcon />}</Button>
                    </div>
                </div>
                {expanded && <div className={[classes.result, 'desktop'].join(' ')}>
                    
                        {/* Short fields */}

                        {/* Row 1 */}
                        <div className='plan section'>
                            <div className={'section-label'}>Allowable treatment(s)</div>                    
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Basic treatment</div>                    
                            <div className={'value'}>{result.basicTreatment || '-'}</div>
                        </div>
                        <div className='plan'>
                            <div className={'label'}>RCT</div>                    
                            <div className={'value'}>{result.rct || '-'}</div>
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Dentures</div>                    
                            <div className={'value'}>{result.dentures || '-'}</div>
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Crowns or Bridges</div>                    
                            <div className={'value'}>{result.crownsOrBridges || '-'}</div>
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Implants</div>                    
                            <div className={'value'}>{result.implants || '-'}</div>
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Surgical extractions</div>                    
                            <div className={'value'}>{result.surgicalExtractions || '-'}</div>
                        </div>

                        {/* Row 2 */}
                        <div className='plan section'>
                            <div className={'section-label'}>Contact information</div>                    
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Administrator</div>                    
                            <div className={'value'}>{result.administrator || '-'}</div>
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Email</div>                    
                            <div className={'value'}>{(typeof result.contactInfo?.email === 'string' ? result.contactInfo?.email : result.contactInfo?.email?.map(number => `${number}\n`)) || '-'}</div>
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Fax</div>                    
                            <div className={'value'}>{(typeof result.contactInfo?.fax === 'string' ? result.contactInfo?.fax : result.contactInfo?.fax?.map(number => `${number}\n`)) || '-'}</div>
                        </div>
                        <div className='plan'>
                            <div className={'label'}>Phone</div>                    
                            <div className={'value'}>{(typeof result.contactInfo?.phone === 'string' ? result.contactInfo?.phone : result.contactInfo?.phone?.map(number => `${number}\n`)) || '-'}</div>
                        </div>
                        <div className='plan'>
                            {result.source ? <a className='download_link' href={result.source} underline='none' platform rel="noreferrer" target="_blank" download={`${result.scheme}_${result.plan}_source.pdf`}>
                                <Button className='download' style={{minWidth: '0'}}>
                                    Source
                                    <LinkIcon />
                                </Button>
                            </a> : <div></div>}
                        </div>
                    
                        {/* Long fields */}
                        <div className='plan section'>
                            <div className={'section-label'}>Rules & Exclusions</div>                    
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Pre-authorisation required?</div>                    
                            <div className={'label'}>{result.preAuth || '-'}</div>
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Crucial Scheme Rules To Be Aware Of</div>                    
                            <div className={'label'}>{result.crucialRules || '-'}</div>
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Specific exclusions</div>                    
                            <div className={'label'}>{result.exclusions || '-'}</div>
                        </div>

                        <div className='plan section'>
                            <div className={'section-label'}>Other</div>                    
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Additional notes</div>                    
                            <div className={'label'}>{result.notes || '-'}</div>
                        </div>
                    </div>
                }
            </div>
        )
    } else {
        return (

            <div className={[classes['result-container'], platform].join(' ')}>
                <div className={[classes['summary-row'], platform, expanded ? 'expanded': ''].join(' ')}>
                    <div>
                        {/* <div>{result.scheme}</div> */}
                        <div>{result.plan}</div>
                    </div>
                    <div className={'action-buttons'}>
                        <Button onClick={() => setExpanded(!expanded)}>{expanded ? <ExpandLessIcon />: <ExpandMoreIcon />}</Button>
                    </div>
                    <div className={'info-container'}>
                        <div className='info'>
                            <div>Network applying?</div>
                            <div className={'label'}>{result.networked || '-'}</div>
                        </div>    
                        <div className='info'>
                            <div>Non-network payed?</div>
                            <div className={'label'}>{result.nonNetworkedPayment || '-'}</div>
                        </div>    
                        <div className='info'>
                            <div>Balanced billing?</div>
                            <div className={'label'}>{result.balancedBilling || '-'}</div>
                        </div>    
                        <div className='info'>
                            <div>Updated</div>
                            <div className={'label'}>{result.lastUpdated}</div>
                        </div> 
                    </div>    
                </div>
                {expanded && <div className={[classes.result, 'mobile'].join(' ')}>
                        <Divider className='divider' />
                        <div className='plan section'>
                            <div className={''}>Allowable treatment(s)</div>                    
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Basic treatment</div>    
                            <div className={'label'}>{result.basicTreatment || '-'}</div>
                        </div>    
                        <div className='plan multi_column'>
                            <div className={'value'}>RCT</div>                    
                            <div className={'label'}>{result.rct || '-'}</div>
                        </div>    
                        <div className='plan multi_column'>
                            <div className={'value'}>Dentures</div>                    
                            <div className={'label'}>{result.dentures || '-'}</div>
                        </div>    
                        <div className='plan multi_column'>
                            <div className={'value'}>Crowns or Bridges</div>                    
                            <div className={'label'}>{result.crownsOrBridges || '-'}</div>
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Implants</div>                    
                            <div className={'label'}>{result.implants || '-'}</div>
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Surgical extractions</div>                    
                            <div className={'label'}>{result.surgicalExtractions || '-'}</div>
                        </div>

                        <Divider className='divider' />
                        <div className='plan section'>
                            <div className={''}>Contact information</div>                    
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Administrator</div>                    
                            <div className={'label'}>{result.administrator || '-'}</div>
                        </div>    
                        <div className='plan multi_column'>
                            <div className={'value'}>Email</div>                    
                            <div className={'label'}>{(typeof result.contactInfo?.email === 'string' ? result.contactInfo?.email : result.contactInfo?.email?.map(number => `${number}\n`)) || '-'}</div>
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Fax</div>                    
                            <div className={'label'}>{(typeof result.contactInfo?.fax === 'string' ? result.contactInfo?.fax : result.contactInfo?.fax?.map(number => `${number}\n`)) || '-'}</div>
                        </div>
                        <div className='plan multi_column'>
                            <div className={'value'}>Phone</div>                    
                            <div className={'label'}>{(typeof result.contactInfo?.phone === 'string' ? result.contactInfo?.phone : result.contactInfo?.phone?.map(number => `${number}\n`)) || '-'}</div>
                        </div>
                        <div className='plan'>
                            {result.source ? <a className='download_link' href={result.source} underline='none' platform rel="noreferrer" target="_blank" download={`${result.scheme}_${result.plan}_source.pdf`}>
                                <Button className='download' style={{minWidth: '0'}}>
                                    Source
                                    <LinkIcon />
                                </Button>
                            </a> : <div></div>}
                        </div>

                        <Divider className='divider' />
                        <div className='plan section'>
                            <div className={''}>Rules & Exclusions</div>                    
                        </div>
                        <div className='plan stretch'>
                            <div className={'value'}>Pre-authorisation required?</div>                    
                            <div className={'label'}>{result.preAuth || '-'}</div>
                        </div>    
                        <div className='plan stretch'>
                            <div className={'value'}>Crucial Scheme Rules To Be Aware Of</div>                    
                            <div className={'label'}>{result.crucialRules || '-'}</div>
                        </div>    
                        <div className='plan stretch'>
                            <div className={'value'}>Specific exclusions</div>                    
                            <div className={'label'}>{result.exclusions || '-'}</div>
                        </div>    
                        <div className='plan stretch'>
                            <div className={'value'}>Additional notes</div>                    
                            <div className={'label'}>{result.notes || '-'}</div>
                        </div>
                </div>}
            </div>
        )
    }
}

export default MedicalAid