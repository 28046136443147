import { makeStyles } from "@mui/styles";
import Logo from "./Logo";
import SearchBar from "./Searchbar";

const titleSearchStyles = makeStyles(theme => ({
    logo: {
        maxWidth: '80vw'
    },
    container: {
        display: 'grid',
        width: '100%',
        maxWidth: '600px',
        justifyItems: 'center',
        alignItems: 'center',
        rowGap: '1.5em',

        '& .searchbar': {
            fontSize: '1.25em'
        }
    },
    title: {
        gridRow: '1/ span 1',
        fontSize: '2.5em',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    subTitle: {
        gridRow: '2 / span 1',
        width: '100%',
        maxWidth: '500px',
        fontSize: '1.35em',
        color: '#60a1a3',
        textAlign: 'center',
    },
    center: {
        height: 'calc(90% - 4em)',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const Title = ({platform}) => {
    const classes = titleSearchStyles()

    if (platform === 'desktop') {
        return (
            <>
                <div className={classes.title}>Dental benefit quick reference</div>
                <div className={classes.subTitle}>View benefits, exclusions and authorisation by scheme and option</div>
            </>
        )
    } else {
        return (
            <div className={classes.logo}>
                <Logo />
            </div>
        )        
    }
}

const TitleSearch = ({platform, tariffCode, setTariffCode, tariffCodes, setSearchTerm}) => { 
    const classes = titleSearchStyles()

    return (
        <div className={classes.container}>
            <Title platform={platform} />
            <SearchBar className={platform === 'mobile' ? '' : 'searchbar'} value={tariffCode} setValue={setTariffCode} options={tariffCodes} placeholder="Select a scheme" searchHandler={(searchTerm='') => setSearchTerm(searchTerm || tariffCode)} />
        </div>
    )

}

export default TitleSearch