import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            light: '#60a1a3',
            main: '#138084',
        },
        secondary: {
            light: '#ebc270',
            main: '#e4af44',
        },
        background: {
            button: 'rgb(19 128 132 / 24%)',
            card: '#fff',
            'expanded-desktop': '#ecf3f3',
            'expanded-mobile': '#fff'
        },
    },
    shadow: {
        select: '2px 2px 4px rgb(19 128 132 / 24%)',
        main: '4px 4px 8px rgb(19 128 132 / 24%)',
        dark: '#293754'
    },
    typography: {
        button: {
            textTransform: "none"
        }
    }
});

export default theme;