import { makeStyles } from "@mui/styles";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const searchbarStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        width: 'calc(85% - 2rem)',
        height: 'fit-content',
        borderRadius: '1em',
        padding: '0 1rem',
        background: 'white',
        justifyContent: 'space-between',
        boxShadow: theme.shadow.main,
    },
    field: {
        width: '100%',
        background: 'transparent',
        '& fieldset': {
            border: 'none',
        },
        '& input': {
            color: theme.palette.primary.main,
            padding: '0 !important',
        },
        '& svg': {
            color: theme.palette.primary.main,
        }
    },
    button: {
        width: '2em',
        alignSelf: 'center',
        fontSize: 'inherit !important',
        '& svg': {
            color: theme.palette.primary.main,
            fontSize: 'inherit !important',
            height: '100%',
            width: '100%',
        },
    },
    label: {
        margin: '1em 0 0.5em'
    }
}));

const searchInput = (params, placeholder, searchHandler) => {
    return <TextField {...params} placeholder={placeholder} style={{padding: 0}} onKeyUp={(event) => {if (['Enter', 'NumpadEnter'].includes(event.code)) {
        if (searchHandler) searchHandler(event.target.value)
    }}} />
}

const SearchBar = ({className, options, searchHandler=null, placeholder='Search'}) => {
    const classes = searchbarStyles();
    const classNames = [classes.container, className].join(' ');

    return (
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div className={classNames}>
                <Autocomplete 
                    className={classes.field}
                    sx={{ border: 'none' }}
                    options={options}
                    popupIcon={<ExpandMoreIcon />}
                    renderInput={(params) => searchInput(params, placeholder, searchHandler)}
                    onChange={(event, value, reason) => { if (['selectOption'].includes(reason) && searchHandler) searchHandler(value) }}
                />
                <IconButton name='medical aid search button' className={classes.button} variant='contained' onClick={() => {if (searchHandler) searchHandler()}}>
                    <SearchIcon />
                </IconButton>
            </div>
        </div>
    )
}

export default SearchBar;